import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Badge from '@/components/Badge';
import Card from '@/components/Card';
import Icon from '@/components/Icon';
import { RadioButton } from '@/components/Inputs';

const propTypes = {
  internalProduct: PropTypes.oneOf(['chat', 'crm']),
  plan: PropTypes.string.isRequired,
  setPlan: PropTypes.func.isRequired
};

const defaultProps = {
  internalProduct: 'crm'
};

function PlanSelect({ internalProduct, plan, setPlan }) {
  const options = [
    {
      label: 'Plano Pro',
      value: 'pro_plan',
      internalProduct: 'crm'
    },
    {
      label: 'Plano Performance',
      value: 'performance_plan',
      subLabel: 'Melhor custo-benefício',
      internalProduct: 'crm'
    },
    {
      label: 'Agendor Chat',
      value: 'agendor_chat_plan',
      internalProduct: 'chat'
    },
    {
      label: 'Agendor Chat',
      value: 'agendor_chat_combo_plan',
      internalProduct: 'chat'
    }
  ];

  const filteredOptions = useMemo(() => options
    .filter((option) => option.internalProduct === internalProduct)
    .filter((option) => !(
      (plan === 'agendor_chat_plan' && option.value === 'agendor_chat_combo_plan') ||
      (plan === 'agendor_chat_combo_plan' && option.value === 'agendor_chat_plan')
    )), [internalProduct, plan]);

  return (
    <div id='plan-select'>
      {filteredOptions.map(({ label, value, subLabel }) => (
        <Card
          onClick={() => setPlan(value)}
          key={value}
          className='border-light p-2 mb-2 cursor-pointer'
        >
          <div className='d-flex justify-content-between align-items-center'>
            <RadioButton
              name='plan'
              value={plan}
              id={value}
              radioValue={value}
              label={<h2>{label}</h2>}
              className='me-2 cursor-pointer'
              noFlex
              checkClassName='mb-0 cursor-pointer'
            />

            {subLabel && (
              <Badge className='px-1 bg-primary text-white d-flex align-items-center'>
                <Icon size='sm' className='text-yellow me-1' name='star' />
                <span className='text-small fw-bold'>{subLabel}</span>
              </Badge>
            )}
          </div>
        </Card>
      ))}
    </div>
  );
}

PlanSelect.propTypes = propTypes;
PlanSelect.defaultProps = defaultProps;

export default PlanSelect;
