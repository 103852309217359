import React, { useEffect, useMemo, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useCreatePerson, useOrganization, usePeopleCustomFields } from '@/api';
import { useAlert } from 'react-alert';
import {
  legacyNewFormsOptoutUrl,
  legacyPeopleUrl,
  legacyPersonUrl,
  personPath,
  settingsCustomFieldsPath
} from '@/routes';
import { useDocumentTitle } from '@/hooks';
import { useAuth } from '@/lib/auth';
import { getValidationErrors, isFeedbackRecent, isOptin } from '@/utils';
import { redirect, setLegacyAlertMessage } from '@/browser';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTracking } from '@/lib/tracking';
import FullPersonForm from '@/components/FullPersonForm';
import APIErrorMessage from '@/components/APIErrorMessage';
import Main from '@/components/Main';
import OptoutButton from '@/components/OptoutButton';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import LegacyModal from '@/components/LegacyModal/LegacyModal';

const PAGE_TITLE = 'Adicionar nova pessoa';

function PeopleNew() {
  useDocumentTitle(PAGE_TITLE);

  const [searchParams] = useSearchParams();
  const { user } = useAuth();
  const createPersonMutation = useCreatePerson();
  const alert = useAlert();
  const navigate = useNavigate();
  const tracker = useTracking();
  const { data: customFields, isSuccess: loadedCustomFields } = usePeopleCustomFields();
  const eventMetadata = { source: 'newForm', entityCustomFields: customFields?.data, user };
  const [showLegacyModal, setShowLegacyModal] = useState(false);

  const handleOpenModal = () => {
    const screen = 'Formulario';
    const feedbackGivenRecently = isFeedbackRecent(screen);
    tracker.trackBackToLegacy({
      user,
      page: 'Formulario',
      entity: 'Pessoa',
      feedback: !feedbackGivenRecently
    });
    if (feedbackGivenRecently) {
      const redirectUrl = legacyNewFormsOptoutUrl({ type: 'person' });
      window.location.href = redirectUrl;
    } else {
      setShowLegacyModal(true);
    }
  };

  const organizationId = searchParams.get('organization_id');

  const {
    data: { data: organization = {} } = {},
    isLoading: isLoadingOrganization
  } = useOrganization({
    organizationId,
    config: { enabled: Boolean(organizationId) }
  });

  useEffect(() => {
    tracker.trackNewCreatePersonFormViewed({ user });
  }, [user]);

  const handleSubmit = (formData, { setFieldError, setSubmitting }) => {
    createPersonMutation.mutate(formData, {
      onSuccess: ({ data: { id } }) => {
        tracker.trackPersonCreated({ ...eventMetadata, formData });

        setLegacyAlertMessage('Pessoa inserida com sucesso!', 'success');

        if (user.features.blockLegacyEnabled || isOptin(user?.features?.organizationModalEnabled)) {
          navigate(personPath(id, '/people'));
        } else {
          redirect(legacyPersonUrl(id));
        }
      },
      onError: (err) => {
        const errors = getValidationErrors(err, 'person');

        Object.entries(errors).forEach(([key, message]) => {
          setFieldError(key, message);
        });

        alert.show(
          <APIErrorMessage err={err} resource='person' action='create' />,
          { variant: 'danger' }
        );
        setSubmitting(false);
      }
    });
  };

  const initialValues = useMemo(() => ({
    owner_user_id: user.id,
    privacy: { type: user.account.privacy },
    organization_id: organization?.id,
    organization
  }), [user, organization]);

  const handleCancel = () => redirect(legacyPeopleUrl());

  const onCreateOrganization = () => {
    tracker.trackOrganizationCreated({
      ...eventMetadata,
      quickAdded: true
    });
  };

  if (!loadedCustomFields || isLoadingOrganization) {
    return <div className='flex-grow-1 bg-light' />;
  }

  return (
    <Main fluid flex>
      <div className='py-6 border-bottom'>
        <Container>
          <Row>
            <Col
              md={{ offset: 1, span: 10 }}
              className='d-flex align-items-center'
            >
              <div className='flex-fill'>
                <h1>{PAGE_TITLE}</h1>
              </div>

              <Button
                variant='light'
                href={settingsCustomFieldsPath('people')}
                className='text-primary'
              >
                <Icon name='config' className='me-1' />
                Personalize este formulário
              </Button>
              <OptoutButton
                from='newForms'
                className='ms-2'
                entity={{ type: 'person' }}
                onClick={handleOpenModal}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <LegacyModal
        show={showLegacyModal}
        onHide={() => setShowLegacyModal(false)}
        feature='newForms'
        entity={{ type: 'person' }}
      />

      <FullPersonForm
        initialValues={initialValues}
        customFields={customFields?.data ?? []}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        onCreateOrganization={onCreateOrganization}
      />
    </Main>
  );
}

export default PeopleNew;
