import React, { useMemo } from 'react';
import { compact } from 'lodash';
import { useActivityCard } from '@/components/EntityModal/Activities/ActivityCard/context';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Task from '@/task';
import { ACTIVITY_TYPES } from '@/utils';
import { formatDate, formatTime, toDateString } from '@/date';
import Form from '@/components/EntityModal/Activities/Form';

function prepareInitialValues(data = {}) {
  const type = data?.type ?? ACTIVITY_TYPES.TASK;
  const text = data?.text ?? '';
  const date = formatDate(data?.dueAtUtc) || toDateString(new Date());
  const time = data?.allDay ? '' : formatTime(data?.dueAtUtc) ?? '';
  const assignedUserIds = data?.assignedUsers?.map((assignedUser) => assignedUser.id) ?? [];
  const attachments = compact([].concat(data?.documents).concat(data?.images));
  const finishedAt = data?.finishedAt ?? '';
  return { text, date, time, assignedUserIds, attachments, type, finishedAt };
}

function Edit() {
  const { activity, onUpdate, setEditing } = useActivityCard();
  const { relatedEntity, relatedEntityType } = Task.build(activity);

  const initialValues = useMemo(() => prepareInitialValues(activity), [activity]);

  return (
    <div className='rounded bg-light'>
      <div className='d-flex justify-content-between p-3'>
        <h4 className='mt-1 text-darker-gray'>Editar atividade</h4>
        <Button
          className='text-dark-gray p-0'
          onClick={() => setEditing(false)}
          variant='link'
        >
          <Icon name='close' />
        </Button>
      </div>

      <Form
        entityId={relatedEntity?.id}
        entityName={relatedEntityType}
        activityId={activity.id}
        initialValues={initialValues}
        onUpdate={onUpdate}
        onClose={() => setEditing(false)}
      />
    </div>
  );
}

export default Edit;
