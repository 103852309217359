import React from 'react';
import classnames from 'classnames';
import { useActivityCard } from '@/components/EntityModal/Activities/ActivityCard/context';
import Task from '@/task';
import EmailActionButtons from '@/components/Email/EmailActionButtons';
import Tooltip from '@/components/Tooltip';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import DeleteActivityButton
  from '@/components/EntityModal/Activities/ActivityCard/Footer/DeleteActivityButton';

function Email() {
  const { activity, email, showFooterButtons } = useActivityCard();
  const { relatedEntityType, relatedEntity } = Task.build(activity);

  return (
    <div className='d-flex justify-content-between mt-2 pb-2 ps-1 pe-2'>
      <EmailActionButtons
        entityId={relatedEntity.id}
        entityType={relatedEntityType}
        email={activity.email}
      />

      <div className='d-flex align-items-center'>
        {
          email.historyEnabled && (
            <Tooltip content='Lista de emails'>
              <Button
                className={classnames(
                  'px-2 ms-auto text-dark-gray',
                  { 'visibility-hidden': !showFooterButtons }
                )}
                variant='link'
                onClick={email.showEmailHistory}
              >
                <Icon name='expand' size='sm'/>
              </Button>
            </Tooltip>
          )
        }
        <DeleteActivityButton
          className={classnames({ 'visibility-hidden': !showFooterButtons })}
        />
      </div>
    </div>
  );
}

export default Email;
