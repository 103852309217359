import { useMediaQuery } from '@/hooks';
/*
 * Hook sobre screen size baseado no design system do agenor.
 * https://www.figma.com/file/NluquJtGqDTlbruIEVHx0B/NeoAgendor-DS---Componentes?node-id=936%3A1733
 */

const SM = '767px';
const MD = '991px';
const LG = '1200px';
const XL = '1440px';

export function useScreenSize() {
  return {
    // Small (>= 576px) até 767px
    isSM: useMediaQuery(`(max-width: ${SM})`),

    // Medium (>= 768px) até 991px
    isMD: useMediaQuery(`(max-width: ${MD})`),

    // Large (>= 992px) até 1199px
    isLG: useMediaQuery(`(max-width: ${LG})`),
    isUpperLG: useMediaQuery(`(min-width: ${LG})`),

    // Extra large (>= 1200px) até 1399px
    isXL: useMediaQuery(`(max-width: ${XL})`),
    isUpperXL: useMediaQuery(`(min-width: ${XL})`)
  };
}
