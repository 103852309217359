import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import Icon from '@/components/Icon';
import Task from '@/task';
import { useActivityCard } from '@/components/EntityModal/Activities/ActivityCard/context';
import { getTimelineDateLabel, toUTCISOString } from '@/date';
import strings from '@/strings';
import { ACTIVITY_TYPES, EMAIL_STATUSES } from '@/utils';
import Truncate from '@/components/Truncate';
import isPast from 'date-fns/isPast';
import Tooltip from '@/components/Tooltip';
import Authorization from '@/components/Authorization';
import { Checkbox } from '@/components/Inputs';
import { canFinishActivity } from '@/policies';

function Badge({ dueAt }) {
  const isActivityLate = isPast(dueAt);
  const dueAtLabel = getTimelineDateLabel(dueAt);

  return (
    <span
      className={classnames(
        'text-nowrap text-small px-2 py-1 rounded me-2',
        { 'bg-light-yellow text-warning': isActivityLate },
        { 'bg-light-green text-success': !isActivityLate }
      )}
    >
      Prazo: {dueAtLabel}
    </span>
  );
}

function FinishToggle({ activity, finishedAt, isUpdating, onChange }) {
  const finishedUserName = activity?.finishedBy?.name;
  // WEB-5215
  const finishedText =
    activity.finished && finishedUserName ? `Finalizada por ${finishedUserName}` : '';
  const finishedAtLabel = activity.finished ? getTimelineDateLabel(finishedAt) : '';


  return (
    <Tooltip
      placement='top'
      className='my-n3'
      content={finishedText}
      hide={!finishedText}
    >
      <div>
        <Authorization
          policy={canFinishActivity}
          data={activity}
        >
          <Checkbox
            id={`activity_finished_${activity.id}`}
            name='activity_finished'
            checked={activity.finished}
            onChange={onChange}
            disabled={isUpdating}
            className='ms-auto mt-3 me-2 form-check-input.is-valid'
            isValid
            label={
              <span
                className={classnames(
                  'text-dark-gray',
                  { 'cursor-pointer': !isUpdating },
                  { 'text-decoration-line-through': activity.finished }
                )}
              >
                {activity.finished
                  ? `Finalizada ${finishedAtLabel.toLowerCase()}`
                  : 'Finalizar'}
              </span>
            }
          />
        </Authorization>
      </div>
    </Tooltip>
  );
}

function RightSide({ dueAt, finishedAt }) {
  const { activity, onUpdate, onFinished } = useActivityCard();
  const [isUpdating, setIsUpdating] = useState(false);

  if (activity.type === ACTIVITY_TYPES.NOTE) {
    return null;
  }

  const onChange = () => {
    const finished = activity.finished;

    setIsUpdating(true);
    onUpdate(
      activity?.id,
      {
        finished_at: finished ? null : toUTCISOString(new Date()),
        text: activity.text,
        type: activity.type
      },
      (error) => {
        setIsUpdating(false);
        if (!error && !finished) {
          onFinished();
        }
      }
    );
  };

  return (
    <div className='d-flex align-items-center'>
      {Boolean(!activity.finished && dueAt) && <Badge dueAt={dueAt} />}

      <FinishToggle
        activity={activity}
        finishedAt={finishedAt}
        onChange={onChange}
        onFinished={onFinished}
        isUpdating={isUpdating}
      />
    </div>
  );
}

function SubHeader() {
  const { activity } = useActivityCard();

  const { createdAt, dueAt, finishedAt } = useMemo(
    () => Task.getActivityDates(activity),
    [activity.createdAt, activity.dueAt, activity.finishedAt]
  );

  const email = activity.email;
  const activityHeaderData = useMemo(() => {
    const typeState = activity.finished ? 'type_finished' : 'type_started';

    if (email?.status === EMAIL_STATUSES.RECEIVED) {
      return {
        icon: 'email-received',
        title: 'E-mail recebido',
        label: 'Recebida'
      };
    }

    if (email?.status === EMAIL_STATUSES.FAILED) {
      return {
        icon: 'email-sent',
        title: 'E-mail não enviado',
        label: 'Enviada'
      };
    }

    return {
      icon: activity.type,
      title: strings.models.activity[typeState][activity.type],
      label: 'Criada'
    };
  }, [email?.status, activity.finished, activity.type]);

  const createdAtLabel = createdAt && getTimelineDateLabel(createdAt);

  return (
    <div className='px-4 py-3 text-darker-gray fw-bold'>
      <div className='d-flex align-items-center'>
        <div
          className={classnames(
            'me-3 p-1 rounded bg-lighter-blue',
            'text-dark-gray lh-0 position-relative'
          )}
        >
          <Icon name={activityHeaderData.icon} />
        </div>

        <div className='me-4 flex-fill'>
          <span>{activityHeaderData.title}</span>
          <Truncate className='text-small fw-normal text-dark-gray'>
            {activityHeaderData.label} {createdAtLabel.toLowerCase()}
          </Truncate>
        </div>

        <RightSide
          activity={activity}
          dueAt={dueAt}
          finishedAt={finishedAt}
        />
      </div>
    </div>
  );
}

export default SubHeader;
