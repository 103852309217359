import React from 'react';
import classnames from 'classnames';
import { useActivityCard } from '@/components/EntityModal/Activities/ActivityCard/context';
import AvatarList from '@/components/AvatarList';
import Button from '@/components/Button';
import Authorization from '@/components/Authorization';
import { canUpdateActivity } from '@/policies';
import Tooltip from '@/components/Tooltip';
import Icon from '@/components/Icon';
import CreatedBy from '@/components/EntityModal/Activities/ActivityCard/CreatedBy';
import SmartSuggestionButton from '@/components/EntityModal/Activities/SmartSuggestionButton';
import SmartSuggestion from '@/components/EntityModal/Activities/SmartSuggestion';
import { ENTITIES_TRACKER_SCREEN } from '@/utils';
import Task from '@/task';
import DeleteActivityButton
  from '@/components/EntityModal/Activities/ActivityCard/Footer/DeleteActivityButton';

function EditActivityButton({ className }) {
  const { setEditing } = useActivityCard();

  return (
    <Authorization policy={canUpdateActivity}>
      <Tooltip content='Editar'>
        <Button
          className={classnames('ms-auto text-dark-gray', className)}
          onClick={() => setEditing(true)}
          variant='link'
          aria-label='Editar'
        >
          <Icon name='edit' size='sm'/>
        </Button>
      </Tooltip>
    </Authorization>
  );
}

function Base() {
  const { activity, smartSuggestion, showSmartSuggestion, showFooterButtons } = useActivityCard();

  const { relatedEntity, relatedEntityType } = Task.build(activity);

  const hasAssignedUsers = Boolean(activity.assignedUsers.length);
  const assignedUsers = activity.assignedUsers
    .map((assignedUser) => ({
      name: assignedUser.name,
      url: assignedUser.avatarUrl
    }));

  return (
    <>
      <div className='d-flex justify-content-between mt-2 pb-2 ps-4 pe-2'>
        <div className='d-flex align-items-center'>
          <span className='me-1 text-small text-dark-gray'>
            Criada por
          </span>

          <CreatedBy createdBy={activity.createdBy} />

          {
            hasAssignedUsers && (
              <>
                <div className='vr mx-2' />

                <span className='me-1 text-small text-dark-gray'>
                  Responsáveis
                </span>
                <AvatarList
                  disableArrow
                  slide={false}
                  items={assignedUsers}
                />
              </>
            )
          }
        </div>

        <div className='d-flex align-items-center'>
          <EditActivityButton
            className={classnames({ 'visibility-hidden': !showFooterButtons })}
          />
          <DeleteActivityButton
            className={classnames({ 'visibility-hidden': !showFooterButtons })}
          />

          {smartSuggestion.enabled && smartSuggestion.hasSmartSuggestions && (
            <SmartSuggestionButton
              show={showSmartSuggestion}
              loading={smartSuggestion.isProcessing}
              onClick={() => smartSuggestion.onUpdateActivitySuggestions(!showSmartSuggestion)}
            />
          )}
        </div>
      </div>

      {smartSuggestion.enabled && smartSuggestion.hasSmartSuggestions && (
        <SmartSuggestion
          show={showSmartSuggestion && !smartSuggestion.isProcessing}
          activity={activity}
          entities={smartSuggestion.entities}
          relatedEntity={relatedEntity}
          entityScreen={ENTITIES_TRACKER_SCREEN[relatedEntityType]}
          onEditEntity={smartSuggestion.onEditEntity}
          onSelectIntent={smartSuggestion.onSelectIntent}
          intents={smartSuggestion.intents}
          onSave={(currentIntent) => {
            const params = { processing: false, confirmed: true, cancelled: false, open: false };
            smartSuggestion.onUpdateSuggestion(currentIntent, params);
          }}
          onCancel={(currentIntent) => {
            const params = { processing: false, confirmed: false, cancelled: true, open: false };
            smartSuggestion.onUpdateSuggestion(currentIntent, params);
          }}
          onClose={() => smartSuggestion.onUpdateActivitySuggestions(false)}
        />
      )}
    </>
  );
}

export default Base;
