import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@/components/Avatar';
import { AutomationTag } from '@/feature/automation';

const propTypes = {
  createdBy: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    avatarUrl: PropTypes.string
  }),
  label: PropTypes.bool
};

function CreatedBy({ createdBy, showLabel }) {
  if (createdBy.type === 'automation') {
    return (
      <div className='d-flex text-primary text-small fw-bold'>
        <AutomationTag size='md' />
      </div>
    );
  }

  return (
    <>
      <Avatar
        className='me-1'
        name={createdBy.name}
        tooltip={createdBy.name}
        url={createdBy.avatarUrl}
      />

      {showLabel && createdBy.name}
    </>
  );
}

CreatedBy.propTypes = propTypes;

export default CreatedBy;
