import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '@/components/Button';
import LoadingButton from '@/components/LoadingButton';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import {
  TextInput, MaskedInput, WhatsAppInput, OrganizationSelect,
  CategorySelect, LeadOriginSelect, UserSelect, AddressFields,
  TextArea, ProductSelect, PrivacyInput, ImagePicker
} from '@/components/Inputs';
import AsyncFormField from '@/components/AsyncFormField';
import { usePersonValidation } from '@/api';
import {
  schema as addressSchema, defaultValues as addressDefaultValues
} from '@/components/Inputs/AddressFields';
import {
  schema as privacySchema, defaultValues as privacyDefaultValues
} from '@/components/Inputs/PrivacyInput';
import Footer from '@/components/Footer';
import Icon from '@/components/Icon';
import { CustomFieldsSection, customFieldsSchema } from '@/components/CustomFields';

const propTypes = {
  className: PropTypes.string,
  personId: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onCreateOrganization: PropTypes.func,
  onDelete: PropTypes.func,
  initialValues: PropTypes.object
};

const defaultProps = {
  initialValues: {},
  isEdit: false,
  onSubmit: () => { },
  onCancel: () => { },
  onCreateOrganization: () => { },
  className: ''
};

/* eslint-disable no-magic-numbers */
const schema = Yup.object().shape({
  name: Yup.string()
    .max(60, 'O nome da pessoa deve ter no máximo 60 caracteres.')
    .required('Por favor, informe o nome da pessoa.'),
  cpf: Yup.string()
    .length(11, 'CPF inválido.')
    .when('cpf_async', ([value], fieldSchema) => (
      value
        ? fieldSchema.notOneOf([value], 'Já existe uma pessoa com esse CPF.')
        : fieldSchema
    )),
  birthday: Yup.string()
    .length(5, 'Data de aniversário inválida.'),
  birth_year: Yup.number('Ano inválido.'),
  job_title: Yup.string()
    .max(60, 'O cargo da pessoa deve ter no máximo 60 caracteres.'),
  contact: Yup.object().shape({
    email: Yup.string()
      .email('E-mail inválido.'),
    whatsapp: Yup.string()
      .min(12, 'Número inválido.')
      .max(16, 'Número inválido.'),
    work_phone: Yup.string()
      .min(10, 'Número inválido.')
      .max(11, 'Número inválido.'),
    mobile_phone: Yup.string()
      .min(10, 'Número inválido.')
      .max(11, 'Número inválido.'),
    fax_phone: Yup.string()
      .min(10, 'Número inválido.')
      .max(11, 'Número inválido.'),
    facebook: Yup.string()
      .max(255, 'Esse campo deve ter no máximo 255 caracteres'),
    twitter: Yup.string()
      .max(255, 'Esse campo deve ter no máximo 255 caracteres'),
    linkedin: Yup.string()
      .max(255, 'Esse campo deve ter no máximo 255 caracteres'),
    instagram: Yup.string()
      .max(255, 'Esse campo deve ter no máximo 255 caracteres'),
    skype: Yup.string()
      .max(255, 'Esse campo deve ter no máximo 255 caracteres')
  }),
  address: addressSchema,
  privacy: privacySchema,
  custom_fields: customFieldsSchema
});
/* eslint-enable no-magic-numbers */

const defaultValues = {
  name: '',
  cpf: '',
  organization_id: '',
  job_title: '',
  birthday: '',
  birth_year: '',
  owner_user_id: '',
  category_id: '',
  lead_origin_id: '',
  description: '',
  products_id: [],
  avatar: null,
  privacy: { type: 'default' },
  contact: {
    email: '',
    whatsapp: '',
    work_phone: '',
    fax_phone: '',
    mobile_phone: '',
    phone_extension: '',
    facebook: '',
    twitter: '',
    linkedin: '',
    skype: '',
    instagram: ''
  },
  custom_fields: {},
  ...addressDefaultValues,
  ...privacyDefaultValues
};

function FullPersonForm(props) {
  const {
    initialValues, isEdit, onSubmit,
    onCancel, onCreateOrganization,
    className, onDelete, customFields,
    personId
  } = props;

  const nameRef = useRef();

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }, []);

  return (
    <Form
      name='full-person-form'
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      initialValues={initialValues}
      validationSchema={schema}
      validationContext={{ customFields }}
      className='flex-grow-1 d-flex flex-column'
    >
      {
        ({ handleBlur, handleSubmit, isSubmitting }) => (
          <>
            <Container className='mt-8'>
              <Row>
                <Col
                  md={{ span: 3, offset: 1 }}
                  lg={{ span: 2, offset: 1 }}
                >
                  <FormField
                    as={ImagePicker}
                    name='avatar'
                    label='avatar'
                    iconName='person'
                  />
                </Col>

                <Col md={7} lg={8}>
                  <div className={className}>
                    <h2 className='my-4'>
                      Dados básicos
                    </h2>

                    <Row className='mt-6 gx-3'>
                      <Col sm={6}>
                        <FormField
                          as={TextInput}
                          name='name'
                          label='Nome'
                          autoComplete='off'
                          placeholder='Digite o nome'
                          hint='Obrigatório'
                          maxLength={60}
                          variant='light'
                          ref={nameRef}
                          onBlur={handleBlur}
                        />
                      </Col>
                      <Col sm={6}>
                        <AsyncFormField
                          as={MaskedInput}
                          query={usePersonValidation}
                          extraQueryArgs={{ excludedId: personId }}
                          maskType='cpf'
                          name='cpf'
                          label='CPF'
                          autoComplete='off'
                          placeholder='000.000.000-00'
                          maxLength={14}
                          variant='light'
                          onBlur={handleBlur}
                        />
                      </Col>
                    </Row>
                    <Row className='gx-3'>
                      <Col sm={6}>
                        <FormField
                          as={OrganizationSelect}
                          name='organization_id'
                          className='mb-6'
                          defaultValue={initialValues.organization?.name}
                          onCreate={onCreateOrganization}
                        />
                      </Col>
                      <Col sm={6}>
                        <FormField
                          as={TextInput}
                          name='job_title'
                          label='Cargo'
                          placeholder='Digite o cargo'
                          onBlur={handleBlur}
                          maxLength={60}
                          autoComplete='off'
                        />
                      </Col>
                    </Row>
                    <Row className='gx-3'>
                      <Col xs={6} sm={2}>
                        <FormField
                          as={MaskedInput}
                          name='birthday'
                          label='Aniversário'
                          placeholder='00/00'
                          onBlur={handleBlur}
                          maskType='monthDay'
                          autoComplete='off'
                          maxLength={5}
                        />
                      </Col>
                      <Col xs={6} sm={4}>
                        <FormField
                          as={MaskedInput}
                          name='birth_year'
                          label='Ano nasc.'
                          placeholder='0000'
                          onBlur={handleBlur}
                          maskType='natural'
                          autoComplete='off'
                          maxLength={4}
                        />
                      </Col>
                      <Col sm={6}>
                        <FormField
                          as={UserSelect}
                          name='owner_user_id'
                          label='Responsável'
                          placeholder='Selecione'
                        />
                      </Col>
                    </Row>
                    <Row className='gx-3'>
                      <Col sm={6}>
                        <FormField
                          as={CategorySelect}
                          name='category_id'
                          label='Categoria'
                          placeholder='Selecione'
                        />
                      </Col>
                      <Col sm={6}>
                        <FormField
                          as={LeadOriginSelect}
                          name='lead_origin_id'
                          label='Origem'
                          placeholder='Selecione'
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormField
                          as={TextArea}
                          name='description'
                          label='Descrição'
                          placeholder='Escreva detalhes importantes sobre esse cliente'
                        />
                      </Col>
                    </Row>

                    <CustomFieldsSection
                      section='basic_data'
                      fields={customFields}
                      className='gx-3'
                    />

                    <hr className='mb-6' />

                    <h2 className='my-4'>
                      Informações para contato
                    </h2>

                    <p className='subtitle text-dark-gray'>
                      Adicione informações que facilitem o contato com o cliente.
                    </p>

                    <Row className='gx-3'>
                      <Col sm={6}>
                        <FormField
                          as={MaskedInput}
                          name='contact.email'
                          maskType='email'
                          label='E-mail'
                          autoComplete='off'
                          placeholder='exemplo@email.com'
                          variant='light'
                          onBlur={handleBlur}
                        />
                      </Col>
                      <Col sm={6}>
                        <FormField
                          as={WhatsAppInput}
                          name='contact.whatsapp'
                          label='WhatsApp'
                          autoComplete='off'
                          placeholder='+00 00 00000-0000'
                          variant='light'
                          onBlur={handleBlur}
                        />
                      </Col>
                    </Row>
                    <Row className='gx-3'>
                      <Col sm={6}>
                        <FormField
                          as={MaskedInput}
                          maskType='phone'
                          name='contact.work_phone'
                          label='Telefone'
                          autoComplete='off'
                          placeholder='(00) 0000-0000'
                          variant='light'
                          onBlur={handleBlur}
                        />
                      </Col>
                      <Col sm={6}>
                        <FormField
                          as={MaskedInput}
                          maskType='phone'
                          name='contact.mobile_phone'
                          label='Celular'
                          autoComplete='off'
                          placeholder='(00) 00000-0000'
                          variant='light'
                          onBlur={handleBlur}
                        />
                      </Col>
                    </Row>
                    <Row className='gx-3'>
                      <Col xs={8} sm={6}>
                        <FormField
                          as={MaskedInput}
                          maskType='phone'
                          name='contact.fax_phone'
                          label='Fax'
                          autoComplete='off'
                          placeholder='(00) 0000-0000'
                          variant='light'
                          onBlur={handleBlur}
                        />
                      </Col>
                      <Col xs={4} sm={6}>
                        <FormField
                          as={MaskedInput}
                          name='contact.phone_extension'
                          label='Ramal'
                          placeholder='00'
                          onBlur={handleBlur}
                          maskType='natural'
                          autoComplete='off'
                        />
                      </Col>
                    </Row>

                    <CustomFieldsSection
                      section='contact'
                      fields={customFields}
                      className='gx-3'
                    />

                    <hr className='mb-6' />

                    <h2 className='my-4'>
                      Dados de endereço
                    </h2>

                    <p className='subtitle text-dark-gray'>
                      Adicione a localização do seu cliente.
                    </p>

                    <AddressFields />

                    <CustomFieldsSection
                      section='address'
                      fields={customFields}
                      className='gx-3'
                    />

                    <hr className='mb-6' />

                    <h2 className='my-4'>
                      Produtos e serviços
                    </h2>

                    <p className='subtitle text-dark-gray'>
                      Quais esta pessoa tem potencial de compra?
                    </p>

                    <Row>
                      <Col>
                        <FormField
                          as={ProductSelect}
                          name='products_id'
                          multiple
                          placeholder='Buscar...'
                          aria-label='Produtos e serviços'
                        />
                      </Col>
                    </Row>

                    <hr className='mb-6' />

                    <h2 className='my-4'>
                      Redes sociais
                    </h2>

                    <Row className='gx-3'>
                      <Col sm={6}>
                        <FormField
                          as={TextInput}
                          name='contact.facebook'
                          label='Facebook'
                          placeholder='facebook.com/agendor'
                          onBlur={handleBlur}
                          autoComplete='off'
                        />
                      </Col>
                      <Col sm={6}>
                        <FormField
                          as={TextInput}
                          name='contact.twitter'
                          label='Twitter'
                          placeholder='twitter.com/agendor'
                          onBlur={handleBlur}
                          autoComplete='off'
                        />
                      </Col>
                    </Row>
                    <Row className='gx-3'>
                      <Col sm={6}>
                        <FormField
                          as={TextInput}
                          name='contact.linkedin'
                          label='LinkedIn'
                          placeholder='linkedin.com/in/agendor'
                          onBlur={handleBlur}
                          autoComplete='off'
                        />
                      </Col>
                      <Col sm={6}>
                        <FormField
                          as={TextInput}
                          name='contact.skype'
                          label='Skype'
                          placeholder='agendorcrm'
                          onBlur={handleBlur}
                          autoComplete='off'
                        />
                      </Col>
                    </Row>
                    <Row className='gx-3'>
                      <Col sm={6}>
                        <FormField
                          as={TextInput}
                          name='contact.instagram'
                          label='Instagram'
                          placeholder='instagram.com/agendor'
                          onBlur={handleBlur}
                          autoComplete='off'
                        />
                      </Col>
                    </Row>

                    <hr className='mb-6' />

                    <h2 className='my-4'>
                      Privacidade
                    </h2>

                    <p className='subtitle text-dark-gray'>
                      Quem pode ver o histórico e editar essa pessoa?
                    </p>

                    <Row className='mb-2'>
                      <Col>
                        <PrivacyInput />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>

            <Footer className='mt-auto flex-shrink-0 py-4 shadow-up bg-white'>
              <Container>
                <Row>
                  <Col
                    md={{ offset: 1, span: 10 }}
                    className='d-flex'
                  >
                    {
                      onDelete &&
                      <Button
                        className='text-danger fw-bold px-0'
                        variant='link'
                        onClick={onDelete}
                      >
                        <Icon name='delete' className='me-2 my-n1' />
                        <span>Excluir pessoa</span>
                      </Button>
                    }

                    <Button
                      className='ms-auto me-3'
                      variant='outline-dark-gray'
                      onClick={onCancel}
                      disabled={isSubmitting}
                    >
                      {isEdit ? 'Cancelar' : 'Voltar'}
                    </Button>

                    <LoadingButton
                      className='min-width-4 px-8'
                      isLoading={isSubmitting}
                      onClick={handleSubmit}
                    >
                      {isEdit ? 'Salvar alterações' : 'Salvar'}
                    </LoadingButton>
                  </Col>
                </Row>
              </Container>
            </Footer>
          </>
        )
      }
    </Form>
  );
}

FullPersonForm.propTypes = propTypes;
FullPersonForm.defaultProps = defaultProps;

export default FullPersonForm;
