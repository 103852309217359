import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import { toCurrencyString, toNumber } from '@/number';
import { useAuth } from '@/lib/auth';
import { canEditDealValue, canEditDeal } from '@/policies/deals';
import DetailsCard from '@/components/EntityModal/DetailsCard';
import ProductsFormPopover from '@/components/EntityModal/ProductsFormPopover';
import Truncate from '@/components/Truncate';
import Authorization from '@/components/Authorization';
import Skeleton from '@/components/Skeleton';
import { CurrencyInput, EditInPlace } from '@/components/Inputs';

DetailsProducts.defaultProps = {
  products: [],
  dealValue: 0,
  loading: false,
  onEditDeal: () => {}
};

DetailsProducts.propTypes = {
  dealId: PropTypes.number,
  products: PropTypes.array,
  dealValue: PropTypes.number,
  loading: PropTypes.bool,
  onEditDeal: PropTypes.func
};

const renderProducts = (products) => (
  products
    .filter(({ product }) => product.active)
    .map(({ quantity, product, unitValue }, index) => (
      <Row key={index} className='d-flex mb-2 g-0'>
        <Col sm={7}>
          <Truncate className='me-2'>
            {product.name}
          </Truncate>
        </Col>
        <Col sm={5}>
          <Truncate className='text-end'>
            <span className='me-1 text-dark-gray'>{quantity}x</span>
            <span className='fw-bold'>
              {toCurrencyString(toNumber(unitValue))}
            </span>
          </Truncate>
        </Col>
      </Row>
    ))
);

function DetailsProducts({ products, dealValue, dealId, loading, onEditDeal }) {
  const { user } = useAuth();
  if (loading) {
    return <Skeleton width='100%' height={200} className='mb-3' />;
  }

  const hasProducts = Boolean(products.length);
  const hasQuantity = products?.some((product) => toNumber(product.totalValue) > 0);
  const dealValuePolicy = () => canEditDealValue({ user, data: { hasQuantity } });

  return (
    <>
      <DetailsCard className='mt-3' title='Valor do negócio'>
        <EditInPlace
          className='mb-5'
          as={CurrencyInput}
          placeholder='R$ 0,00'
          name='value'
          policy={dealValuePolicy}
          value={dealValue}
          onConfirm={onEditDeal}
        >
          <h2 className='mb-3'>
            {toCurrencyString(toNumber(dealValue))}
          </h2>
        </EditInPlace>

        <div>
          <div className='d-inline-flex mb-2'>
            <span className='text-dark-gray text-small'>
              Produtos e serviços
            </span>
            {hasProducts
              ? (
                <ProductsFormPopover
                  isEdit
                  dealId={dealId}
                  products={products}
                  onSubmit={onEditDeal}
                  policy={canEditDeal}
                />
              )
              : null}
          </div>

          {
            hasProducts && !loading
              ? renderProducts(products)
              : (
                <>
                  <div className='mb-2 fw-bold text-dark-gray'>
                    Nenhum produto ou serviço foi adicionado a este negócio
                  </div>

                  <Authorization policy={canEditDeal}>
                    <ProductsFormPopover
                      dealId={dealId}
                      onSubmit={onEditDeal}
                    />
                  </Authorization>
                </>
              )
          }
        </div>
      </DetailsCard>
    </>
  );
}

export default DetailsProducts;
