export function canUseIntegration({ user, integration }) {
  let effect, reason, planName;

  const userPlan = user.account.plan.name;
  const allowedPlans = integration?.plans?.map((plan) => plan.value);

  if (!allowedPlans.includes(userPlan) && allowedPlans.length > 0) {
    effect = 'paywall';
    planName = allowedPlans[0];
    reason = 'Para utilizar essa integração, é necessário um plano compatível.';
  } else {
    effect = 'allow';
  }

  return { effect, reason, planName };
}
