import React from 'react';
import FormField from '@/components/FormField';
import {
  Checkbox
} from '@/components/Inputs';
import { CustomFieldFormField } from '@/components/Filters';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';
import { customFieldValue } from '@/utils';

function customFieldFilterName(customField) {
  if (customField.type === 'text') {
    return `custom_fields.${customField.id}.text_value_cont`;
  }
  return `custom_fields.${customField.id}`;
}

function customFieldColumns(customFields = []) {
  return customFields.map((customField) => ({
    name: customField.identifier,
    humanizedName: customField.name,
    default: false,
    customField: true,
    isDecimal: customField.type === 'decimal',
    calculable: customField.type === 'integer' || customField.type === 'decimal',
    getValue: (entity) => {
      const value = entity?.customFields[customField.identifier];
      return customFieldValue(value, customField);
    },
    filter: {
      name: customFieldFilterName(customField),
      formKey: `custom_fields[${customField.id}]`,
      defaultValue: null,
      isPopover: ['integer', 'decimal', 'date'].includes(customField.type),
      renderComponent: () => (
        <CustomFieldFormField
          key={customField.identifier}
          field={customField}
        />
      )
    }
  }));
}

function columnsFilters(customFields = []) {
  return customFields.map((column) => (
    <FormField
      as={Checkbox}
      key={column.identifier}
      name={column.identifier}
      label={
        <div className='d-flex align-items-stretch'>
          <span>{column.name}</span>
          <Tooltip content='Esta coluna é filtrável'>
            <div className='cursor-pointer'>
              <Icon className='ms-1' name='filter' size='sm' />
            </div>
          </Tooltip>
        </div>
      }
    />
  ));
}

function columnsDefaultValues(customFields = []) {
  return customFields.reduce((object, field) => {
    object[field.identifier] = false;
    return object;
  }, {}) || {};
}

function tableDefaultValues(customFields = []) {
  const columnsList = customFieldColumns(customFields);
  return columnsList.filter((column) => column.filter)
    .reduce((result, column) => {
      result[column.filter.name] = column.filter.defaultValue;
      return result;
    }, {});
}

export default customFieldColumns;
export { columnsDefaultValues, columnsFilters, tableDefaultValues };
