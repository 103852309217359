/* eslint-disable max-len */
/**
 * Definição de variáveis conforme o ambiente.
 *
 * Essas variáveis serão EXPOSTAS no bundle final, portanto só devem ser incluídas
 * aqui variáveis que NÃO sejam sensíveis.
 */

const RAILS_ENV = process.env.RAILS_ENV;

let LEGACY_HOST;
let COOKIE_DOMAIN;

let SENTRY_DSN;
let INTERCOM_APP_ID;
let MIXPANEL_TOKEN;

let UPLOAD_BACKEND;

let ADYEN_CLIENT_KEY;
let ADYEN_CLIENT_ENV;

let GOOGLE_MAPS_EMBED_KEY;
let STRIPE_API_KEY;
let AGENDOR_CHAT_URL;
let AGENDOR_CHAT_UNIQ_URL;

switch (RAILS_ENV) {
  case 'production':
    COOKIE_DOMAIN = '.agendor.com.br';
    LEGACY_HOST = 'https://web.agendor.com.br';

    SENTRY_DSN = 'https://6a34981c6b574c2f863f6c499f467123@o319937.ingest.sentry.io/5242450';
    INTERCOM_APP_ID = 'ac691553c8b72b18afd877cab0c20e6026938888';
    MIXPANEL_TOKEN = '8ce2e81ecd2422f3687b0fbe34e6e713';

    UPLOAD_BACKEND = 'S3';

    ADYEN_CLIENT_KEY = 'live_42D55ARIZBBZFIAKDWHYSNEA3AYOPN6J';
    ADYEN_CLIENT_ENV = 'live';

    GOOGLE_MAPS_EMBED_KEY = 'AIzaSyCVvbOa7N05nS7NyNuhZgl9-uiSiOa83Lo';

    STRIPE_API_KEY = 'pk_live_51MoWAqFBUE5ialPBd7YsHssGZ7wEAgzVRJw0HZb2kUzSINuAiGj2d4kR3Cg5dqxKc8QpqPHYnY3VoQzhXOrKy8CJ00DLKcAecn';
    AGENDOR_CHAT_URL = 'https://chat.agendor.com.br';
    AGENDOR_CHAT_UNIQ_URL = 'https://chat-uniq.agendor.com.br';
    break;

  case 'staging':
    COOKIE_DOMAIN = '.staging.agendor.com.br';
    LEGACY_HOST = 'https://web.staging.agendor.com.br';

    SENTRY_DSN = 'https://6a34981c6b574c2f863f6c499f467123@o319937.ingest.sentry.io/5242450';
    INTERCOM_APP_ID = 'r70x4aa5';
    MIXPANEL_TOKEN = '266382e5ea3ddd8f846c8bfe2ee8d7c0';

    UPLOAD_BACKEND = 'S3';

    ADYEN_CLIENT_KEY = 'test_CNHF7RPNC5HLZIJ3KN5A7B73NQ62YW62';
    ADYEN_CLIENT_ENV = 'test';

    GOOGLE_MAPS_EMBED_KEY = 'AIzaSyCVvbOa7N05nS7NyNuhZgl9-uiSiOa83Lo';

    STRIPE_API_KEY = 'pk_test_51MoWAqFBUE5ialPB3Bl9zUdKtgyJVVNIaQYx2XCF4rAqP9f6tjrB6dfMLvfkvPWsqKSIgCAYam76h8N1amJUNRZl00JU2JvnzI';
    AGENDOR_CHAT_URL = 'https://chat.staging.agendor.com.br';
    AGENDOR_CHAT_UNIQ_URL = 'https://chat-uniq.staging.agendor.com.br';

    break;

  case 'development':
    COOKIE_DOMAIN = 'localhost';
    LEGACY_HOST = 'http://localhost:5000';

    MIXPANEL_TOKEN = '61bc4756f9dee8bdadef2e45d7dec076';
    INTERCOM_APP_ID = 'r70x4aa5';

    ADYEN_CLIENT_KEY = 'test_CNHF7RPNC5HLZIJ3KN5A7B73NQ62YW62';
    ADYEN_CLIENT_ENV = 'test';

    GOOGLE_MAPS_EMBED_KEY = 'AIzaSyCVvbOa7N05nS7NyNuhZgl9-uiSiOa83Lo';

    STRIPE_API_KEY = 'pk_test_51MoWAqFBUE5ialPB3Bl9zUdKtgyJVVNIaQYx2XCF4rAqP9f6tjrB6dfMLvfkvPWsqKSIgCAYam76h8N1amJUNRZl00JU2JvnzI';
    AGENDOR_CHAT_URL = 'https://chat.staging.agendor.com.br';
    AGENDOR_CHAT_UNIQ_URL = 'https://chat-uniq.staging.agendor.com.br';
    break;

  case 'test':
    LEGACY_HOST = 'http://test';

    break;

  default:
    // Em ambientes desconhecidos, as variáveis vão ficar indefinidas.
}

export {
  RAILS_ENV,
  LEGACY_HOST,
  COOKIE_DOMAIN,
  SENTRY_DSN,
  INTERCOM_APP_ID,
  MIXPANEL_TOKEN,
  UPLOAD_BACKEND,
  ADYEN_CLIENT_KEY,
  ADYEN_CLIENT_ENV,
  GOOGLE_MAPS_EMBED_KEY,
  STRIPE_API_KEY,
  AGENDOR_CHAT_URL,
  AGENDOR_CHAT_UNIQ_URL
};
