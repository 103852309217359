import { useAuth } from '@/lib/auth';

export function useIntercomUserData() {
  const { user } = useAuth();

  if (!user) {
    return {};
  }

  return {
    userId: user.id,
    email: user.email,
    name: user.name,
    customAttributes: {
      'ID Usuário': user.id,
      'Cargo': user.jobTitle,
      'Persona': user.persona,
      'CRM Experience': user?.crmExperience
    },
    company: {
      companyId: user.account.id,
      name: user.account.name,
      createdAt: user.account.createdAt,
      plan: user.account.plan.name,
      size: user.account.salespersonNumber,
      industry: user.account.segment,
      customAttributes: {
        'Tamanho da empresa': user.account.salespersonNumber,
        'Max usuarios plano': user.account.maxUsers,
        'ID Cliente': user.account.id,
        'Nome Cliente': user.account.name,
        'ID Plano': user.account.plan.id,
        'Nome Plano': user.account.plan.name,
        'Data de expiração': user?.account?.expiresAt,
        'Data de bloqueio': user?.account?.blockAccessAt,
        'Necessidade': user.account.need,
        'UTM Medium': user?.account?.utmMedium,
        'UTM Content': user?.account?.utmContent
      }
    },
    userHash: user.intercomHash
  };
}
