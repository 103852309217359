import {
  legacyDealUrl,
  legacyNewFormsOptoutUrl,
  legacyNewFunnelOptoutUrl,
  legacyNewListsOptoutUrl,
  legacyOrganizationUrl,
  legacyPersonUrl
} from '@/routes';

const buildHistoryUrl = (entity) => {
  const { type, id } = entity;
  if (type === 'person') {
    return legacyPersonUrl(id, true);
  }
  if (type === 'deal') {
    return legacyDealUrl(id, true);
  }
  return legacyOrganizationUrl(id, true);
};

const config = {
  newLists: {
    base: {
      title: 'Funcionalidades que não existem nas listagens antigas:',
      items: [
        'Visualização de todas as informações',
        'Filtros avançados',
        'Geração de leads'
      ],
      screen: 'Listagem',
      buildUrl: (entity) => legacyNewListsOptoutUrl(entity),
      showDeprecationAlert: true,
      form: {
        type: 'text',
        descriptionTitle: 'Compartilhe conosco o que sentiu falta na nova tela de listagem:'
      }
    },
    activity: {
      screen: 'Listagem',
      buildUrl: (entity) => legacyNewListsOptoutUrl(entity),
      form: {
        type: 'options',
        options: [
          {
            label: 'Não encontrei a informação que preciso',
            optionDescription: {
              question: 'Qual informação você não encontrou?'
            }
          },
          {
            label: 'A nova tem muita informação',
            optionDescription: {
              question: 'Você pode dar mais detalhes? Isso vai nos ajudar a melhorar'
            }
          },
          {
            label: 'Estou habituado com a outra versão',
            optionDescription: {
              question: 'Você pode dar mais detalhes? Isso vai nos ajudar a melhorar'
            }
          },
          {
            label: 'Outro',
            optionDescription: {
              question: 'Você pode dar mais detalhes? Isso vai nos ajudar a melhorar'
            }
          }
        ]
      }
    }
  },
  history: {
    base: {
      title: 'Funcionalidades que não existem no histórico antigo:',
      items: [
        'Telefone virtual inteligente',
        'Envio de e-mails pelo Agendor',
        'Geração de propostas',
        'Edição mais rápida',
        'Modelos de anotação'
      ],
      screen: 'Ficha',
      buildUrl: buildHistoryUrl,
      showDeprecationAlert: true,
      form: {
        type: 'text',
        descriptionTitle: 'Compartilhe conosco o que sentiu falta no histórico:'
      }
    },
    person: {
      screen: 'Feedback',
      buildUrl: buildHistoryUrl,
      showDeprecationAlert: true,
      form: {
        type: 'options',
        options: [
          {
            label: 'Não encontrei a informação que preciso',
            optionDescription: {
              question: 'Você pode dar mais detalhes? Isso vai nos ajudar a melhorar'
            }
          },
          {
            label: 'Não consigo diferenciar da Ficha do Negócio',
            optionDescription: {
              question: 'Qual informação você não encontrou?'
            }
          },
          {
            label: 'A nova tem muita informação',
            optionDescription: {
              question: 'Qual informação você não encontrou?'
            }
          },
          {
            label: 'Estou habituado com a outra versão',
            optionDescription: {
              question: 'Qual informação você não encontrou?'
            }
          },
          {
            label: 'Outro',
            optionDescription: {
              question: 'Qual informação você não encontrou?'
            }
          }
        ]
      }
    },
    organization: {
      screen: 'Feedback',
      buildUrl: buildHistoryUrl,
      showDeprecationAlert: true,
      form: {
        type: 'options',
        options: [
          {
            label: 'Não encontrei a informação que preciso',
            optionDescription: {
              question: 'Você pode dar mais detalhes? Isso vai nos ajudar a melhorar'
            }
          },
          {
            label: 'Não consigo diferenciar da Ficha do Negócio',
            optionDescription: {
              question: 'Qual informação você não encontrou?'
            }
          },
          {
            label: 'A nova tem muita informação',
            optionDescription: {
              question: 'Qual informação você não encontrou?'
            }
          },
          {
            label: 'Estou habituado com a outra versão',
            optionDescription: {
              question: 'Qual informação você não encontrou?'
            }
          },
          {
            label: 'Outro',
            optionDescription: {
              question: 'Qual informação você não encontrou?'
            }
          }
        ]
      }
    }
  },
  newFunnel: {
    base: {
      title: 'Funcionalidades que não existem no funil antigo:',
      items: [
        'Múltiplos funis',
        'Geração de leads'
      ],
      screen: 'Funil',
      buildUrl: () => legacyNewFunnelOptoutUrl(),
      showDeprecationAlert: true,
      form: {
        type: 'text',
        descriptionTitle: 'Compartilhe conosco o que sentiu falta no novo funil de negócios:'
      }
    }
  },
  newForms: {
    base: {
      title: 'Funcionalidades que não existem nos formulários antigos:',
      items: [
        'Autopreenchimento por CNPJ',
        'Campos customizados'
      ],
      screen: 'Formulario',
      buildUrl: (entity) => legacyNewFormsOptoutUrl(entity),
      showDeprecationAlert: true,
      form: {
        type: 'text',
        descriptionTitle: 'Compartilhe conosco o que sentiu falta nos novos formulários:'
      }
    }
  }
};

export default config;
