const eligibleIndustries = [
  'Distribuidoras e revendedoras',
  'Indústrias e fábricas',
  'Transporte, armazenagem e logística'
];

export function canSetupAccount({ user }) {
  const {
    account: { segment },
    privileges: { account_owner: isOwner }
  } = user;

  let effect;

  if (isOwner && eligibleIndustries.includes(segment)) {
    effect = 'allow';
  }

  return { effect };
}
