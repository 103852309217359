import React from 'react';
import { useActivityCard } from '@/components/EntityModal/Activities/ActivityCard/context';
import Email from '@/components/EntityModal/Activities/ActivityCard/Footer/Email';
import Base from '@/components/EntityModal/Activities/ActivityCard/Footer/Base';

function Footer() {
  const { isEmail } = useActivityCard();

  if (isEmail) {
    return <Email />;
  }

  return <Base />;
}

export default Footer;
