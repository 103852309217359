import React from 'react';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import { useFilters, useUpdateFilters } from '@/contexts/task-list';
import Tooltip from '@/components/Tooltip';
import classnames from 'classnames';

const ACTIONS = [
  { value: 'email', label: 'Email' },
  { value: 'call', label: 'Ligação' },
  { value: 'whatsapp', label: 'WhatsApp' },
  { value: 'proposal', label: 'Proposta' },
  { value: 'visit', label: 'Visita' },
  { value: 'meeting', label: 'Reunião' },
  { value: 'task', label: 'Tarefa' }
];

const TypeFilter = () => {
  const { types_in: typesIn = [] } = useFilters();
  const updateFilters = useUpdateFilters();

  const toggleMetric = (id) => {
    updateFilters({
      types_in: typesIn.includes(id)
        ? typesIn.filter((metric) => metric !== id)
        : [...typesIn, id]
    });
  };

  return (
    <div className='d-flex gap-2'>
      {ACTIONS.map(({ value, label }) => (
        <Tooltip content={`Filtrar por ${label}`} key={value}>
          <Button
            variant={typesIn.includes(value) ? 'light-blue' : 'white'}
            className={
              classnames(
                'p-2',
                { 'active text-primary': typesIn.includes(value) }
              )}
            onClick={() => toggleMetric(value)}
          >
            <Icon name={value} />
          </Button>
        </Tooltip>
      ))}
    </div>
  );
};

export default TypeFilter;
