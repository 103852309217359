import React, { useCallback, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useFormik, FormikProvider, yupToFormErrors, prepareDataForValidation } from 'formik';
import {
  useFirstRenderEffect,
  useDidUpdateEffect,
  useDebounce
} from '@/hooks';

const propTypes = {
  children: PropTypes.func.isRequired,
  name: PropTypes.string,
  className: PropTypes.string,
  initialValues: PropTypes.object,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  initialSubmit: PropTypes.bool,
  submitOnDefaultChange: PropTypes.bool,
  validationSchema: PropTypes.object,
  initialErrors: PropTypes.object
};

const defaultProps = {
  className: '',
  defaultValues: {},
  initialSubmit: false,
  submitOnDefaultChange: false
};

const defaultDebounceTime = 600;

const FormProvider = React.forwardRef(
  ({
    children, defaultValues, initialValues, onSubmit, onReset,
    initialSubmit, submitOnDefaultChange, validationSchema, validationContext, initialErrors,
    validateOnMount
  }, ref) => {
    const validate = useCallback((values) => {
      if (!validationSchema) {
        return;
      }
      return validationSchema
        .validate(
          prepareDataForValidation(values),
          { abortEarly: false, context: validationContext }
        )
        .then(() => ({}))
        .catch((err) => yupToFormErrors(err));
    }, [validationSchema, validationContext]);

    const formik = useFormik({
      initialValues: defaultValues,
      onSubmit,
      onReset,
      initialErrors,
      validate,
      validateOnMount
    });
    const { setValues, submitForm, resetForm } = formik;

    useFirstRenderEffect(() => {
      if (initialValues) {
        setValues({ ...defaultValues, ...initialValues });
      }

      if (initialSubmit) {
        submitForm();
      }
    }, [initialValues, setValues, defaultValues, initialSubmit, submitForm]);

    useDidUpdateEffect(() => {
      resetForm({ values: defaultValues });

      if (submitOnDefaultChange) {
        submitForm();
      }
    }, [defaultValues, resetForm, submitForm]);

    const debounceHandler = useDebounce(submitForm, defaultDebounceTime);
    const debouncedSubmitForm = useCallback((_, extra = {}) => {
      const { debounce: { cancel } = { cancel: false } } = extra;

      if (cancel) {
        debounceHandler.cancel();
        submitForm();
      } else {
        debounceHandler();
      }
    }, [debounceHandler, submitForm]);

    useImperativeHandle(ref, () => formik, [formik]);

    return (
      <FormikProvider value={formik}>
        {children({ ...formik, debouncedSubmitForm })}
      </FormikProvider>
    );
  }
);

FormProvider.propTypes = propTypes;
FormProvider.defaultProps = defaultProps;
FormProvider.displayName = 'FormProvider';


export default FormProvider;
