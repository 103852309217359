import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import DatePickerInput from '@/components/Inputs/DatePickerInput';

const propTypes = {
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string
};

export const dateCustomFieldValidations = Yup.string()
  .nullable()
  .typeError('Este campo deve ser uma data.');

export function DateCustomField({ field, label, value, onChange, ...otherProps }) {
  return (
    <DatePickerInput
      label={label ?? field.name}
      placeholder='Escolha uma data'
      variant='light'
      autoComplete='off'
      value={value}
      onChange={onChange}
      { ...otherProps }
    />
  );
}

DateCustomField.propTypes = propTypes;

export default DateCustomField;
