import React, { useState } from 'react';
import classnames from 'classnames';
import {
  DOC_EXTENSIONS,
  MEDIA_EXTENSIONS,
  PDF_EXTENSION,
  VIDEO_EXTENSIONS
} from '@/utils';
import Icon from '@/components/Icon';
import Truncate from '@/components/Truncate';
import Button from '@/components/Button';
import ExternalLink from '@/components/ExternalLink';
import AudioPlayer from '@/components/AudioPlayer';
import ImageCarouselModal from '@/components/ImageCarouselModal';
import ProposalFileDropdown from '@/components/Proposal/ProposalFileDropdown';

function DocumentLink({ url, fileName, iconName, download }) {
  return (
    <Button
      as={ExternalLink}
      target={download ? '_self' : '_blank'}
      href={url}
      size='sm'
      variant='light'
      className={classnames(
        'd-inline-flex align-items-center mt-2 me-1',
        'cursor-pointer text-dark-gray text-small p-1'
      )}
    >
      <Icon withStroke size='sm' name={iconName} className='me-1' />
      <Truncate as='span'>{fileName}</Truncate>
    </Button>
  );
}

function AudioPlayerLink({ url, fileName, iconName }) {
  return (
    <AudioPlayer src={url}>
      <Button
        size='sm'
        variant='light'
        className={classnames(
          'd-inline-flex align-items-center mt-2 me-1',
          'cursor-pointer text-dark-gray text-small p-1'
        )}
      >
        <Icon withStroke size='sm' name={iconName} className='me-1' />
        <Truncate as='span'>{fileName}</Truncate>
      </Button>
    </AudioPlayer>
  );
}

function FileList({
  documents = [],
  images = [],
  isFromProposal,
  entityId,
  entityType,
  relatedEntity
}) {
  const [showCarousel, setShowCarousel] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  if (!documents?.length && !images?.length) {
    return null;
  }
  return (
    <>
      <ImageCarouselModal
        images={images}
        imageIndex={selectedImage}
        show={showCarousel}
        onHide={() => setShowCarousel(false)}
        onSelect={setSelectedImage}
      >
        <div>
          <div className='d-inline-block mt-2'>
            {
              images.map((image, index) => (
                <div
                  key={index}
                  className='d-inline-block cursor-pointer'
                  onClick={() => {
                    setSelectedImage(index);
                    setShowCarousel(true);
                  }}
                >
                  <img
                    className={classnames([
                      'img-fluid img-thumbnail upload-thumbnail',
                      'border-0 me-1 mt-2'
                    ])}
                    src={image.url}
                  />
                </div>
              ))
            }
          </div>
          <br />
        </div>
      </ImageCarouselModal>

      <div className='d-inline-block mt-2'>
        {
          documents.map((document, index) => {
            const fileName = document.fileName?.toLowerCase() || '';

            if (isFromProposal) {
              return (
                <ProposalFileDropdown
                  key={index}
                  fileName={document.fileName}
                  url={document.url}
                  entityId={entityId}
                  entityType={entityType}
                  relatedEntity={relatedEntity}
                />
              );
            }

            const isDoc = DOC_EXTENSIONS.some((ext) => fileName.includes(ext));
            if (isDoc) {
              const pdf = fileName.includes(PDF_EXTENSION);
              return (
                <DocumentLink
                  key={index}
                  iconName='proposal'
                  download={!pdf}
                  {...document}
                />
              );
            }

            const isMedia = MEDIA_EXTENSIONS.some((ext) => fileName.includes(ext));
            if (isMedia) {
              return <AudioPlayerLink key={index} iconName='music' {...document} />;
            }

            const isVideo = VIDEO_EXTENSIONS.some((ext) => fileName.includes(ext));
            if (isVideo) {
              return <DocumentLink key={index} iconName='play' {...document} />;
            }

            return <DocumentLink key={index} iconName='globe' download {...document} />;
          })
        }
      </div>
    </>
  );
}

export default FileList;
