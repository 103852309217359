import React from 'react';
import { useAlert } from 'react-alert';

import ActivityNavs from '@/components/EntityModal/Activities/ActivityNavs';
import { useOrganization } from '@/contexts/organizationModal';
import APIErrorMessage from '@/components/APIErrorMessage';
import {
  useUpdateActivity,
  useDeleteActivity,
  useInfiniteActivitiesByEntity
} from '@/api';
import { useStoredState } from '@/hooks';
import Sidebar from '@/components/EntityModal/Organization/Sidebar/Sidebar';
import { orderBy } from 'lodash';
import ActivityEntityFilter
  from '@/components/EntityModal/Organization/Activities/ActivityEntityFilter';
import Skeleton from '@/components/Skeleton';

const PER_PAGE = 30;

const ENTITY_NAME = 'organization';

function getActivitiesFilter(mode) {
  const variantMap = {
    organization: {
      include_related_entity: false,
      include_deals: false
    },
    organization_people: {
      include_related_entity: true,
      include_deals: false
    },
    organization_people_deals: {
      include_related_entity: true,
      include_deals: true
    }
  };
  return variantMap[mode] || variantMap.organization_people_deals;
}

function createActivityListParams(mode) {
  return {
    ...getActivitiesFilter(mode),
    per_page: PER_PAGE
  };
}

function ActivitiesContent({ activitiesQuery, activitiesFilter, setActivitiesFilter }) {
  const organization = useOrganization();
  const alert = useAlert();
  const updateActivityMutation = useUpdateActivity();
  const deleteActivityMutation = useDeleteActivity();

  const {
    data: { pages: activityPages } = {},
    isLoading,
    isFetching: isFetchingActivities,
    isFetchingNextPage: isFetchingNextPageActivities,
    fetchNextPage: fetchNextPageActivities,
    hasNextPage: hasNextPageActivities
  } = activitiesQuery;
  const activities = orderBy(activityPages?.flatMap((page) => page.data), 'createdAt', 'desc');

  const onUpdateActivity = (activityId, params, done) => {
    updateActivityMutation.mutate({ activityId, params }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='activities' action='update' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.(err);
      },
      onSuccess: () => {
        alert.show(
          'Atividade atualizada com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        done?.();
      }
    });
  };

  const onDeleteActivity = (activityId, done) => {
    deleteActivityMutation.mutate({ activityId }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='activities' action='destroy' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.(err);
      },
      onSuccess: () => {
        alert.show(
          'Atividade excluída com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        done?.();
      }
    });
  };

  return (
    <ActivityNavs
      entity={organization}
      entityName={ENTITY_NAME}
      activities={activities}
      isFetchingActivities={isFetchingActivities}
      isFetchingNextPageActivities={isFetchingNextPageActivities}
      fetchNextPageActivities={fetchNextPageActivities}
      hasNextPageActivities={hasNextPageActivities}
      isLoading={isLoading}
      onUpdateActivity={onUpdateActivity}
      onDeleteActivity={onDeleteActivity}
      detailsNavName='Detalhes da Empresa'
      FilterComponent={
        <ActivityEntityFilter
          mode={activitiesFilter}
          onChange={(mode) => setActivitiesFilter(mode)}
        />
      }
    >
      <Sidebar/>
    </ActivityNavs>
  );
}

function Activities() {
  const organization = useOrganization();

  const { value: activitiesFilter, store: setActivitiesFilter } = useStoredState(
    'organization_activities_filter',
    'organization_people_deals'
  );

  const isActivitiesEnabled = Boolean(organization?.id);
  const listParams = createActivityListParams(activitiesFilter);

  const activitiesQuery = useInfiniteActivitiesByEntity({
    entityType: 'organization',
    entityId: organization?.id,
    params: listParams,
    config: {
      enabled: isActivitiesEnabled,
      staleTime: 1000
    }
  });

  if (!organization || !activitiesQuery.isSuccess) {
    return (
      <>
        <div className='d-flex my-2'>
          <div className='flex-fill'>

            <Skeleton width={150} height={30} />
          </div>
          <Skeleton width={300} height={30} />
        </div>

        <Skeleton width='100%' height={200} className='mb-2' />
        <Skeleton width='100%' height={120} className='mb-2' />
        <Skeleton width='100%' height={160} />
      </>
    );
  }

  return (
    <ActivitiesContent
      activitiesQuery={activitiesQuery}
      activitiesFilter={activitiesFilter}
      setActivitiesFilter={setActivitiesFilter}
    />
  );
}

export default Activities;
