import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { Col, Container, Row } from 'react-bootstrap';
import { useDeleteActivity, useUpdateActivity } from '@/api';
import { useDocumentTitle, useScreenSize } from '@/hooks';
import { TaskListProvider, useActivities, useActivitiesActions } from '@/contexts/task-list';
import Main from '@/components/Main';
import Activities from '@/components/TaskList/Activities';
import APIErrorMessage from '@/components/APIErrorMessage';
import { TaskListFilters } from '@/components/Filters/TaskListFilters';
import SideBar from '@/components/TaskList/Sidebar';
import Tabs from '@/components/Tabs';
import NavLink from '@/components/NavLink';
import classNames from 'classnames';

const PAGE_TITLE = 'Tarefas';

const TABS = [
  {
    value: 'tasks',
    label: 'Tarefas'
  },
  {
    value: 'others',
    label: 'Outras opções'
  }
];

function TaskListContent() {
  const [activeTab, setActiveTab] = useState('tasks');
  const { isUpperLG, isUpperXL } = useScreenSize();
  useDocumentTitle(PAGE_TITLE);

  const alert = useAlert();
  const updateActivityMutation = useUpdateActivity();
  const deleteActivityMutation = useDeleteActivity();
  const activities = useActivities();

  const {
    isLoading,
    isFetchingActivities,
    isFetchingNextPageActivities,
    fetchNextPageActivities,
    hasNextPageActivities
  } = useActivitiesActions();

  const onUpdateActivity = (activityId, params, done) => {
    updateActivityMutation.mutate({ activityId, params }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='activities' action='update' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.(err);
      },
      onSuccess: () => {
        alert.show(
          'Atividade atualizada com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        done?.();
      }
    });
  };

  const onDeleteActivity = (activityId, done) => {
    deleteActivityMutation.mutate({ activityId }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='activities' action='destroy' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.(err);
      },
      onSuccess: () => {
        alert.show(
          'Atividade excluída com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        done?.();
      }
    });
  };


  return (
    <Main id='task-list'>
      <Container fluid={!isUpperXL} className='d-flex flex-column flexible-height pb-2' >
        <div className='d-xl-none d-block'>
          <Tabs>
            {
              TABS.map(({ value, label }) => (
                <Tabs.Item key={value}>
                  <NavLink className={classNames({ 'bg-gray bg-opacity-50': activeTab === value })}
                    active={activeTab === value}
                    onClick={() => setActiveTab(value)}
                  >
                    {label}
                  </NavLink>
                </Tabs.Item>
              ))
            }
          </Tabs>

          <hr className='mt-0 mb-4' />
        </div>
        <Row >
          {(isUpperLG || activeTab === 'tasks') && (
            <Col sm={12} xl={9}>
              <TaskListFilters />
              <Activities
                activities={activities}
                isFetchingActivities={isFetchingActivities}
                isFetchingNextPageActivities={isFetchingNextPageActivities}
                fetchNextPageActivities={fetchNextPageActivities}
                hasNextPageActivities={hasNextPageActivities}
                isLoading={isLoading}
                onUpdateActivity={onUpdateActivity}
                onDeleteActivity={onDeleteActivity}
              />
            </Col>
          )}
          {(isUpperLG || activeTab === 'others') && (
            <Col sm={12} xl={3}>
              <SideBar />
            </Col>
          )}
        </Row>
      </Container>
    </Main>
  );
}

function TaskList() {
  return (
    <TaskListProvider>
      <TaskListContent />
    </TaskListProvider>
  );
}

export default TaskList;
