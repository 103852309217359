import React, { useState } from 'react';
import { useFilters, useUpdateFilters } from '@/contexts/task-list';
import Collapse from '@/components/Collapse';
import ToggleButtonGroup from '@/components/ToggleButtonGroup';
import Icon from '@/components/Icon';
import ToggleButton from '@/components/ToggleButton';
import DateRangePopover from '@/components/DateRangePopover';
import { formatRange, parseDate } from '@/date';
import { DATE_RANGE_DEFAULT_PRESETS } from '@/date-range';

const FILTER_OPTIONS = {
  date: [
    { id: 'all', label: 'Todas' },
    { id: 'today', label: 'Hoje' },
    { id: 'week', label: 'Esta semana' }
  ]
};

const Popover = ({ children, customDateAttributes }) => {
  const [selectedFrom, setSelectedFrom] = useState();
  const [selectedTo, setSelectedTo] = useState();
  const updateFilters = useUpdateFilters();

  const onCustomDateChange = (value) => {
    const [initialDate, finalDate] = value.split('/');

    updateFilters({
      date: 'custom',
      [`initial_${customDateAttributes.show}_at`]: initialDate,
      [`final_${customDateAttributes.show}_at`]: finalDate,
      [`initial_${customDateAttributes.omit}_at`]: null,
      [`final_${customDateAttributes.omit}_at`]: null
    });
  };

  return (
    <DateRangePopover
      name='date'
      onChange={onCustomDateChange}
      presets={DATE_RANGE_DEFAULT_PRESETS}
      selectedFrom={selectedFrom}
      setSelectedFrom={setSelectedFrom}
      selectedTo={selectedTo}
      setSelectedTo={setSelectedTo}
      placement='bottom-end'
    >
      {children}
    </DateRangePopover>
  );
};

const FilterGroup = ({ name, options, filters }) => {
  const updateFilters = useUpdateFilters();

  const handleChange = (value) => {
    if (value !== 'custom') {
      updateFilters({
        [name]: value,
        initial_finished_at: null,
        final_finished_at: null,
        initial_due_at: null,
        final_due_at: null
      });
    }
  };

  const stopPropagation = (e) => e.stopPropagation();

  return (
    <ToggleButtonGroup
      type='radio'
      name={name}
      value={filters[name] || options[0].id}
      onChange={handleChange}
      className='min-height-100'
    >
      {options.map(({ id, label }) => (
        <ToggleButton
          key={id}
          id={id}
          value={id}
          className={
            filters[name] === id
              ? 'bg-light-primary text-dark-primary border-0 text-nowrap'
              : 'bg-white border-0 text-nowrap'
          }
        >
          {label}
        </ToggleButton>
      ))}
      <ToggleButton
        id='custom'
        value='custom'
        className={
          filters[name] === 'custom'
            ? 'bg-light-primary text-dark-primary border-0 text-nowrap'
            : 'bg-white border-0 text-nowrap'
        }
        onClick={stopPropagation}
      >
        Definir
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const CustomFilterDates = ({ filters, customDateAttributes }) => {
  const updateFilters = useUpdateFilters();

  const initialDate = filters[`initial_${customDateAttributes.show}_at`];
  const finalDate = filters[`final_${customDateAttributes.show}_at`];

  const closeCustomFilter = (e) => {
    updateFilters({
      date: 'all',
      initial_finished_at: null,
      final_finished_at: null,
      initial_due_at: null,
      final_due_at: null
    });
    e.stopPropagation();
  };

  return (
    <ToggleButtonGroup name='name'>
      <ToggleButton
        className='d-flex align-items-center text-nowrap text-primary'
        variant='light-primary'
        aria-label='Adicionar negócio'
      >
        {formatRange(initialDate && parseDate(initialDate), finalDate && parseDate(finalDate))}
      </ToggleButton>
      <ToggleButton
        className='bg-white border-0'
        onClick={closeCustomFilter}
      >
        <Icon name='close' size='fixed' />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const DateRangeFilter = () => {
  const filters = useFilters();

  const hasDefinedCustomDates = filters.date === 'custom' && (
    filters.initial_due_at ||
    filters.final_due_at ||
    filters.initial_finished_at ||
    filters.final_finished_at
  );

  const customDateAttributes = filters.status === 'pending'
    ? { show: 'due', omit: 'finished' }
    : { show: 'finished', omit: 'due' };

  return (
    <Popover customDateAttributes={customDateAttributes}>
      <div className='d-flex justify-content-end' style={{ width: 290 }}>
        <Collapse in={!hasDefinedCustomDates} dimension='width'>
          <div>
            <FilterGroup
              filters={filters}
              name='date'
              options={FILTER_OPTIONS.date}
              customDateAttributes={customDateAttributes}
            />
          </div>
        </Collapse>
        <Collapse in={Boolean(hasDefinedCustomDates)} dimension='width'>
          <div>
            <CustomFilterDates filters={filters} customDateAttributes={customDateAttributes} />
          </div>
        </Collapse>
      </div>
    </Popover>
  );
};
export default DateRangeFilter;
