import { useQuery, useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { get, post, patch, _delete } from '@/lib/fetch';
import sortBy from 'lodash/sortBy';

export const productsKeys = {
  all: ['products'],
  lists: () => [...productsKeys.all, 'list'],
  list: (params) => [...productsKeys.lists(), ...params],
  infiniteAll: (params) => [...productsKeys.lists(), 'infinite', params],
  categories: ['products', 'categories'],
  listCategories: (params) => [...productsKeys.categories, params]
};

export async function getProducts(params, options) {
  const { data } = await get('/products', params, options);

  return data;
}

export async function deleteProduct({ id }) {
  const { data } = await _delete(`/products/${id}`);

  return data;
}

export async function getProductCategories(params = {}) {
  const { data } = await get('/product_categories', params);

  return data;
}

export async function createProduct(params = {}) {
  const { data } = await post('/products', params);

  return data;
}

export async function updateProduct({ productId, params = {} } = {}) {
  const { data } = await patch(`/products/${productId}`, params);

  return data;
}

export async function createProductCategory(params = {}) {
  const { data } = await post('/product_categories', params);

  return data;
}

export async function updateProductCategory({ id, params = {} } = {}) {
  const { data } = await patch(`/product_categories/${id}`, params);

  return data;
}

export async function deleteProductCategory({ id, params = {} } = {}) {
  const { data } = await _delete(`/product_categories/${id}`, params);

  return data;
}

export function useProducts({ params = {}, config = {} } = {}) {
  const queryClient = useQueryClient();

  const { selected_ids: selectedIds, ...filters } = params;

  return useQuery({
    // Para que não ocorra "piscada" nas opções do select durante a busca.
    keepPreviousData: true,
    ...config,
    queryKey: productsKeys.list([filters, selectedIds]),
    queryFn: ({ signal }) => {
      if (selectedIds) {
        const filtersKey = productsKeys.list([filters]);
        const queriesData = queryClient.getQueriesData(filtersKey);
        for (const [, previousData] of queriesData) {
          if (previousData) {
            const containsSelectedIds = selectedIds.every((selectedId) => (
              previousData.data.find((item) => item.id === selectedId)
            ));

            if (containsSelectedIds) {
              return previousData;
            }
          }
        }
      }

      return getProducts(params, { signal });
    }
  });
}

export function useInfiniteProducts({ params = {}, config = {} } = {}) {
  return useInfiniteQuery({
    queryKey: productsKeys.infiniteAll(params),
    queryFn: ({ pageParam: page = 1, signal }) => getProducts({ ...params, page }, { signal }),
    getNextPageParam: (lastPage) => lastPage.meta.next,
    ...config
  });
}

export function useCreateProduct({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(productsKeys.all);

  return useMutation(
    createProduct,
    {
      onSuccess,
      ...config
    }
  );
}

export function useUpdateProduct({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(productsKeys.all);

  return useMutation(
    updateProduct,
    {
      onSuccess,
      ...config
    }
  );
}

export function useDeleteProduct({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(productsKeys.all);

  return useMutation(
    deleteProduct,
    {
      onSuccess,
      ...config
    }
  );
}

export function useProductCategories({ params = {}, config = {} }) {
  return useQuery({
    ...config,
    queryKey: productsKeys.listCategories(params),
    queryFn: () => getProductCategories(params)
  });
}

export function useCreateProductCategory({ config = {} } = {}) {
  const key = productsKeys.listCategories({});
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(key);
  const onError = (_err, _params, rollback) => rollback();
  const onMutate = (params) => {
    queryClient.cancelQueries(key);

    const previousData = queryClient.getQueryData(key);
    const newItems = sortBy(
      previousData.data.concat(params),
      (item) => item.name.toUpperCase()
    );

    queryClient.setQueryData(key, { data: newItems });

    return () => queryClient.setQueryData(key, previousData);
  };

  return useMutation(
    createProductCategory,
    {
      onSuccess,
      onError,
      onMutate,
      ...config
    }
  );
}

export function useUpdateProductCategory({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(productsKeys.all);

  return useMutation(
    updateProductCategory,
    {
      onSuccess,
      ...config
    }
  );
}

export function useDeleteProductCategory({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = () => queryClient.invalidateQueries(productsKeys.categories);

  return useMutation(
    deleteProductCategory,
    {
      onSuccess,
      ...config
    }
  );
}
