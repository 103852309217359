import { baseLegacyUrl } from '@/routes';

export function legacyTasksUrl() {
  return `${baseLegacyUrl()}/tarefas`;
}

export function newTaskListUrl() {
  return '/tasks';
}

export function legacyTaskUrl(id, forceLegacy = false) {
  return `${baseLegacyUrl()}/tarefas/historico.php?id=${id}${forceLegacy ? '&legado=1' : ''}`;
}
