import React, { useMemo, useRef } from 'react';
import { useAlert } from 'react-alert';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { getValidationErrors } from '@/utils';
import { useCreateDeal, useDealTemplate } from '@/api';
import { isUnprocessableEntityError } from '@/errors';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import Button from '@/components/Button';
import Popover from '@/components/Popover';
import { PopoverProvider } from '@/contexts';
import LoadingButton from '@/components/LoadingButton';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import {
  CurrencyInput,
  CustomerSelect,
  DatePickerInput,
  FunnelSelect,
  TextArea,
  TextInput,
  UserSelect
} from '@/components/Inputs';
import LoadingWrapper from '@/components/LoadingWrapper';
import APIErrorMessage from '@/components/APIErrorMessage';
import Alert from '@/components/QuickForms/Alert';

const propTypes = {
  children: PropTypes.node.isRequired,
  entityId: PropTypes.number,
  entityType: PropTypes.oneOf(['organization', 'person']),
  handleHide: PropTypes.func
};

const defaultProps = {
  handleHide: () => {}
};

const CUSTOMER_PARAMS = {
  organization: 'organization_id',
  person: 'person_id'
};

/* eslint-disable no-magic-numbers */
const schema = Yup.object().shape({
  title: Yup.string()
    .max(150, 'O nome do negócio deve ter no máximo 150 caracteres.')
    .required('Por favor, informe o nome do negócio.'),
  value: Yup.number()
    .nullable()
    .typeError('O valor do negócio deve ser um número.')
    .min(0, 'O valor do negócio não pode ser negativo.'),
  description: Yup.string()
    .max(2000, 'A descrição do negócio deve ter no máximo 2000 caracteres.')
});
/* eslint-enable no-magic-numbers */

function CreateDealForm({ entityId, entityType, onHide, createDealMutation }) {
  const ownerUserContainerRef = useRef(null);
  const funnelContainerRef = useRef(null);
  const alert = useAlert();
  const { user } = useAuth();
  const entityParams = getQueryParams({ entityId, entityType });
  const tracker = useTracking();

  const { data: { data: templateData } = {}, isLoading, refetch } = useDealTemplate({
    params: entityParams,
    config: { staleTime: 1000 }
  });

  const initialValues = useMemo(() => {
    const { account } = user;
    if (!templateData) {
      return {};
    }

    const customerEntity = templateData.organization || templateData.person;

    return {
      ...entityParams,
      title: templateData.name,
      value: templateData.value?.toString() || '',
      description: templateData.description || '',
      owner_user_id: user.id,
      funnel_id: account.defaultFunnel.id,
      customer: customerEntity
        ? {
          id: `${entityType}-${customerEntity.id}`,
          text: customerEntity.name || ''
        }
        : { id: '', text: '' },
      privacy: { type: account.privacy }
    };
  }, [templateData, user]);

  const onSubmit = (formData, { setFieldError, setSubmitting, setFieldValue }) => {
    createDealMutation.mutate(formData, {
      onSuccess: ({ data }) => {
        tracker.trackDealCreated({
          user,
          quickAdded: true,
          source: entityType
        });

        alert.show(
          <Alert
            entityType='deal'
            entityId={data.id}
            entityName={data.title}
          />,
          { variant: 'success', timeout: 15000 }
        );

        onHide();
      },
      onError: async (error) => {
        const errors = getValidationErrors(error, 'deal');

        Object.entries(errors).forEach(([key, message]) => {
          setFieldError(key, message);
        });

        alert.show(
          <APIErrorMessage err={error} resource='deal' action='create' />,
          { variant: 'danger' }
        );

        // Caso o título seja duplicado, atualiza com um novo título da rota de deal-template
        if (isUnprocessableEntityError(error)) {
          const titleErrors = error.data.errors.title;
          if (titleErrors && titleErrors.includes('taken')) {
            const { data: { data } = {} } = await refetch();
            setFieldValue('title', data.name);
          }
        }

        setSubmitting(false);
      }
    });
  };

  const customerProps = useMemo(() => {
    if (!entityType) {
      return { label: 'Empresa/Pessoa', disabled: false };
    }

    return {
      label: entityType === 'organization' ? 'Empresa' : 'Pessoa',
      disabled: true
    };
  }, [entityType]);

  const { label: customerLabel, disabled: customerDisabled } = customerProps;

  return (
    <div>
      <LoadingWrapper isLoading={isLoading}>
        <h4 className='mb-4'>Adicionar negócio</h4>

        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={schema}
        >
          {({ isSubmitting, setFieldValue }) => (
            <>
              <Row className='gx-3'>
                <Col sm={12}>
                  <FormField
                    as={TextInput}
                    name='title'
                    label='Título do negócio'
                    autoComplete='off'
                    placeholder='Dê um nome para o negócio'
                    maxLength={150}
                    variant='light'
                    data-autofocus='true'
                    autoFocus
                  />
                </Col>
              </Row>

              <div ref={ownerUserContainerRef} className='position-relative mb-4'>
                <Row className='gx-3'>
                  <Col sm={6}>
                    <FormField
                      as={CustomerSelect}
                      name='customer'
                      label={customerLabel}
                      initialValue={initialValues.customer}
                      disabled={customerDisabled}
                      isSearchable
                      onChange={(customer) => {
                        if (customer && customer.id) {
                          const [type, id] = customer.id.split('-');
                          const param = CUSTOMER_PARAMS[type];
                          setFieldValue(param, id);
                        }
                      }}
                    />
                  </Col>
                  <Col sm={6}>
                    <FormField
                      as={UserSelect}
                      name='owner_user_id'
                      label='Responsável'
                      isSearchable
                      menuPortalTarget={ownerUserContainerRef.current}
                      menuPosition={'absolute'}
                      className='mb-0 flex-fill min-width-0'
                      styles={{
                        menuPortal: (menuPortalProps) => ({
                          ...menuPortalProps,
                          zIndex: 1265,
                          right: 0,
                          left: 0,
                          top: '100%',
                          width: 'auto'
                        })
                      }}
                    />
                  </Col>
                </Row>
              </div>

              <div ref={funnelContainerRef} className='position-relative mb-4'>
                <Row className='gx-3'>
                  <Col sm={6}>
                    <FormField
                      as={CurrencyInput}
                      className=''
                      name='value'
                      label='Valor estimado'
                      autoComplete='off'
                      placeholder='R$ 0,00'
                    />
                  </Col>

                  <Col sm={6}>
                    <FormField
                      as={FunnelSelect}
                      name='funnel_id'
                      label='Funil'
                      isSearchable
                      menuPortalTarget={funnelContainerRef.current}
                      menuPosition={'absolute'}
                      className='mb-0 flex-fill min-width-0'
                      styles={{
                        menuPortal: (menuPortalProps) => ({
                          ...menuPortalProps,
                          zIndex: 1265,
                          right: 0,
                          left: 0,
                          top: '100%',
                          width: 'auto'
                        })
                      }}
                    />
                  </Col>
                </Row>
              </div>

              <Row className='gx-3'>
                <Col sm={6}>
                  <FormField
                    as={DatePickerInput}
                    name='started_at'
                    label='Data de início'
                    placeholder='00/00/0000'
                    variant='light'
                  />
                </Col>
                <Col sm={6}>
                  <FormField
                    as={DatePickerInput}
                    name='finished_at'
                    label='Data de conclusão'
                    placeholder='00/00/0000'
                    variant='light'
                  />
                </Col>

                <Col sm={12}>
                  <FormField
                    as={TextArea}
                    name='description'
                    label='Descrição'
                    autoComplete='off'
                    variant='light'
                    maxLength={2000}
                    placeholder='Descreva detalhes importantes sobre essa negociação'
                  />
                </Col>
              </Row>

              <Row className='gx-3 mt-4'>
                <Col sm={6}>
                  <Button
                    className='w-100'
                    onClick={onHide}
                    disabled={isSubmitting}
                    variant='outline-dark-gray'
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col sm={6}>
                  <LoadingButton
                    className='w-100'
                    loadingText='Salvando...'
                    type='submit'
                    isLoading={isSubmitting}
                  >
                    Salvar
                  </LoadingButton>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </LoadingWrapper>
    </div>
  );
}

function AddDealPopover({ entityId, entityType, children, handleHide }) {
  const createDealMutation = useCreateDeal();
  const container = useRef(null);

  return (
    <Popover
      placement='left-start'
      flip
      showArrow
      rootClose
      rootCloseEvent='mousedown'
      onExit={handleHide}
      content={
        ({ onHide }) => (
          <PopoverProvider value={{ container }}>
            <div ref={container}>
              <CreateDealForm
                entityId={entityId}
                entityType={entityType}
                onHide={() => {
                  onHide();
                  handleHide();
                }}
                createDealMutation={createDealMutation}
              />
            </div>
          </PopoverProvider>
        )
      }
    >
      <div>
        {children}
      </div>
    </Popover>
  );
}

function getQueryParams({ entityId, entityType }) {
  switch (entityType) {
    case 'organization':
      return { organization_id: entityId };
    case 'person':
      return { person_id: entityId };
    default:
      return {};
  }
}

AddDealPopover.propTypes = propTypes;
AddDealPopover.defaultProps = defaultProps;

export default AddDealPopover;
