import React, { useState } from 'react';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import Button from '@/components/Button';
import EmailVerifyListAlert from '@/feature/email/EmailVerifyListAlert';
import { goBack } from '@/browser';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import { RichTextInput, TextInput, UserSelect } from '@/components/Inputs';
import EmailTemplateList from '@/components/Email/EmailTemplateList';
import AttachmentUpload from '@/components/EntityModal/Activities/AttachmentUpload';
import Breadcrumb from '@/components/Breadcrumb';
import { useAutomationContext } from '@/pages/settings/automations/modal';
import isEmpty from 'lodash/isEmpty';
import strings from '@/strings';

/* eslint-disable no-magic-numbers */
const validationSchema = Yup.object().shape({
  from: Yup.string()
    .nullable()
    .required('Por favor, selecione o remetente.'),
  to: Yup.array()
    .of(Yup.string())
    .nullable()
    .required('Por favor, selecione pelo menos um destinatário.'),
  subject: Yup.string()
    .required('Por favor, preencha o assunto do e-mail.')
    .max(255, 'Assunto deve ter no máximo 255 caracteres'),
  body: Yup.string()
    .required('Por favor, preencha o corpo do e-mail.')
    /**
     * Esse limite é uma aproximação, obtida considerando que o tamanho total
     * dos parâmetros tem um limite de 64 KiB, mas que o restante dos campos vai
     * ocupar um espaço na ordem de 10 KiB.
     */
    .max(50000, 'Corpo deve ter no máximo 50 kB.')
});
/* eslint-enable no-magic-numbers */

const fromDefaultOptions = [
  {
    options: [
      {
        value: 'owner_user',
        label: strings.labels.automation.owner_user
      }
    ]
  }
];

const toDefaultOptions = [
  {
    options: [
      {
        value: 'related_entity',
        label: strings.labels.automation.related_entity
      },
      {
        value: 'owner_user',
        label: strings.labels.automation.owner_user
      }
    ]
  }
];

const defaultValues = {
  from: 'owner_user',
  to: ['related_entity'],
  cc: [],
  bcc: [],
  subject: '',
  body: ''
};

function AutomationsEmail() {
  const location = useLocation();
  const crumb = location.pathname.includes('edit')
    ? 'Editar automação'
    : 'Criar nova automação';

  const { initialValues, setInitialValues } = useAutomationContext();
  const { action_params: actionParams = {} } = initialValues;

  const [showCc, setShowCc] = useState(!isEmpty(actionParams?.cc));
  const openCc = () => setShowCc(true);

  const [showBcc, setShowBcc] = useState(!isEmpty(actionParams?.bcc));
  const openBcc = () => setShowBcc(true);

  const onSubmit = (values) => {
    setInitialValues({ ...initialValues, action_params: values });
    goBack();
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item linkAs='span'>
          {crumb}
        </Breadcrumb.Item>

        <Breadcrumb.Item active>
          Adicionar e-mail
        </Breadcrumb.Item>
      </Breadcrumb>

      <EmailVerifyListAlert />

      <h2 className='mb-4'>
        Adicionar e-mail
      </h2>

      <Form
        defaultValues={defaultValues}
        initialValues={actionParams}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {
          () => (
            <>
              <FormField
                as={UserSelect}
                label='De'
                name='from'
                defaultOptions={fromDefaultOptions}
              />

              <Form.Label htmlFor='to'>
                Para
              </Form.Label>
              <div className='d-flex gap-1 align-items-start'>
                <FormField
                  as={UserSelect}
                  label=''
                  name='to'
                  defaultOptions={toDefaultOptions}
                  multiple
                  className='mb-4 flex-1'
                />

                {
                  !showCc &&
                  <Button
                    variant='outline-dark-gray'
                    onClick={openCc}
                  >
                    Cc
                  </Button>
                }

                {
                  !showBcc &&
                  <Button
                    variant='outline-dark-gray'
                    onClick={openBcc}
                  >
                    Cco
                  </Button>
                }
              </div>

              {
                showCc &&
                <FormField
                  as={UserSelect}
                  label='Cc'
                  name='cc'
                  defaultOptions={toDefaultOptions}
                  multiple
                />
              }

              {
                showBcc &&
                <FormField
                  as={UserSelect}
                  label='Cco'
                  name='bcc'
                  defaultOptions={toDefaultOptions}
                  multiple
                />
              }

              <Form.Label htmlFor='subject'>
                Assunto
              </Form.Label>
              <div className='d-flex gap-2 align-items-start'>
                <FormField
                  as={TextInput}
                  name='subject'
                  className='mb-4 flex-1'
                />

                <EmailTemplateList />
              </div>

              <FormField
                autoFocus={true}
                as={RichTextInput}
                name='body'
                className='mb-4'
                toolbarButtonSize='xsmall'
              />

              <div className='d-flex gap-2 align-items-end'>
                <div className='w-50 me-auto'>
                  <FormField
                    as={AttachmentUpload}
                    name='attachments'
                    automationAttachment={true}
                  />
                </div>

                <Button
                  variant='outline-dark-gray'
                  onClick={goBack}
                >
                  Voltar
                </Button>

                <Button
                  type='submit'
                >
                  Salvar
                </Button>
              </div>
            </>
          )
        }
      </Form>
    </div>
  );
}

export default AutomationsEmail;
