import React from 'react';
import PropTypes from 'prop-types';

Divisor.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string
};

Divisor.defaultProps = {
  content: 'ou',
  className: 'text-gray my-4 w-100'
};

function Divisor({ content, className }) {
  return (
    <div className={`d-flex align-items-center ${className}`}>
      <hr className='w-50 opacity-100' />
      <span className='mx-2'> {content} </span>
      <hr className='w-50 opacity-100' />
    </div>
  );
}

export default Divisor;
