import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SearchBar from '@/components/SearchBar';
import CustomerSearch from '@/components/CustomerSearch';
import { useAuth } from '@/lib/auth';
import { useCreateOrganization, useCreatePerson, useCreateDeal } from '@/api';
import FooterActionButton from '@/components/CustomerSearch/FooterActionButton';

function FooterActions(props) {
  const { isFetching, isEmpty, isCreating, createFailed, term, items, onButtonClick } = props;

  if (isFetching) {
    return null;
  }

  return (
    <div
      className={classnames(
        'd-flex',
        'flex-column',
        'align-items-center',
        { 'pt-5': !isEmpty }
      )}
    >
      <div className='text-darker-gray fw-bold'>
        {`Criar "${term}" como novo(a):`}
      </div>

      {createFailed &&
        (
          <div className='text-center w-100 mt-5 py-2 bg-light-red'>
            Não foi possível criar o(a) {term}
          </div>
        )
      }

      <div className='d-flex align-items-center pt-5 pb-3 fw-bold'>
        <FooterActionButton
          className='me-5'
          disabled={isCreating}
          type='organization'
          items={items}
          term={term}
          onClick={onButtonClick}
        />

        <FooterActionButton
          className='me-5'
          disabled={isCreating}
          type='person'
          items={items}
          term={term}
          onClick={onButtonClick}
        />

        <FooterActionButton
          disabled={isCreating}
          type='deal'
          items={items}
          term={term}
          onClick={onButtonClick}
        />
      </div>
    </div>
  );
}

function Footer(props) {
  const {
    isFetching, isEmpty, isCreating, createFailed, term, items,
    onButtonClick, onHideOptions
  } = props;

  const handleButtonClick = (data) => onButtonClick({ ...data, onHideOptions });

  return (
    <div>
      <CustomerSearch.FooterStatus
        isFetching={isFetching}
        isEmpty={isEmpty}
      />
      <FooterActions
        isFetching={isFetching}
        isEmpty={isEmpty}
        isCreating={isCreating}
        createFailed={createFailed}
        term={term}
        items={items}
        onButtonClick={handleButtonClick}
      />
    </div>
  );
}

const propTypes = {
  onChange: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  className: PropTypes.string,
  initialValue: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string
  })
};

const defaultProps = {
  onCreate: () => { },
  initialValue: { id: '', text: '' }
};

function EntitySelect(props) {
  const { onChange, onCreate, className, initialValue, label, error, onBlur } = props;
  const { user } = useAuth();

  const { id: defaultFunnelId } = user.account.defaultFunnel;

  const [value, setValue] = useState(initialValue.text);
  const [selected, setSelected] = useState(Boolean(initialValue.text));

  useEffect(() => {
    setValue(initialValue.text);
    setSelected(Boolean(initialValue.text));
  }, [initialValue]);

  const createOrganizationMutation = useCreateOrganization();
  const createPersonMutation = useCreatePerson();
  const createDealMutation = useCreateDeal();

  const inputProps = {
    onBlur,
    error,
    placeholder: 'Empresa, pessoa ou negócio',
    label,
    autoComplete: 'off',
    maxLength: 100,
    isClearable: selected,
    disabled: selected,
    variant: 'white',
    onClear: () => {
      setSelected(false);
      onChange(null);
    }
  };

  const popoverProps = {
    className: 'popover-padding-0 w-100 max-width-100',
    size: 'lg',
    placement: 'bottom-start'
  };

  const onSelect = ({ id, name, onHideOptions, type, title }) => {
    setValue(name || title);
    setSelected(true);
    onChange({ text: name || title, id: `${type}-${id}` });
    onHideOptions();
  };

  const onButtonClickCreateEntity = ({ type, term, onHideOptions }) => {
    const mutations = {
      organization: createOrganizationMutation,
      person: createPersonMutation,
      deal: createDealMutation
    };

    const mutation = mutations[type];
    const dealMutationParams = { title: term, funnel_id: defaultFunnelId };

    mutation.mutate({
      privacy: {
        type: 'default'
      },
      owner_user_id: user.id,
      name: term,
      ...(type === 'deal' && dealMutationParams)
    }, {
      onSuccess: ({ data }) => {
        onCreate({ type });
        onSelect({ ...data, type, onHideOptions });
      }
    });
  };

  // Reseta erros da mutation de criação sempre que uma nova busca iniciar
  const onSearchStart = () => {
    createOrganizationMutation.reset();
    createPersonMutation.reset();
    createDealMutation.reset();
  };

  const resultsFooter = (resultsFooterProps) => (
    <Footer
      {...resultsFooterProps}
      isCreating={
        createOrganizationMutation.isLoading ||
        createPersonMutation.isLoading ||
        createDealMutation.isLoading
      }
      createFailed={
        createOrganizationMutation.isError ||
        createPersonMutation.isError ||
        createDealMutation.isError
      }
      onButtonClick={onButtonClickCreateEntity}
    />
  );

  return (
    <>
      <SearchBar
        name='entity-search'
        types={['organization', 'person', 'deal']}
        perPage={5}
        resultsFooter={resultsFooter}
        ItemComponent={CustomerSearch.Item}
        inputProps={inputProps}
        popoverProps={popoverProps}
        onSelect={onSelect}
        onSearchStart={onSearchStart}
        className={className}
        searchValue={value}
      />
      {
        error &&
        <div className='text-danger text-small mt-1'>
          {error}
        </div>
      }
    </>
  );
}

EntitySelect.propTypes = propTypes;
EntitySelect.defaultProps = defaultProps;

export default EntitySelect;
