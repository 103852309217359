import { useMutation, useQueryClient } from 'react-query';
import { post } from '@/lib/fetch';
import { activitiesKeys } from '@/api';
import { EMAIL_STATUSES } from '@/utils';
import Task from '@/task';

export async function createEmail(params = {}) {
  const { data } = await post('/emails', params);

  return data;
}

const getEntity = (data) => {
  const type = Task.build(data).relatedEntityType;

  if (!type) {
    return null;
  }

  return {
    type,
    id: data[type].id
  };
};

export function useCreateEmail({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const updateData = (previousData, newData) => {
    if (!previousData) {
      return;
    }

    // Feedback otimista de Enviado é mostrado mesmo antes de o envio ser completo
    const emailWithSentStatus = { ...newData.email, status: EMAIL_STATUSES.SENT };
    const dataWithSentEmails = { ...newData, email: emailWithSentStatus };

    const [firstPage, ...otherPages] = previousData.pages;

    const updatedFirstPage = {
      ...firstPage,
      data: [dataWithSentEmails, ...firstPage.data]
    };

    return {
      ...previousData,
      pages: [
        updatedFirstPage,
        ...otherPages
      ]
    };
  };

  // Atualiza dados da query evitando mutação
  const onSuccess = ({ data: newData }) => {
    queryClient.setQueriesData(
      activitiesKeys.infiniteLists(),
      (previousData) => updateData(previousData, newData)
    );

    const entity = getEntity(newData);
    if (!entity) {
      return;
    }

    const key = activitiesKeys.infiniteListsByEntity({
      entityType: entity.type,
      entityId: entity.id
    });
    queryClient.setQueriesData(key, (previousData) => updateData(previousData, newData));
  };

  const meta = {
    ongoingChecklistKey: 'create-email'
  };

  return useMutation(
    createEmail,
    {
      meta,
      ...config,
      onSuccess
    }
  );
}
