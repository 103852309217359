import React, { useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import { useCreateActivity } from '@/api';
import APIErrorMessage from '@/components/APIErrorMessage';
import Form from '@/components/EntityModal/Activities/Form';

function ActivityForm() {
  const alert = useAlert();
  const { user } = useAuth();
  const tracker = useTracking();
  const createActivityMutation = useCreateActivity();

  useEffect(() => {
    tracker.trackNewTaskListViewed({ user });
  }, [user]);

  const onCreateActivity = (params, done) => {
    createActivityMutation.mutate(params, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='activities' action='create' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.(err);
      },
      onSuccess: () => {
        alert.show(
          'Atividade cadastrada com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        done?.();
      }
    });
  };

  return (
    <Form
      isTaskList
      onCreate={onCreateActivity}
    />
  );
}

export default ActivityForm;
