import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '@/lib/auth';
import { AGENDOR_CHAT_URL, AGENDOR_CHAT_UNIQ_URL } from '@/config';

const AgendorChatContext = createContext(undefined);

export const useAgendorChatContext = () => {
  const context = useContext(AgendorChatContext);

  if (context === undefined) {
    throw new Error('useAgendorChatContext must be used within AgendorChatProvider');
  }

  return context;
};

export function AgendorChatProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth();

  // Temporarily for unique conversation test
  // eslint-disable-next-line no-magic-numbers
  const uniqueConversationAllowedAccounts = [72, 337073];

  const baseUrl = uniqueConversationAllowedAccounts.includes(user?.account?.id)
    ? AGENDOR_CHAT_UNIQ_URL
    : AGENDOR_CHAT_URL;

  const agendorChatBaseUrl = `${baseUrl}/app`;

  const [iframeSrc, setIframeSrc] = useState(agendorChatBaseUrl);

  const openAgendorChat = (src) => {
    setIframeSrc(src);
    setIsOpen(true);
  };

  const closeAgendorChat = () => {
    setIsOpen(false);
    setIframeSrc(agendorChatBaseUrl);
  };

  return (
    <AgendorChatContext.Provider
      value={{ isOpen, iframeSrc, openAgendorChat, closeAgendorChat }}
    >
      {children}
    </AgendorChatContext.Provider>
  );
}

const propTypes = {
  children: PropTypes.node.isRequired
};

AgendorChatProvider.propTypes = propTypes;
