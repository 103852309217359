import React from 'react';
import Avatar from '@/components/Avatar';
import FormField from '@/components/FormField';
import {
  CategorySelect,
  DateRangeInput,
  NextTaskSelect,
  LatestActivitySelect,
  RankingSelect,
  LeadOriginSelect,
  ProductSelect,
  SectorSelect,
  UserSelect,
  CitySelect,
  TextInput,
  ImportSelect
} from '@/components/Inputs';
import FilterState from '@/components/Columns/FilterState';
import PeoplePopover from '@/components/PeoplePopover';
import ProductsPopover from '@/components/ProductsPopover';
import Rating from '@/components/Rating';
import SocialMediaIcons from '@/components/SocialMediaIcons';
import TaskTag from '@/components/TaskTag';
import DependentFormField from '@/components/DependentFormField';
import {
  buildQueryStringKeysFrom,
  buildTableDefaultValuesFrom,
  buildColumnsDefaultValuesFrom,
  renderColumnsFiltersFrom
} from '@/components/Columns/Utils';
import { getString, concatDateForFilter, concatStringForFilter } from '@/utils';
import { DATE_RANGE_DEFAULT_PRESETS } from '@/date-range';
import { toISOString } from '@/date';
import isEmpty from 'lodash/isEmpty';

const organizationColumns = [
  {
    name: 'name',
    humanizedName: 'Nome',
    default: true,
    getValue: (organization) => organization.name,
    fixed: true
  },
  {
    name: 'cnpj',
    humanizedName: 'CNPJ',
    default: false,
    getValue: (organization) => organization.cnpj,
    queryString: {
      cnpj: {
        filterKey: 'q',
        set: (value) => value,
        concat: concatStringForFilter,
        columnName: 'cnpj'
      }
    }
  },
  {
    name: 'legal_name',
    humanizedName: 'Razão Social',
    default: false,
    getValue: (organization) => organization.legalName
  },
  {
    name: 'category',
    humanizedName: 'Categoria',
    default: true,
    getValue: (organization) => organization.category?.name,
    queryString: {
      ct: {
        filterKey: 'category_id_in',
        set: (value) => (isEmpty(value) ? [] : [Number(value)]),
        columnName: 'category'
      }
    },
    filter: {
      name: 'category_id_in',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={CategorySelect}
          allowNull
          multiple
          isSearchable
        />
      )
    }
  },
  {
    name: 'origin',
    humanizedName: 'Origem',
    default: false,
    getValue: (organization) => organization.leadOrigin?.name,
    queryString: {
      origemLead: {
        filterKey: 'lead_origin_id_in',
        set: (value) => (isEmpty(value) ? [] : [Number(value)]),
        columnName: 'origin'
      }
    },
    filter: {
      name: 'lead_origin_id_in',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={LeadOriginSelect}
          allowNull
          multiple
          isSearchable
        />
      )
    }
  },
  {
    name: 'sector',
    humanizedName: 'Setor',
    default: false,
    getValue: (organization) => organization.sector?.name,
    queryString: {
      st: {
        filterKey: 'sector_id_in',
        set: (value) => (isEmpty(value) ? [] : [Number(value)]),
        columnName: 'sector'
      }
    },
    filter: {
      name: 'sector_id_in',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={SectorSelect}
          multiple
          allowNull
          isSearchable
        />
      )
    }
  },
  {
    name: 'owner',
    humanizedName: 'Responsável',
    default: true,
    getValue: (organization) => (
      organization.ownerUser?.name
        ? (
          <span className='d-inline-flex align-items-center'>
            <Avatar
              name={organization.ownerUser?.name}
              url={organization.ownerUser?.avatarUrl}
              className='me-1'
              tooltip={false}
            />
            {organization.ownerUser?.name}
          </span>
        )
        : null
    ),
    queryString: {
      ur: {
        filterKey: 'user_id_in',
        set: (value) => (isEmpty(value) ? [] : [Number(value)]),
        columnName: 'owner'
      }
    },
    filter: {
      name: 'user_id_in',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={UserSelect}
          label='Responsável'
          multiple
          allowNull
        />
      )
    }
  },
  {
    name: 'email',
    humanizedName: 'Email',
    default: true,
    getValue: (organization) => organization.contact?.email
  },
  {
    name: 'phone',
    humanizedName: 'Telefone',
    default: true,
    getValue: (organization) => organization.contact?.availablePhone
  },
  {
    name: 'website',
    humanizedName: 'Website',
    default: false,
    getValue: (organization) => organization?.website
  },
  {
    name: 'social_medias',
    humanizedName: 'Redes sociais',
    default: false,
    getValue: (organization) => (
      organization.socialMedias?.length
        ? <SocialMediaIcons data={organization.socialMedias} />
        : null
    )
  },
  {
    name: 'cep',
    humanizedName: 'CEP',
    default: false,
    getValue: (organization) => organization.address?.postalCode
  },
  {
    name: 'country',
    humanizedName: 'País',
    default: false,
    getValue: (organization) => organization.address?.country
  },
  {
    name: 'state',
    humanizedName: 'Estado',
    default: false,
    getValue: (organization) => organization.address?.state,
    queryString: {
      e: {
        filterKey: 'state_in',
        set: (value = '') => [value.toUpperCase()],
        columnName: 'state'
      }
    },
    filter: {
      name: 'state_in',
      defaultValue: null,
      onClear: ({ setFieldValue }) => {
        setFieldValue('city_id_in', null);
      },
      renderComponent: FilterState
    }
  },
  {
    name: 'city',
    humanizedName: 'Cidade',
    default: false,
    getValue: (organization) => organization.address?.city?.name,
    queryString: {
      c: {
        filterKey: 'city_id_in',
        set: (value) => (isEmpty(value) ? [] : [Number(value)]),
        columnName: 'city'
      }
    },
    filter: {
      name: 'city_id_in',
      defaultValue: null,
      renderComponent: (filter) => (
        <DependentFormField
          key={filter.name}
          name={filter.name}
          as={CitySelect}
          source='state_in'
          target='state'
          multiple
          allowNull
          isSearchable
        />
      )
    }
  },
  {
    name: 'district',
    humanizedName: 'Bairro',
    default: false,
    getValue: (organization) => organization.address?.district,
    queryString: {
      b: {
        filterKey: 'district_i_cont',
        set: (value) => value,
        columnName: 'district'
      }
    },
    filter: {
      name: 'district_i_cont',
      defaultValue: '',
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={TextInput}
          label='Bairro'
          placeholder='Digite aqui'
          variant='light'
        />
      )
    }
  },
  {
    name: 'street_name',
    humanizedName: 'Rua',
    default: false,
    getValue: (organization) => organization.address?.streetName
  },
  {
    name: 'street_number',
    humanizedName: 'Número',
    default: false,
    getValue: (organization) => organization.address?.streetNumber
  },
  {
    name: 'additional_info',
    humanizedName: 'Complemento',
    default: false,
    getValue: (organization) => organization.address?.additionalInfo
  },
  {
    name: 'products',
    humanizedName: 'Produtos',
    default: false,
    getValue: (organization) => {
      const productsEmptyListMessage = getString(
        ['messages', 'organization', 'products', 'empty_list']
      );

      return (
        <ProductsPopover
          entity={{ id: organization.id, type: 'organization' }}
          emptyListMessage={productsEmptyListMessage}
          products={organization.products}
        />
      );
    },
    queryString: {
      pro: {
        filterKey: 'products_id_in',
        set: (value) => (isEmpty(value) ? [] : [Number(value)]),
        columnName: 'products'
      }
    },
    filter: {
      name: 'products_id_in',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={ProductSelect}
          multiple
          allowNull
        />
      )
    }
  },
  {
    name: 'people',
    humanizedName: 'Pessoas',
    default: false,
    getValue: (organization) => (
      <PeoplePopover organizationId={organization.id} />
    )
  },
  {
    name: 'next_task',
    humanizedName: 'Próxima tarefa',
    default: false,
    getValue: (organization) => (
      <TaskTag variant='nextTask'
        task={organization?.nextTask}
        entity={{ type: 'organization', id: organization.id }}
      />
    ),
    filter: {
      name: 'next_task',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={NextTaskSelect}
        />
      )
    }
  },
  {
    name: 'latest_task',
    humanizedName: 'Última atividade',
    default: false,
    getValue: (organization) => (
      <TaskTag
        variant='latestTask'
        task={organization?.latestTask}
        entity={{ type: 'organization', id: organization.id }}
      />
    ),
    filter: {
      name: 'latest_activity',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={LatestActivitySelect}
        />
      )
    },
    queryString: {
      forgotten: {
        filterKey: 'latest_activity',
        set: (value = '') => {
          const variants = {
            noActivity: 'all_time',
            noRecentActivity: 'over_thirthy'
          };

          return variants[value];
        },
        columnName: 'latest_activity'
      }
    }
  },
  {
    name: 'import',
    humanizedName: 'Importações',
    default: false,
    getValue: (organization) => organization?.importedAt,
    className: 'text-nowrap',
    queryString: {
      import: {
        filterKey: 'import_id_in',
        set: (value) => (isEmpty(value) ? [] : [Number(value)]),
        columnName: 'import'
      }
    },
    filter: {
      name: 'import_id_in',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={ImportSelect}
          source='import_id_in'
          entity='organizations'
          allowNull
          multiple
          isSearchable
        />
      )
    }
  },
  {
    name: 'ranking',
    humanizedName: 'Ranking',
    default: true,
    getValue: (organization, onUpdate) => (
      <Rating
        value={organization.ranking ?? 0}
        onChange={(ranking, done) => onUpdate(organization.id, { ranking }, done)}
      />
    ),
    filter: {
      name: 'ranking_in',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={RankingSelect}
        />
      )
    }
  },
  {
    name: 'updated_at',
    humanizedName: 'Última atualização',
    default: false,
    className: 'text-nowrap',
    getValue: (organization) => organization.updatedAt
  },
  {
    name: 'created_at',
    humanizedName: 'Data de cadastro',
    default: false,
    className: 'text-nowrap',
    getValue: (organization) => organization.createdAt,
    queryString: {
      createdAtStart: {
        filterKey: 'created_at_utc_date_within',
        set: (value = '') => `${toISOString(value) || ''}/`,
        concat: concatDateForFilter,
        columnName: 'created_at'
      },
      createdAtEnd: {
        filterKey: 'created_at_utc_date_within',
        set: (value = '') => `/${toISOString(value) || ''}`,
        concat: concatDateForFilter,
        columnName: 'created_at'
      }
    },
    filter: {
      name: 'created_at_utc_date_within',
      defaultValue: null,
      isPopover: true,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={DateRangeInput}
          label='Data de cadastro'
          presets={DATE_RANGE_DEFAULT_PRESETS}
        />
      )
    }
  }
];

const columnsFilters = renderColumnsFiltersFrom(organizationColumns);

const columnsDefaultValues = buildColumnsDefaultValuesFrom(organizationColumns);

const tableDefaultValues = buildTableDefaultValuesFrom(organizationColumns);

const queryStringKeys = buildQueryStringKeysFrom(organizationColumns);

export default organizationColumns;
export { columnsDefaultValues, columnsFilters, tableDefaultValues, queryStringKeys };
