import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SwitchTransition, Transition } from 'react-transition-group';
import Stack from 'react-bootstrap/Stack';

import illustration from 'images/empty-panel.png';
import { useCreateSetup } from '@/api';
import { funnelsPath } from '@/routes';

const messages = [
  'Construindo funis...',
  'Criando categorias...',
  'Definindo motivos de perda...',
  'Configurando automações...'
];

function Setup() {
  const [index, setIndex] = useState(0);

  const { mutate, isLoading } = useCreateSetup();
  useEffect(() => {
    mutate();
  }, []);

  const navigate = useNavigate();
  const onEntered = () => {
    if (index < messages.length - 1) {
      setIndex(index + 1);
    } else if (isLoading) {
      setIndex(0);
    } else {
      navigate(funnelsPath());
    }
  };


  return (
    <SwitchTransition>
      <Transition
        key={index}
        timeout={500}
        onEntered={onEntered}
        appear
      >
        <Stack gap={4} className='align-items-center text-center'>
          <img src={illustration} />

          <h5 className='text-dark-gray'>
            Estamos deixando o Agendor com a sua cara!
          </h5>

          <h2>{messages[index]}</h2>
        </Stack>
      </Transition>
    </SwitchTransition>
  );
}

export default Setup;
