import React from 'react';
import { useActivityCard } from '@/components/EntityModal/Activities/ActivityCard/context';
import Header from '@/components/EntityModal/Activities/ActivityCard/Header';
import SubHeader from '@/components/EntityModal/Activities/ActivityCard/SubHeader';
import Footer from '@/components/EntityModal/Activities/ActivityCard/Footer';
import Body from '@/components/EntityModal/Activities/ActivityCard/Body';
import FileList from '@/components/FileList';
import Task from '@/task';

function Content() {
  const { activity, setShowFooterButtons } = useActivityCard();
  const { relatedEntity, relatedEntityType } = Task.build(activity);

  return (
    <div
      data-activity-id={activity.id}
      className='rounded border border-light-gray bg-light'
      onMouseEnter={() => setShowFooterButtons(true)}
      onMouseLeave={() => setShowFooterButtons(false)}
    >
      <Header />
      <SubHeader/>

      <hr className='m-0' />

      <div className='rounded-bottom bg-white'>
        <div className='pt-2 pb-2 px-4'>
          <Body />

          <FileList
            documents={activity.documents}
            images={activity.images}
            isFromProposal={activity.isFromProposal}
            entityType={relatedEntityType}
            entityId={relatedEntity.id}
            relatedEntity={relatedEntity}
          />
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default Content;
