import React from 'react';
import { useAlert } from 'react-alert';
import ActivityForm from '@/components/EntityModal/Activities/Form';
import { useCreateActivity } from '@/api';
import APIErrorMessage from '@/components/APIErrorMessage';
import { useOrganization } from '@/contexts/organizationModal';
import Skeleton from '@/components/Skeleton';

function Form() {
  const alert = useAlert();
  const organization = useOrganization();

  const createActivityMutation = useCreateActivity();

  const onCreateActivity = (params, done) => {
    createActivityMutation.mutate(params, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='activities' action='create' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.(err);
      },
      onSuccess: () => {
        alert.show(
          'Atividade cadastrada com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        done?.();
      }
    });
  };

  if (!organization) {
    return <Skeleton width='100%' height={160} className='mb-3' />;
  }

  return (
    <ActivityForm
      entityId={organization.id}
      entityName='organization'
      onCreate={onCreateActivity}
    />
  );
}

export default Form;
