import React from 'react';
import { UserSelect } from '@/components/Inputs';
import { useActivitiesCount, useFilters, useTaskListContext, useUpdateFilters } from '@/contexts';
import Tooltip from '@/components/Tooltip';
import { pluralize } from '@/utils';
import Skeleton from '@/components/Skeleton';

const OwnerUserFilter = () => {
  const filters = useFilters();
  const updateFilters = useUpdateFilters();
  const totalActivities = useActivitiesCount();
  const isLoading = useTaskListContext().isLoading;
  const totalLabel = totalActivities === 0 ? 'Sem' : totalActivities;
  const taskLabel = pluralize('tarefa', totalActivities);
  const selectedUserId = filters.assigned_user_id || filters.finished_by_user_id;
  const isFilterByFinished = filters.status === 'finished';

  const handleChange = (value) => {
    if (isFilterByFinished) {
      updateFilters({ finished_by_user_id: value, assigned_user_id: null });
    } else {
      updateFilters({ finished_by_user_id: null, assigned_user_id: value });
    }
  };

  const filterLabel = () => {
    if (isLoading) {
      return <Skeleton width={200} />;
    }
    const isFilteredByUser = Boolean(selectedUserId);

    const fillLabelText = () => {
      if (!isFilteredByUser && !isFilterByFinished) {
        return 'para';
      }

      if (isFilteredByUser && !isFilterByFinished) {
        return 'do(a) responsável';
      }

      return 'finalizadas por';
    };

    const labelText = fillLabelText();

    if (totalActivities > 100) {
      return (
        <span>
          <Tooltip content={totalActivities}>
            <span className='fw-bold'>100+</span>
          </Tooltip>{' '}
          tarefas {labelText}
        </span>
      );
    }

    return (
      <span>
        <span className='fw-bold'>{totalLabel}</span> {taskLabel} {labelText}
      </span>
    );
  };

  return (
    <div className='d-flex align-items-center'>
      <div className='text-nowrap text-base fw-bold text-dark-gray'>
        {filterLabel()}
      </div>
      <UserSelect
        name='assigned_user_id'
        isSearchable
        label=''
        allowAll
        nullLabel='Todos'
        isClearable={false}
        value={selectedUserId}
        onChange={handleChange}
        className='ms-2 width-5'
      />
    </div>
  );
};

export default OwnerUserFilter;
