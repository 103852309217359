import React from 'react';
import { useAlert } from 'react-alert';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { getValidationErrors } from '@/utils';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import { useCreateOrganization } from '@/api';
import Button from '@/components/Button';
import Popover from '@/components/Popover';
import LoadingButton from '@/components/LoadingButton';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import {
  TextInput,
  MaskedInput
} from '@/components/Inputs';
import APIErrorMessage from '@/components/APIErrorMessage';
import Alert from '@/components/QuickForms/Alert';

/* eslint-disable no-magic-numbers */
const schema = Yup.object().shape({
  name: Yup.string()
    .max(150, 'O nome do negócio deve ter no máximo 150 caracteres.')
    .required('Por favor, informe o nome da empresa.'),
  contact: Yup.object().shape({
    website: Yup.string()
      .max(150, 'O website da empresa deve ter no máximo 150 caracteres.'),
    whatsapp: Yup.string()
      .min(12, 'Número inválido.')
      .max(16, 'Número inválido.'),
    work_phone: Yup.string()
      .min(10, 'Número inválido.')
      .max(11, 'Número inválido.'),
    email: Yup.string()
      .email('E-mail inválido.')
  })
});
/* eslint-enable no-magic-numbers */

const propTypes = {
  children: PropTypes.node.isRequired
};

const defaultProps = {
  handleHide: () => {}
};

const defaultValues = {
  privacy: {
    type: 'default'
  }
};

function CreateOrganizationForm({ onHide, createOrganizationMutation }) {
  const alert = useAlert();
  const { user } = useAuth();
  const tracker = useTracking();

  const onSubmit = (formData, { setFieldError, setSubmitting }) => {
    createOrganizationMutation.mutate(formData, {
      onSuccess: ({ data }) => {
        tracker.trackOrganizationCreated({
          user,
          quickAdded: true,
          source: 'organization'
        });

        alert.show(
          <Alert
            entityType='organization'
            entityId={data.id}
            entityName={data.name}
          />,
          { variant: 'success', timeout: 15000 }
        );

        onHide();
      },
      onError: (error) => {
        const errors = getValidationErrors(error, 'organization');

        Object.entries(errors).forEach(([key, message]) => {
          setFieldError(key, message);
        });

        alert.show(
          <APIErrorMessage err={error} resource='organization' action='create' />,
          { variant: 'danger' }
        );

        setSubmitting(false);
      }
    });
  };

  return (
    <div>
      <h4 className='mb-4'>Adicionar empresa</h4>

      <Form
        onSubmit={onSubmit}
        validationSchema={schema}
        defaultValues={defaultValues}
      >
        {({ isSubmitting }) => (
          <>
            <Row className='gx-3'>
              <Col sm={12}>
                <FormField
                  as={TextInput}
                  name='name'
                  label='Nome'
                  placeholder='Dê um nome para a empresa'
                  maxLength={150}
                  data-autofocus='true'
                  autoFocus
                />
              </Col>
              <Col sm={6}>
                <FormField
                  as={TextInput}
                  name='website'
                  label='Website'
                  maxLength={150}
                  placeholder='https://www.empresa.com.br'
                />
              </Col>

              <Col sm={6}>
                <FormField
                  as={MaskedInput}
                  maskType='whatsapp'
                  name='contact.whatsapp'
                  label='WhatsApp'
                  placeholder='+55 00 00000-0000'
                />
              </Col>

              <Col sm={6}>
                <FormField
                  as={MaskedInput}
                  maskType='phone'
                  name='contact.work_phone'
                  label='Telefone'
                  placeholder='(00) 0000-0000'
                />
              </Col>

              <Col sm={6}>
                <FormField
                  as={MaskedInput}
                  name='contact.email'
                  maskType='email'
                  label='E-mail'
                  placeholder='exemplo@email.com'
                  maxLength={700}
                  variant='light'
                />
              </Col>
            </Row>

            <Row className='gx-3 mt-4'>
              <Col sm={6}>
                <Button
                  className='w-100'
                  onClick={onHide}
                  disabled={isSubmitting}
                  variant='outline-dark-gray'
                >
                  Cancelar
                </Button>
              </Col>
              <Col sm={6}>
                <LoadingButton
                  className='w-100'
                  loadingText='Salvando...'
                  type='submit'
                  isLoading={isSubmitting}
                >
                  Salvar
                </LoadingButton>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </div>
  );
}

function AddOrganizationPopover({ children, handleHide }) {
  const createOrganizationMutation = useCreateOrganization();

  return (
    <Popover
      placement='left-start'
      flip
      showArrow
      rootClose
      rootCloseEvent='mousedown'
      onExit={handleHide}
      content={
        ({ onHide }) => <CreateOrganizationForm
          onHide={() => {
            onHide();
            handleHide();
          }}
          createOrganizationMutation={createOrganizationMutation}
        />
      }
    >
      <div>
        {children}
      </div>
    </Popover>
  );
}

AddOrganizationPopover.propTypes = propTypes;
AddOrganizationPopover.defaultProps = defaultProps;

export default AddOrganizationPopover;
