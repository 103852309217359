import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { useAuth } from '@/lib/auth';
import { useCheckoutContext } from '@/contexts';
import { useTracking } from '@/lib/tracking';
import { settingsCurrentPlanPath } from '@/routes';

import BillingFooter from '@/components/Billing/BillingFooter';
import CardCheckoutDetails from '@/components/Billing/CardCheckoutDetails/CardCheckoutDetails';
import PixCompletion from '@/components/Billing/PixCompletion';
import BankSlipCompletion from '@/components/Billing/BankSlipCompletion';

const propTypes = {
  show: PropTypes.bool,
  radioOptions: PropTypes.array,
  isStripeComponent: PropTypes.bool,
  allowAll: PropTypes.bool
};

const defaultProps = {
  show: false,
  radioOptions: null,
  allowAll: false
};

function Payment({ show, radioOptions, isStripeComponent, allowAll }) {
  const {
    nextStep,
    paymentData,
    currentPlanData,
    setPaymentData,
    paymentCompletionData,
    accountData,
    setEncryptedData,
    onCreatePayment,
    isLoadingCreatePayment,
    transactionPollingEnabled,
    period: selectedPeriod
  } = useCheckoutContext();

  const { user } = useAuth();
  const tracker = useTracking();
  const alert = useAlert();
  const navigate = useNavigate();

  const [isLoadingCreateCard, setIsLoadingCreateCard] = useState(false);

  useEffect(() => {
    if (show && isStripeComponent) {
      tracker.trackPaymentStarted({ user });
    }
  }, [show, isStripeComponent]);

  useEffect(() => {
    if (paymentData?.paymentMethod) {
      onCreatePayment();

      setIsLoadingCreateCard(false);
    }
  }, [paymentData?.paymentMethod]);

  const footerButtonText = useMemo(() => {
    if (paymentData.type === 'bank_slip') {
      return { default: 'Gerar boleto', loading: 'Gerando...' };
    }

    return { default: 'Finalizar', loading: 'Finalizando...' };
  }, [paymentData.type]);

  const initialValues = useMemo(() => ({
    type: paymentData.type
  }), [paymentData.type]);

  const isRadioDisabled = useCallback((label) => {
    if (allowAll) {
      return false;
    }

    if (currentPlanData) {
      const { periodicity, paymentInfo, allowMonthlyBankSlipPix } = currentPlanData;
      const alreadyUseBankSlipPix =
        periodicity === 'monthly' && ['bank_slip', 'pix'].includes(paymentInfo?.method);

      if (alreadyUseBankSlipPix || allowMonthlyBankSlipPix) {
        return false;
      }
    }

    return selectedPeriod === 'monthly' && label !== 'credit_card';
  }, [selectedPeriod, currentPlanData]);

  const handleChange = (value) => {
    setPaymentData((prev) => ({ ...prev, type: value }));
  };

  const isPix = paymentData.type === 'pix';

  const isAdvanceStepDisabled = paymentData.type === 'credit_card' &&
    ((!paymentData?.event?.complete) && (
      !paymentData.credit_card?.is_valid ||
      !paymentData.credit_card?.brand));

  const handlePaymentError = () => {
    alert.show(
      <div>
        <h6>Infelizmente, não conseguimos validar o seu cartão</h6>
        <div>Por favor, tente novamente ou adicione um novo.</div>
      </div>,
      { variant: 'danger' }
    );
  };

  const isLoadingPayment = isLoadingCreateCard ||
    isLoadingCreatePayment ||
    transactionPollingEnabled;

  const onClickNext = async () => {
    if (!isStripeComponent) {
      nextStep();

      return;
    }

    if (['pix', 'bank_slip'].includes(paymentData.type)) {
      return onCreatePayment();
    }

    if (show && paymentData.stripe) {
      setIsLoadingCreateCard(true);
      const { error, paymentMethod } = await paymentData.stripe.createPaymentMethod({
        elements: paymentData.elements,
        params: {
          billing_details: {
            address: {
              country: 'BR'
            }
          }
        }
      });

      if (error) {
        console.error(error);

        setPaymentData((prev) => ({ ...prev, error }));

        setIsLoadingCreateCard(false);

        return handlePaymentError();
      }

      setPaymentData((prev) => ({ ...prev, paymentMethod }));
    }
  };

  const renderPaymentSection = () => (
    <CardCheckoutDetails
      className={classnames({ 'd-none': !show })}
      accountData={accountData}
      initialValues={initialValues}
      setPaymentData={setPaymentData}
      setEncryptedData={setEncryptedData}
      handleChange={handleChange}
      isRadioDisabled={isRadioDisabled}
      disabledToolTipContent='Boleto e Pix não estão disponíveis para pagamento mensal.'
      radioOptions={radioOptions}
      isStripeComponent={isStripeComponent}
    />
  );

  const renderCompletionSection = () => {
    const CompletionComponent = isPix ? PixCompletion : BankSlipCompletion;

    return (
      <CompletionComponent
        data={paymentCompletionData}
      />
    );
  };

  const onClose = () => {
    navigate(settingsCurrentPlanPath());
  };

  if (!show) {
    return null;
  }

  return (
    <>
      {paymentCompletionData ? renderCompletionSection() : renderPaymentSection()}


      {
        paymentCompletionData
          ? <BillingFooter.Close onClick={onClose} />
          : (
            <BillingFooter
              buttonNextText={footerButtonText.default}
              loadingTextButton={footerButtonText.loading}
              onClickNext={onClickNext}
              isLoading={isLoadingPayment}
              disabled={isAdvanceStepDisabled}
            />
          )
      }
    </>
  );
}

Payment.propTypes = propTypes;
Payment.defaultProps = defaultProps;

export default Payment;
