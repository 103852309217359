import { objectHasValue } from '@/utils';

const trackingEvents = {
  newFormViewed: 'Novo formulario visualizado',
  newListViewed: 'Nova listagem visualizada',
  objectAdded: 'Objeto adicionado',
  objectUpdated: 'Objeto alterado',
  productCreated: 'Adicionar produto (listagem)',
  productEdited: 'Editar produto (listagem)',
  productDeleted: 'Excluir produto (listagem)',
  funnelViewed: 'Novo funil visualizado',
  funnelCreated: 'Novo funil criado',
  funnelStageCreated: 'Nova etapa de funil criada',
  customFieldCreated: 'Campo personalizado adicionado',
  customFieldEdited: 'Campo personalizado alterado',
  customFieldDeleted: 'Campo personalizado excluido',
  backlogFormToggle: 'Toggle listagem lateral utilizado',
  cardDraggedFromBacklog: 'Card listagem lateral arrastado',
  backlogFilterUsed: 'Filtro listagem lateral utilizado',
  backlogSearchUsed: 'Busca listagem lateral utilizada',
  funnelFilterUsed: 'Filtro novo funil utilizado',
  newImportViewed: 'Pagina de Importacao Neo visualizada',
  newImportStarted: 'Importacao Neo iniciada',
  newImportMapping: 'Importacao Neo mapeamento concluido',
  newImportVisualization: 'Importacao Neo visualizacao concluida',
  commentAdded: 'Comentario adicionado',
  commentChanged: 'Comentario alterado',
  dealStageChanged: 'Negocio etapa alterada',
  trackDealStatusChanged: 'Negocio status alterado',
  taskFinished: 'Tarefa finalizada',
  modalViewed: 'Nova Ficha visualizada',
  dealFunnelChanged: 'Negocio funil alterado',
  dealEmailCopied: 'Email do negocio copiado',
  backToLegacy: 'Voltar para a versao antiga',
  requiredFieldCreated: 'Novo campo obrigatório criado',
  requiredFieldUpdated: 'Campo obrigatório editado',
  requiredFieldDeleted: 'Campo obrigatório excluído',
  requiredFieldFilled: 'Alerta campo obrigatório preenchido',
  activityTemplateButtonClicked: 'Botão de exibir modelos de anotação clicado',
  activityTemplateSelected: 'Modelo de anotação selecionado',
  activityTemplateCreated: 'Novo modelo de anotação criado',
  activityTemplateDeleted: 'Modelo de anotação excluído',
  voipActivated: 'Conta voip criada',
  voipCallStarted: 'Ligacao voip iniciada',
  voipGptSummaryViewed: 'Voipgpt resumo vizualizado',
  voipGptTranscriptionViewed: 'Voipgpt transcricao visualizada',
  automationCreated: 'Automação criada',
  automationCardSpoilerClicked: 'Card-spoiler automação clicado',
  automationCardClicked: 'Card automação clicado',
  leadsGenerationPresented: 'Geracao de leads apresentada',
  leadsGenerationStarted: 'Geracao de leads iniciada',
  sessionModalInterruption: 'Modal interrupcao visualizado',
  leadsGenerationSegmented: 'Geracao de leads segmentada',
  leadsGenerationDiscarded: 'Geracao de leads descartada',
  leadsGenerationListPresented: 'Geracao de leads lista apresentada',
  leadsGenerationChooseFunnel: 'Geracao de leads escolher funil',
  leadsGenerationPaymentStarted: 'Geracao de leads pagamento iniciado',
  emailSent: 'Email enviado',
  proposalCreated: 'Proposta gerada',
  proposalModelCreated: 'Modelo proposta criado',
  proposalModelDeleted: 'Modelo proposta excluido',
  proposalModelUpdated: 'Modelo proposta atualizado',
  leadsGenerationButtonClicked: 'Botão de Geração de Leads Clicado',
  profileUpdated: 'Perfil atualizado',
  profileStepViewed: 'Etapa form perfil visualizada',
  actionButtonClicked: 'Botao acao clicado',
  paymentStarted: 'Pagamento iniciado',
  funnelGiftClicked: 'Ícone presente funil clicado',
  funnelGiftButtonClicked: 'Popover presente botão clicado',
  importGuideSampleDownloadClicked: 'Importacao - botao baixar planilha clicado',
  importGuideHelpCenterClicked: 'Importacao - botao central ajuda clicado',
  importGuideVideoPlayed: 'Importacao - video tocado',
  whatsappModalViewed: 'Wpp modal visualizado',
  whatsappModalClicked: 'Wpp modal clicado',
  whatsappUsed: 'Whatsapp utilizado',
  smartSuggestionPresented: 'Sugestao inteligente apresentada',
  smartSuggestionAccepted: 'Sugestao inteligente aceita',
  smartSuggestionConfirmed: 'Sugestao inteligente confirmada',
  smartSuggestionClosed: 'Sugestao inteligente recusada',
  smartSuggestionCancelled: 'Sugestao inteligente cancelada',
  backToLegacyConfirmed: 'Voltar para a versao antiga confirmado',
  ongoingChecklistOpened: 'Checklist Ongoing aberto',
  voipInfoModalOpened: 'Voip saiba mais modal visualizado',
  voipInfoShowMoreClicked: 'Voip saiba mais modal clicado',
  voipInfoModalButtonClicked: 'Voip saiba mais botao clicado',
  integrationCardClicked: 'Integracao card clicado',
  integrationModalOpened: 'Integracao modal visualizado',
  integrationModalCTAClicked: 'Integracao cta modal clicado',
  integrationSearched: 'Busca integracao utilizada',
  integrationPartnerButtonClicked: 'Botao tela integracao clicado'
};

const abTestEvents = {
  'funnel_gift': {
    eventName: 'Teste A/B Ícone presente funil mostrado',
    variants: {
      A: 'Grupo A - teste',
      B: 'Grupo B - controle'
    }
  },
  'import_guide': {
    eventName: 'A/B Test Novo Guia de Importação',
    variants: {
      A: 'Grupo A - teste',
      B: 'Grupo B - controle'
    }
  },
  'news': {
    eventName: 'A/B Test Tela de novidades visualizada',
    variants: {
      A: 'Grupo A - teste',
      B: 'Grupo B - controle'
    }
  },
  'import_ignore': {
    eventName: 'Teste A/B Pré-selecionar opção Não Importar',
    propertyName: 'Teste AB Não importar',
    variants: {
      A: 'Grupo A - teste',
      B: 'Grupo B - controle'
    }
  },
  'Teste A/B Onboarding': {
    eventName: '$experiment_started',
    propertyName: 'Variant Name',
    description: 'Teste A/B Onboarding',
    variants: {}
  },
  'setup': {
    eventName: '$experiment_started',
    propertyName: 'Variant Name',
    description: 'Teste A/B Configuração automática',
    variants: {
      A: 'Grupo A - Controle',
      B: 'Grupo B - Teste'
    }
  }
};

const entityNames = {
  activity: 'Tarefa',
  organization: 'Empresa',
  person: 'Pessoa',
  deal: 'Negocio'
};

const customFieldTypeNames = {
  text: 'Texto',
  integer: 'Inteiro',
  decimal: 'Decimal'
};

const defaultMetadata = {
  Ambiente: 'Web'
};

const leadsGenerationStages = [
  '1. Publico e regiao',
  '2. Tamanho da empresa',
  '3. Decisores',
  '4. Encontrar similares',
  'Lista de leads',
  'Escolha de funil',
  'Pagamento',
  'Pagamento',
  'Pagamento'
];

const timeSegments = {
  hours: 'Horas',
  days: 'Dias',
  weeks: 'Semanas',
  months: 'Meses'
};

const activityTypes = {
  task: 'Tarefa',
  note: 'Nota',
  email: 'E-mail',
  call: 'Ligação',
  proposal: 'Proposta',
  meeting: 'Reunião',
  visit: 'Visita',
  whatsapp: 'WhatsApp'
};

const pages = {
  deal_modal: 'Ficha de negocio',
  organization_modal: 'Ficha de empresa',
  person_modal: 'Ficha de pessoa'
};

const components = {
  entity_modal_actions: 'Componente acoes',
  phone_actions: 'Widget atalho numero de telefone'
};

export class MixpanelTracker {
  #trackerFn;

  #profileFn;

  constructor({ trackerFn, profileFn }) {
    this.#trackerFn = trackerFn;
    this.#profileFn = profileFn;
  }

  trackDealStageChanged({ user, page }) {
    const eventMetadata = {
      'ID do cliente': user?.account?.id,
      'Local NEA': page
    };

    return (
      this.#trackerFn(
        trackingEvents.dealStageChanged,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackDealFunnelChanged({ user, page }) {
    const eventMetadata = {
      'ID do cliente': user?.account?.id,
      'Local NFA': page
    };

    return (
      this.#trackerFn(
        trackingEvents.dealFunnelChanged,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackTaskFinished({ user, screen }) {
    const eventMetadata = {
      'ID do cliente': user?.account?.id,
      'Local TF': screen
    };

    return (
      this.#trackerFn(
        trackingEvents.taskFinished,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackDealEmailCopied({ user }) {
    const eventMetadata = {
      'ID do cliente': user?.account?.id,
      'Local ENC': 'Nova ficha'
    };

    return (
      this.#trackerFn(
        trackingEvents.dealEmailCopied,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackFunnelViewed(data) {
    const { user, isBacklogOpen } = data;
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      'Lista lateral exibida': Boolean(isBacklogOpen)
    };

    return (
      this.#trackerFn(
        trackingEvents.funnelViewed,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackFunnelCreated(data) {
    const { user } = data;
    const eventMetadata = {
      'ID do usuario': user.id
    };

    return (
      this.#trackerFn(
        trackingEvents.funnelCreated,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackFunnelStageCreated(data) {
    const { user } = data;
    const eventMetadata = {
      'ID do usuario': user.id
    };

    return (
      this.#trackerFn(
        trackingEvents.funnelStageCreated,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackCustomFieldCreated({ user, entity, field }) {
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      'Objeto CPA': entityNames[entity],
      'Tipo CPA': customFieldTypeNames[field.type]
    };

    return (
      this.#trackerFn(
        trackingEvents.customFieldCreated,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackCustomFieldEdited({ user, entity, type }) {
    const metaData = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      'Objeto CPA': entityNames[entity],
      'Tipo de CPA': customFieldTypeNames[type]
    };

    return (
      this.#trackerFn(
        trackingEvents.customFieldEdited,
        this.#buildMetadata(metaData)
      )
    );
  }

  trackCustomFieldDeleted({ user, entity, type }) {
    const metaData = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      'Objeto CPE': entityNames[entity],
      'Tipo CPE': customFieldTypeNames[type]
    };

    return (
      this.#trackerFn(
        trackingEvents.customFieldDeleted,
        this.#buildMetadata(metaData)
      )
    );
  }

  trackOrganizationCreated(data) {
    const eventMeta = this.#buildObjectAddedMeta({
      ...data,
      entity: 'organization'
    });
    return this.#trackObjectCreated(eventMeta);
  }

  trackOrganizationUpdated(data) {
    const eventMeta = this.#buildObjectAddedMeta({
      ...data,
      entity: 'organization'
    });
    return this.#trackObjectUpdated(eventMeta);
  }

  trackPersonCreated(data) {
    const eventMeta = this.#buildObjectAddedMeta({
      ...data,
      entity: 'person'
    });

    return this.#trackObjectCreated(eventMeta);
  }

  trackDealCreated(data) {
    const eventMeta = this.#buildObjectAddedMeta({
      ...data,
      entity: 'deal'
    });

    return this.#trackObjectCreated(eventMeta);
  }

  trackProductCreated(data) {
    const { formData } = data;

    const hasFilledCategory = Boolean(formData?.product_category_id);
    const hasFilledPrice = Boolean(formData?.price);
    const hasFilledCode = Boolean(formData?.code);
    const status = Boolean(formData?.active);

    return (
      this.#trackerFn(
        trackingEvents.productCreated,
        this.#buildMetadata({
          'Categoria produto preenchida': hasFilledCategory,
          'Preço produto preenchido': hasFilledPrice,
          'Código produto preenchido': hasFilledCode,
          'Status do  produto': status
        })
      )
    );
  }

  trackProductEdited(data) {
    const { formData } = data;

    const hasFilledCategory = Boolean(formData?.product_category_id);
    const hasFilledPrice = Boolean(formData?.price);
    const hasFilledCode = Boolean(formData?.code);
    const status = Boolean(formData?.active);

    return (
      this.#trackerFn(
        trackingEvents.productEdited,
        this.#buildMetadata({
          'Categoria produto preenchida': hasFilledCategory,
          'Preço produto preenchido': hasFilledPrice,
          'Código produto preenchido': hasFilledCode,
          'Status do  produto': status
        })
      )
    );
  }

  trackProductDeleted() {
    return (
      this.#trackerFn(
        trackingEvents.productDeleted,
        this.#buildMetadata()
      )
    );
  }

  trackNewCreateOrganizationFormViewed(data) {
    const eventMeta = this.#buildNewFormViewedMeta({
      ...data,
      entity: 'organization',
      type: 'create'
    });

    return this.#trackNewFormViewed(eventMeta);
  }

  trackNewCreatePersonFormViewed(data) {
    const eventMeta = this.#buildNewFormViewedMeta({
      ...data,
      entity: 'person',
      type: 'create'
    });

    return this.#trackNewFormViewed(eventMeta);
  }

  trackNewCreateDealFormViewed(data) {
    const eventMeta = this.#buildNewFormViewedMeta({
      ...data,
      entity: 'deal',
      type: 'create'
    });

    return this.#trackNewFormViewed(eventMeta);
  }

  trackNewEditOrganizationFormViewed(data) {
    const eventMeta = this.#buildNewFormViewedMeta({
      ...data,
      entity: 'organization',
      type: 'edit'
    });

    return this.#trackNewFormViewed(eventMeta);
  }

  trackNewEditPersonFormViewed(data) {
    const eventMeta = this.#buildNewFormViewedMeta({
      ...data,
      entity: 'person',
      type: 'edit'
    });

    return this.#trackNewFormViewed(eventMeta);
  }

  trackNewEditDealFormViewed(data) {
    const eventMeta = this.#buildNewFormViewedMeta({
      ...data,
      entity: 'deal',
      type: 'edit'
    });

    return this.#trackNewFormViewed(eventMeta);
  }

  trackNewOrganizationListViewed(data) {
    const eventMeta = this.#buildNewListViewedMeta({
      ...data,
      entity: 'organization'
    });

    return this.#trackNewListViewed(eventMeta);
  }

  trackNewPersonListViewed(data) {
    const eventMeta = this.#buildNewListViewedMeta({
      ...data,
      entity: 'person'
    });

    return this.#trackNewListViewed(eventMeta);
  }

  trackNewDealListViewed(data) {
    const eventMeta = this.#buildNewListViewedMeta({
      ...data,
      entity: 'deal'
    });

    return this.#trackNewListViewed(eventMeta);
  }

  trackNewTaskListViewed(data) {
    const eventMeta = this.#buildNewListViewedMeta({
      ...data,
      entity: 'activity'
    });

    return this.#trackNewListViewed(eventMeta);
  }

  trackBacklogFormToggle({ user, values, type }) {
    const { without_deals: withoutDeals } = values;
    const entityType = type === 'deal' ? 'Negocios' : 'Contatos';

    const metaData = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      'Acao TU': !withoutDeals,
      'Entidade TU': entityType
    };

    return (
      this.#trackerFn(
        trackingEvents.backlogFormToggle,
        this.#buildMetadata(metaData)
      )
    );
  }

  trackBacklogSearchUsed({ user }) {
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id
    };

    return (
      this.#trackerFn(
        trackingEvents.backlogSearchUsed,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackCardDraggedFromBacklog({ user, entity }) {
    const metaData = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      'Objeto CA': entityNames[entity.type]
    };

    return (
      this.#trackerFn(
        trackingEvents.cardDraggedFromBacklog,
        this.#buildMetadata(metaData)
      )
    );
  }

  trackBacklogFilterUsed(data) {
    const eventMeta = this.#buildFilterUsedMeta(data);

    return (
      this.#trackerFn(
        trackingEvents.backlogFilterUsed,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackFunnelFilterUsed(data) {
    const eventMeta = this.#buildFilterUsedMeta(data);

    return (
      this.#trackerFn(
        trackingEvents.funnelFilterUsed,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackNewImportViewed(data) {
    const eventMeta = this.#buildNewImportMeta(data);

    return (
      this.#trackerFn(
        trackingEvents.newImportViewed,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackNewImportStarted(data) {
    const eventMeta = this.#buildNewImportMeta(data);

    return (
      this.#trackerFn(
        trackingEvents.newImportStarted,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackNewImportMapping(data) {
    const eventMeta = this.#buildNewImportMeta(data);

    return (
      this.#trackerFn(
        trackingEvents.newImportMapping,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackNewImportVisualization(data) {
    const eventMeta = this.#buildNewImportMeta(data);

    return (
      this.#trackerFn(
        trackingEvents.newImportVisualization,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackCommentAdded(data) {
    const eventMeta = this.#buildCommentAddedMeta(data);

    return (
      this.#trackerFn(
        trackingEvents.commentAdded,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackDealStatusChanged(data) {
    const eventMeta = this.#buildDealStatusChanged(data);

    return (
      this.#trackerFn(
        trackingEvents.trackDealStatusChanged,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackCommentChanged(data) {
    const eventMeta = this.#buildCommentChangedMeta(data);

    return (
      this.#trackerFn(
        trackingEvents.commentChanged,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackModalViewed({ entityType, user, variant }) {
    const eventMetadata = {
      'Objeto FV': entityNames[entityType],
      'ID do cliente': user?.account?.id,
      'Experimento widget acoes': variant
    };

    return (
      this.#trackerFn(
        trackingEvents.modalViewed,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackBackToLegacy({ user, page, entity, feedback }) {
    const eventMetadata = {
      'ID do cliente': user?.account?.id,
      'Tela': page,
      'Entidade': entityNames[entity] || entity,
      'Feedback solicitado': Boolean(feedback)
    };

    return (
      this.#trackerFn(
        trackingEvents.backToLegacy,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackBackToLegacyConfirmed({ user, screen, feedback, entity, answer }) {
    const eventMetadata = {
      'ID do cliente': user.account.id,
      'Email': user.email,
      'Tela': screen,
      'Feedback': feedback,
      'Entidade': entityNames[entity],
      'Motivo': answer
    };

    return (
      this.#trackerFn(
        trackingEvents.backToLegacyConfirmed,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackRequiredFieldCreated(data) {
    const { user, funnelId, target, field, formData } = data;
    const hasFilledQuestion = Boolean(formData?.question);
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      'ID do funil': funnelId,
      'Tipo COB': field.customField?.type,
      'Objeto COB': field.entity,
      'Pergunta': hasFilledQuestion,
      ...this.#buildRequiredFieldsTargetData(target)
    };

    return (
      this.#trackerFn(
        trackingEvents.requiredFieldCreated,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackRequiredFieldUpdated(data) {
    const { user, funnelId, target, field, formData } = data;
    const hasFilledQuestion = Boolean(formData?.question);
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      'ID do funil': funnelId,
      'Tipo COB': field.customField?.type,
      'Objeto COB': field.entity,
      'Pergunta': hasFilledQuestion,
      ...this.#buildRequiredFieldsTargetData(target)
    };

    return (
      this.#buildRequiredFieldsTargetData(target),
      this.#trackerFn(
        trackingEvents.requiredFieldUpdated,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackRequiredFieldDeleted(data) {
    const { user, funnelId, target, field } = data;
    const hasFilledQuestion = Boolean(field.question);
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      'ID do funil': funnelId,
      'Tipo COB': field.customField?.type,
      'Objeto COB': field.entity,
      'Pergunta': hasFilledQuestion,
      ...this.#buildRequiredFieldsTargetData(target)
    };

    return (
      this.#trackerFn(
        trackingEvents.requiredFieldDeleted,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackRequiredFieldFilled(data) {
    const { user, funnelId, stageId, dealStatus } = data;
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      'ID do funil': funnelId,
      'ID da etapa': stageId,
      'Status do negócio': dealStatus
    };

    return (
      this.#trackerFn(
        trackingEvents.requiredFieldFilled,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackVoipActivated(data) {
    const { user } = data;
    const eventMetadata = {
      'ID do cliente': user.account.id,
      'ID do usuario': user.id
    };

    return (
      this.#trackerFn(
        trackingEvents.voipActivated,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackVoipCallStarted(data) {
    const { user, page, component } = data;
    const eventMetadata = {
      'ID do cliente': user.account.id,
      'ID do usuario': user.id,
      'Tela': pages[page],
      'Local': components[component]
    };

    return (
      this.#trackerFn(
        trackingEvents.voipCallStarted,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackActivityTemplateButtonClicked(data) {
    const { user, entity, entityType } = data;
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      'Nome tipo CA': entity.activityType,
      'Tela': `Ficha de ${entityNames[entityType]}`
    };

    return (
      this.#trackerFn(
        trackingEvents.activityTemplateButtonClicked,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackActivityTemplateSelected(data) {
    const { user, entity, activityTemplateId, entityType } = data;
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      'ID do modelo': activityTemplateId,
      'Nome tipo CA': entity.activityType,
      'Tela': `Ficha de ${entityNames[entityType]}`
    };

    return (
      this.#trackerFn(
        trackingEvents.activityTemplateSelected,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackActivityTemplateCreated(data) {
    const { user, entity, activityTemplateId, entityType } = data;
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      'ID do modelo': activityTemplateId,
      'Nome tipo CA': entity.activityType,
      'Tela': `Ficha de ${entityNames[entityType]}`
    };

    return (
      this.#trackerFn(
        trackingEvents.activityTemplateCreated,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackActivityTemplateDeleted(data) {
    const { user, entity, activityTemplateId, entityType } = data;
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      'ID do modelo': activityTemplateId,
      'Nome tipo CA': entity.activityType,
      'Tela': `Ficha de ${entityNames[entityType]}`
    };

    return (
      this.#trackerFn(
        trackingEvents.activityTemplateDeleted,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackAutomationCreated(data) {
    const { user, automationName, actionParams } = data;
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      'Nome da automação': automationName,
      'Descrição preenchida': Boolean(actionParams?.text)
    };

    if (actionParams && 'type' in actionParams && 'due_in' in actionParams) {
      eventMetadata['Tipo de atividade'] = activityTypes[actionParams.type];
      eventMetadata['Quantidade de tempo'] = actionParams.due_in?.number ?? '0';
      eventMetadata['Segmento de tempo'] = timeSegments[actionParams.due_in?.unit] ?? 'Dias';
    }

    return (
      this.#trackerFn(
        trackingEvents.automationCreated,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackAutomationCardSpoilerClicked(data) {
    const { user, automationName } = data;
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      'Nome da automação': automationName
    };

    return (
      this.#trackerFn(
        trackingEvents.automationCardSpoilerClicked,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackAutomationCardClicked(data) {
    const { user, automationName } = data;
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      'Nome da automação': automationName
    };

    return (
      this.#trackerFn(
        trackingEvents.automationCardClicked,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackLeadsGenerationPresented(data) {
    const { user } = data;
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id
    };

    return (
      this.#trackerFn(
        trackingEvents.leadsGenerationPresented,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackLeadsGenerationStarted(data) {
    const { user } = data;
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id
    };

    return (
      this.#trackerFn(
        trackingEvents.leadsGenerationStarted,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackSessionModalInterruption({ user, buttonText }) {
    const eventMetadata = {
      'ID do usuario': user?.id,
      'ID do cliente': user?.account?.id,
      'Botao do MIV': buttonText
    };

    return (
      this.#trackerFn(
        trackingEvents.sessionModalInterruption,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackLeadsGenerationSegmented(data) {
    const includeAdditionalData = ['stage'];
    const eventMeta = this.#buildLeadsGenerationMeta(data, includeAdditionalData);

    return (
      this.#trackerFn(
        trackingEvents.leadsGenerationSegmented,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackLeadsGenerationDiscarded(data) {
    const includeAdditionalData = ['stage'];
    const eventMeta = this.#buildLeadsGenerationMeta(data, includeAdditionalData);

    return (
      this.#trackerFn(
        trackingEvents.leadsGenerationDiscarded,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackLeadsGenerationListPresented(data) {
    const eventMeta = this.#buildLeadsGenerationMeta(data);

    return (
      this.#trackerFn(
        trackingEvents.leadsGenerationListPresented,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackLeadsGenerationChooseFunnel(data) {
    const includeAdditionalData = ['selectedLeadsQuantitity', 'funnel'];
    const eventMeta = this.#buildLeadsGenerationMeta(data, includeAdditionalData);

    return (
      this.#trackerFn(
        trackingEvents.leadsGenerationChooseFunnel,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackLeadsGenerationPaymentStarted(data) {
    const includeAdditionalData = ['selectedLeadsQuantitity'];
    const eventMeta = this.#buildLeadsGenerationMeta(data, includeAdditionalData);

    return (
      this.#trackerFn(
        trackingEvents.leadsGenerationPaymentStarted,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackEmailSent(data) {
    const eventMeta = this.#buildEmailSentMeta(data);

    return (
      this.#trackerFn(
        trackingEvents.emailSent,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackProposalCreated(data) {
    const eventMeta = this.#buildProposalCreatedMeta(data);

    return (
      this.#trackerFn(
        trackingEvents.proposalCreated,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackProposalModelCreated(data) {
    const eventMeta = this.#buildProposalModelCreatedMeta(data);

    return (
      this.#trackerFn(
        trackingEvents.proposalModelCreated,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackProposalModelUpdated(data) {
    const eventMeta = this.#buildProposalModelUpdatedMeta(data);

    return (
      this.#trackerFn(
        trackingEvents.proposalModelUpdated,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackProposalModelDeleted(data) {
    const eventMeta = this.#buildProposalModelDeletedMeta(data);

    return (
      this.#trackerFn(
        trackingEvents.proposalModelDeleted,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackABTest(data) {
    const { experimentName } = data;
    const { eventName } = abTestEvents[experimentName] ?? {};

    if (!eventName) {
      return;
    }

    const eventMeta = this.#buildABTestMeta(data);

    return (
      this.#trackerFn(
        eventName,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackActionButtonClicked(data) {
    const eventMeta = this.#trackActionButtonClicked(data);

    return (
      this.#trackerFn(
        trackingEvents.actionButtonClicked,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackVoipGptSummaryViewed(data) {
    const { user } = data;
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id
    };

    return (
      this.#trackerFn(
        trackingEvents.voipGptSummaryViewed,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackVoipGptTranscriptionViewed(data) {
    const { user } = data;
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id
    };

    return (
      this.#trackerFn(
        trackingEvents.voipGptTranscriptionViewed,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackProfileUpdated(data) {
    const { user } = data;
    const {
      persona,
      need: { checks: needChecks },
      whatsapp,
      crmExperience,
      account: { segment, salespersonNumber, findUs }
    } = user;

    const peopleData = {
      'Perfil atualizado': true,
      'Persona': persona,
      'Ramo de atividade': segment,
      'Necessidade': needChecks,
      'Tamanho empresa': salespersonNumber,
      'Telefone': whatsapp,
      'Experiência com CRM': crmExperience
    };
    this.#profileFn(peopleData);

    const eventMeta = {
      'Persona PA': persona,
      'Ramo de atividade PA': segment,
      'Necessidade PA': needChecks,
      'Tamanho empresa PA': salespersonNumber,
      'Telefone PA': whatsapp,
      'Experiência CRM PA': crmExperience,
      'Como conheceu PA': findUs
    };

    return (
      this.#trackerFn(
        trackingEvents.profileUpdated,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackProfileStepViewed(data) {
    const { user, step } = data;
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      'Etapa FP': step
    };

    return (
      this.#trackerFn(
        trackingEvents.profileStepViewed,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackProfileStepCompleted(data) {
    const { user } = data;
    const {
      persona,
      need,
      whatsapp,
      crmExperience,
      account: { segment, salespersonNumber }
    } = user;

    const peopleData = {
      'Persona': persona,
      'Ramo de atividade': segment,
      'Necessidade': need?.checks,
      'Tamanho empresa': salespersonNumber,
      'Telefone': whatsapp,
      'Experiência com CRM': crmExperience
    };

    return this.#profileFn(peopleData);
  }

  trackPaymentStarted({ user }) {
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id
    };

    return (
      this.#trackerFn(
        trackingEvents.paymentStarted,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackFunnelGiftClicked({ user }) {
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id
    };

    return (
      this.#trackerFn(
        trackingEvents.funnelGiftClicked,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackFunnelGiftPlaylistClicked({ user }) {
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      'Botao clicado': 'Ver playlist'
    };

    return (
      this.#trackerFn(
        trackingEvents.funnelGiftButtonClicked,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackFunnelGiftSettingsClicked({ user }) {
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      'Botao clicado': 'Configurar funis'
    };

    return (
      this.#trackerFn(
        trackingEvents.funnelGiftButtonClicked,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackImportGuideSampleDownloadClicked({ user }) {
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id
    };

    return (
      this.#trackerFn(
        trackingEvents.importGuideSampleDownloadClicked,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackImportGuideHelpCenterClicked({ user }) {
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id
    };

    return (
      this.#trackerFn(
        trackingEvents.importGuideHelpCenterClicked,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackImportGuideVideoPlayed({ user }) {
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id
    };

    return (
      this.#trackerFn(
        trackingEvents.importGuideVideoPlayed,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackWhatsappModalViewed({ user }) {
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id
    };

    return (
      this.#trackerFn(
        trackingEvents.whatsappModalViewed,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackWhatsappModalClicked(data) {
    const eventMeta = this.#trackWhatsAppModalClicked(data);

    return (
      this.#trackerFn(
        trackingEvents.whatsappModalClicked,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackWhatsappUsed(data) {
    const eventMeta = this.#trackWhatsappUsed(data);

    return (
      this.#trackerFn(
        trackingEvents.whatsappUsed,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackSmartSuggestionPresented(data) {
    const eventMeta = this.#trackSmartSuggestionPresented(data);

    return (
      this.#trackerFn(
        trackingEvents.smartSuggestionPresented,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackSmartSuggestionAccepted(data) {
    const eventMeta = this.#trackSmartSuggestionAccepted(data);

    return (
      this.#trackerFn(
        trackingEvents.smartSuggestionAccepted,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackSmartSuggestionConfirmed(data) {
    const eventMeta = this.#trackSmartSuggestionConfirmed(data);

    return (
      this.#trackerFn(
        trackingEvents.smartSuggestionConfirmed,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackSmartSuggestionClosed(data) {
    const eventMeta = this.#trackSmartSuggestionClosed(data);

    return (
      this.#trackerFn(
        trackingEvents.smartSuggestionClosed,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackSmartSuggestionCancelled(data) {
    const eventMeta = this.#trackSmartSuggestionCancelled(data);

    return (
      this.#trackerFn(
        trackingEvents.smartSuggestionCancelled,
        this.#buildMetadata(eventMeta)
      )
    );
  }

  trackOngoingChecklistOpened({ user }) {
    const eventMetadata = {
      'ID do cliente': user.account.id,
      'ID do usuario': user.id
    };

    return (
      this.#trackerFn(
        trackingEvents.ongoingChecklistOpened,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackVoipInfoModalOpened({ user }) {
    const eventMetadata = {
      'ID do cliente': user.account.id,
      'ID do usuario': user.id
    };

    return (
      this.#trackerFn(
        trackingEvents.voipInfoModalOpened,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackVoipInfoShowMoreClicked({ user, action }) {
    const eventMetadata = {
      'ID do cliente': user.account.id,
      'ID do usuario': user.id,
      'Acao': action
    };

    return (
      this.#trackerFn(
        trackingEvents.voipInfoShowMoreClicked,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackVoipInfoModalButtonClicked({ user }) {
    const eventMetadata = {
      'ID do cliente': user.account.id,
      'ID do usuario': user.id
    };

    return (
      this.#trackerFn(
        trackingEvents.voipInfoModalButtonClicked,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackIntegrationCardClicked({ user, integration }) {
    const eventMetadata = {
      'ID do cliente': user.account.id,
      'ID do usuario': user.id,
      'Integracao': integration
    };

    return (
      this.#trackerFn(
        trackingEvents.integrationCardClicked,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackIntegrationModalOpened({ user, integration }) {
    const eventMetadata = {
      'ID do cliente': user.account.id,
      'ID do usuario': user.id,
      'Integracao': integration
    };

    return (
      this.#trackerFn(
        trackingEvents.integrationModalOpened,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackIntegrationModalCTAClicked({ user, integration }) {
    const eventMetadata = {
      'ID do cliente': user.account.id,
      'ID do usuario': user.id,
      'Integracao': integration
    };

    return (
      this.#trackerFn(
        trackingEvents.integrationModalCTAClicked,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackIntegrationSearched({ user, searchTerm, searchResult }) {
    const eventMetadata = {
      'ID do cliente': user.account.id,
      'ID do usuario': user.id,
      'Palavra-chave': searchTerm,
      'Resultado': searchResult
    };

    return (
      this.#trackerFn(
        trackingEvents.integrationSearched,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  trackIntegrationPartnerButtonClicked({ user, partner, area }) {
    const eventMetadata = {
      'ID do cliente': user.account.id,
      'ID do usuario': user.id,
      'Botao': partner,
      'Tela': area
    };

    return (
      this.#trackerFn(
        trackingEvents.integrationPartnerButtonClicked,
        this.#buildMetadata(eventMetadata)
      )
    );
  }

  #cleanupObject(object) {
    return Object
      .entries(object)
      .reduce((acc, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      }, {});
  }

  #buildNewFormViewedMeta({ metadata, entity, type, user }) {
    const formType = type === 'create' ? 'Inserir' : 'Editar';

    return {
      ...metadata,
      'Tela NFV': entityNames[entity],
      'Acao NFV': formType,
      'ID do usuario': user.id,
      'ID do cliente': user.account.id
    };
  }

  #buildFilterUsedMeta(data) {
    const { user, params } = data;
    const {
      q,
      backlogId,
      sorts,
      without_deals: withoutDeals,
      exclude_duplicated: excludeDuplicated,
      custom_fields: customFields,
      ...rest
    } = params;
    const defaultFieldsKeys = Object.keys(this.#cleanupObject(rest));
    const hasCustomFields = objectHasValue(customFields);

    return {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      'Tipo FU': [
        defaultFieldsKeys.length > 0 ? 'Default' : undefined,
        hasCustomFields ? 'Customizado' : undefined
      ].filter(Boolean),
      'Nome de FU': defaultFieldsKeys
    };
  }

  #buildNewListViewedMeta({ metadata, entity, user }) {
    return {
      ...metadata,
      'Tela NLV': entityNames[entity],
      'ID do usuario': user.id,
      'ID do cliente': user.account.id
    };
  }

  #buildNewImportMeta(data) {
    const { user, entity } = data;
    const entityName = {
      organizations: 'Empresa',
      people: 'Pessoa',
      products: 'Produto',
      deals: 'Negocio',
      activities: 'Comentario'
    };

    return {
      'ID do cliente': user.account.id,
      'ID do usuario': user.id,
      'Tela IN': entityName[entity]
    };
  }


  #buildEmailSentMeta(data) {
    const { user, entity, hasTemplate, entityId, hasCc, hasBcc } = data;
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      'Entidade relacionada ao e-mail': entityNames[entity],
      'ID da entidade relacionada': entityId,
      'CC usada': hasCc,
      'BCC usada': hasBcc,
      'Modelo aplicado': hasTemplate,
      'Tela EE': pages[`${entity}_modal`]
    };

    return eventMetadata;
  }

  #buildProposalCreatedMeta(data) {
    const { user, hasProducts, hasText } = data;
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      'Area produtos preenchida GP': Boolean(hasProducts),
      'Area textos preenchida GP': Boolean(hasText)
    };

    return eventMetadata;
  }

  #buildProposalModelCreatedMeta(data) {
    const { user, hasLogo, hasBanner, countSectionsText, countSectionsProducts } = data;
    const eventMetadata = {
      'ID do cliente': user.id,
      'Logomarca inserida MP': Boolean(hasLogo),
      'Background inserido MP': Boolean(hasBanner),
      'Secao tipo texto MP': countSectionsText,
      'Secao tipo produto MP': countSectionsProducts
    };

    return eventMetadata;
  }

  #buildProposalModelUpdatedMeta(data) {
    const { user, hasLogo, hasBanner, countSectionsText, countSectionsProducts } = data;
    const eventMetadata = {
      'ID do cliente': user.id,
      'Logomarca inserida MP': Boolean(hasLogo),
      'Background inserido MP': Boolean(hasBanner),
      'Secao tipo texto MP': countSectionsText,
      'Secao tipo produto MP': countSectionsProducts
    };

    return eventMetadata;
  }

  #buildProposalModelDeletedMeta(data) {
    const { user } = data;
    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user?.account?.id
    };

    return eventMetadata;
  }

  #buildABTestMeta(data) {
    const { user, experimentName, variantName } = data;

    const experiment = abTestEvents[experimentName];
    const propertyName = experiment.propertyName ?? 'Teste AB';
    const variantDescription = experiment?.variants[variantName] ?? variantName;
    const experimentDescription = experiment.description ?? experimentName;

    const eventMetadata = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      [propertyName]: variantDescription,
      // https://docs.mixpanel.com/docs/reports/apps/experiments#add-experiments-to-an-implementation
      'Experiment Name': experimentDescription
    };

    return eventMetadata;
  }

  #trackActionButtonClicked(data) {
    const { user, action, entityType } = data;
    const eventMetadata = {
      'ID do usuario': user?.id,
      'ID do cliente': user?.account?.id,
      'Tipo acao': action,
      'Tela BAC': `Nova Ficha ${entityNames[entityType]}`
    };

    return eventMetadata;
  }

  #trackWhatsAppModalClicked(data) {
    const { user, action } = data;
    const eventMetadata = {
      'ID do usuario': user?.id,
      'ID do cliente': user?.account?.id,
      'Tipo acao': action
    };

    return eventMetadata;
  }

  #trackWhatsappUsed(data) {
    const { user, action, location, screen } = data;
    const eventMetadata = {
      'ID do usuario': user?.id,
      'ID do cliente': user?.account?.id,
      'Local': location,
      'Acao': action,
      'Tela': screen
    };

    return eventMetadata;
  }

  #trackSmartSuggestionPresented(data) {
    const { user, activity, screen, intents } = data;
    const eventMetadata = {
      'ID do usuario': user?.id,
      'ID do cliente': user?.account?.id,
      'Id do comentario': activity?.id,
      'Tela': screen,
      'Sugestoes': intents
    };
    return eventMetadata;
  }

  #trackSmartSuggestionAccepted(data) {
    const { user, activity, screen, intents, currentIntent } = data;
    const eventMetadata = {
      'ID do usuario': user?.id,
      'ID do cliente': user?.account?.id,
      'Id do comentario': activity?.id,
      'Tela': screen,
      'Sugestoes': intents,
      'Sugestao selecionada': currentIntent
    };
    return eventMetadata;
  }

  #trackSmartSuggestionConfirmed(data) {
    const { user, activity, screen, intents, currentIntent } = data;
    const eventMetadata = {
      'ID do usuario': user?.id,
      'ID do cliente': user?.account?.id,
      'Id do comentario': activity?.id,
      'Tela': screen,
      'Sugestoes': intents,
      'Sugestao selecionada': currentIntent
    };
    return eventMetadata;
  }

  #trackSmartSuggestionClosed(data) {
    const { user, activity, screen, intents, currentIntent } = data;
    const eventMetadata = {
      'ID do usuario': user?.id,
      'ID do cliente': user?.account?.id,
      'Id do comentario': activity?.id,
      'Tela': screen,
      'Sugestoes': intents,
      'Sugestao selecionada': currentIntent
    };
    return eventMetadata;
  }

  #trackSmartSuggestionCancelled(data) {
    const { user, activity, screen, intents, currentIntent } = data;
    const eventMetadata = {
      'ID do usuario': user?.id,
      'ID do cliente': user?.account?.id,
      'Id do comentario': activity?.id,
      'Tela': screen,
      'Sugestoes': intents,
      'Sugestao selecionada': currentIntent
    };
    return eventMetadata;
  }

  #buildCommentAddedMeta(data) {
    const { user, entity } = data;

    const localCA = !entity.type
      ? 'Nova listagem tarefas'
      : `Nova Ficha ${entityNames[entity.type]}`;

    const meta = {
      'Tipo CA': !entity.isNote ? 'Tarefa' : 'Normal',
      'Nome tipo CA': entity.activityType === 'Ligação' ? 'Ligacao' : entity.activityType,
      'Local CA': localCA,
      'ID do cliente': user.account.id,
      'ID do usuario': user.id
    };

    if (!entity.isNote) {
      meta['Horário preenchido CA'] = entity.allDay;
      meta['Status da tarefa CA'] = entity.isFinished ? 'Finalizado' : 'Pendente';
    }
    return meta;
  }

  #buildCommentChangedMeta(data) {
    const { user, entity } = data;

    const localCA = !entity.type
      ? 'Nova listagem tarefas'
      : `Nova Ficha ${entityNames[entity.type]}`;

    const meta = {
      'Local CA': localCA,
      'Tipo CA': !entity.isNote ? 'Tarefa' : 'Normal',
      'Nome tipo CA': entity.activityType === 'Ligação' ? 'Ligacao' : entity.activityType,
      'ID do cliente': user.account.id,
      'ID do usuario': user.id
    };

    if (!entity.isNote) {
      meta['Horário preenchido CA'] = entity.allDay;
      meta['Status da tarefa CA'] = entity.isFinished ? 'Finalizado' : 'Pendente';
    }
    return meta;
  }

  #buildDealStatusChanged(data) {
    const { user, status, page } = data;
    const meta = {
      'ID do cliente': user.account.id,
      'Local NSA': page,
      'Status NSA': status
    };

    return meta;
  }

  #buildObjectAddedMeta({
    metadata, source, quickAdded, entity,
    user, entityCustomFields, formData
  }) {
    const screens = {
      newForm: 'Formulario',
      person: 'Atalho ficha pessoa',
      organization: 'Atalho ficha empresa'
    };
    const sourceText = screens[source] || 'Novo funil';

    const hasCustomFields = entityCustomFields?.length > 0;
    const hasFilledCustomFields = Object.values(formData?.custom_fields || {});
    const hasFilledCNPJ = Boolean(formData?.cnpj);

    const productsHasSome = (key) => (
      formData?.entity_products?.some((product) => Number(product[key]) > 0)
    );

    const hasQuantity = productsHasSome('quantity');
    const hasUnitValue = productsHasSome('unit_value');
    const hasDiscount = productsHasSome('discount');

    const meta = {
      ...metadata,
      'ID do usuario': user?.id,
      'ID do cliente': user?.account.id,
      'Tela OA': sourceText,
      'Objeto OA': entityNames[entity],
      'Atalho OA': Boolean(quickAdded),
      'Possui campo personalizado': hasCustomFields,
      'Campo personalizado preenchido': hasFilledCustomFields
    };

    if (entity === 'organization') {
      meta['Campo CNPJ preenchido'] = hasFilledCNPJ;
    }

    if (entity === 'deal') {
      meta['Quantidade produto preenchida'] = hasQuantity;
      meta['Preço produto preenchido'] = hasUnitValue;
      meta['Desconto produto preenchido'] = hasDiscount;
    }

    return meta;
  }

  #buildRequiredFieldsTargetData(target) {
    if (target.type === 'stage') {
      return { 'ID da etapa': target.id };
    } else {
      return { 'Status do negocio': target.name };
    }
  }

  #trackNewFormViewed(metadata = {}) {
    return (
      this.#trackerFn(
        trackingEvents.newFormViewed,
        this.#buildMetadata(metadata)
      )
    );
  }

  #trackNewListViewed(metadata = {}) {
    return (
      this.#trackerFn(
        trackingEvents.newListViewed,
        this.#buildMetadata(metadata)
      )
    );
  }

  #trackObjectCreated(metadata = {}) {
    return (
      this.#trackerFn(
        trackingEvents.objectAdded,
        this.#buildMetadata(metadata)
      )
    );
  }

  #trackObjectUpdated(metadata = {}) {
    return (
      this.#trackerFn(
        trackingEvents.objectUpdated,
        this.#buildMetadata(metadata)
      )
    );
  }

  #buildMetadata(metadata) {
    return { ...defaultMetadata, ...metadata };
  }

  #buildLeadsGenerationMeta(metadata = {}, includeAdditionalData = []) {
    const {
      user,
      business_model: businessModel,
      search_by: searchBy,
      addresses,
      leadSizeData,
      leadSizePreference,
      decisionMakersDepartmentsData,
      decisionMakersLevelsData,
      similarCnpjsData,
      leadsCount,
      searchStep,
      selectedLeadsCount,
      funnel_selection: funnelOption,
      funnel_name: funnelName
    } = metadata;

    const includeSelectedLeadsQuantity = includeAdditionalData.includes('selectedLeadsQuantitity');
    const includeStage = includeAdditionalData.includes('stage');
    const includeFunnel = includeAdditionalData.includes('funnel');
    const regionData = searchBy === 'city' ? addresses.cities : addresses.states;
    const funnel = funnelOption !== 'funnel_id' ? funnelOption : funnelName;

    const newMaxValue = () => {
      const MAX_VALUE = 1000;

      return leadSizeData.maxValue === MAX_VALUE
        ? `+${MAX_VALUE}`
        : leadSizeData.maxValue;
    };

    const meta = {
      'ID do usuario': user.id,
      'ID do cliente': user.account.id,
      'GL Tipo de lead': businessModel,
      'GL Regioes': regionData || 'Todo o Brasil',
      'GL Tamanho empresa': leadSizePreference === 'with_preference'
        ? `${leadSizeData.minValue} a ${newMaxValue()} funcionários`
        : 'Não tenho preferência',
      'GL Decisores': decisionMakersDepartmentsData,
      'GL Nivel decisores': decisionMakersLevelsData,
      'GL CNPJs': similarCnpjsData,
      'GL Resultados potencial': leadsCount || 0
    };

    if (includeSelectedLeadsQuantity) {
      meta['GL Qtd leads selecionados'] = selectedLeadsCount;
    }

    if (includeFunnel) {
      meta['GL Funil'] = funnel;
    }

    if (includeStage) {
      meta['GL Etapa'] = leadsGenerationStages[searchStep];
    }

    return meta;
  }
}
