import React from 'react';
import Avatar from '@/components/Avatar';
import FormField from '@/components/FormField';
import {
  CategorySelect,
  DateRangeInput,
  NextTaskSelect,
  LatestActivitySelect,
  RankingSelect,
  LeadOriginSelect,
  ProductSelect,
  UserSelect,
  CitySelect,
  TextInput,
  OrganizationSelect,
  ImportSelect
} from '@/components/Inputs';
import FilterState from '@/components/Columns/FilterState';
import ProductsPopover from '@/components/ProductsPopover';
import Rating from '@/components/Rating';
import SocialMediaIcons from '@/components/SocialMediaIcons';
import TaskTag from '@/components/TaskTag';
import DependentFormField from '@/components/DependentFormField';
import {
  buildQueryStringKeysFrom,
  buildTableDefaultValuesFrom,
  buildColumnsDefaultValuesFrom,
  renderColumnsFiltersFrom
} from '@/components/Columns/Utils';
import { getString, concatDateForFilter } from '@/utils';
import { DATE_RANGE_DEFAULT_PRESETS } from '@/date-range';
import { useCustomer } from '@/api';
import { toISOString } from '@/date';
import isEmpty from 'lodash/isEmpty';

const personColumns = [
  {
    name: 'name',
    humanizedName: 'Nome',
    default: true,
    getValue: (person) => person.name,
    fixed: true
  },
  {
    name: 'cpf',
    humanizedName: 'CPF',
    default: false,
    getValue: (person) => person.cpf
  },
  {
    name: 'organization',
    humanizedName: 'Empresa',
    default: true,
    getValue: (person) => person.organization?.name,
    queryString: {
      em: {
        filterKey: 'organization_id_eq',
        set: (value) => value,
        columnName: 'organization'
      }
    },
    filter: {
      name: 'organization_id_eq',
      defaultValue: null,
      renderComponent: (filter, value) => {
        /* eslint-disable react-hooks/rules-of-hooks */
        const { data: organization, isLoading } = useCustomer({
          customerId: value,
          params: { type: 'organization' },
          config: { enabled: Boolean(value) }
        });

        if (isLoading) {
          return (
            <TextInput
              disabled
              key={filter.name}
              name='organization_select_placeholder'
              label='Empresa'
              placeholder='Digite o nome da empresa'
              variant='light'
            />
          );
        }

        return (
          <FormField
            key={filter.name}
            name={filter.name}
            as={OrganizationSelect}
            placeholder='Digite o nome da empresa'
            defaultValue={organization?.data.name}
            creatable={false}
            className='mb-4'
          />
        );
      }
    }
  },
  {
    name: 'job_title',
    humanizedName: 'Cargo',
    default: false,
    getValue: (person) => person.jobTitle,
    queryString: {
      ca: {
        filterKey: 'job_title_i_cont',
        set: (value) => value,
        columnName: 'job_title'
      }
    },
    filter: {
      name: 'job_title_i_cont',
      defaultValue: '',
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={TextInput}
          label='Cargo'
          placeholder='Digite aqui'
          variant='light'
        />
      )
    }
  },
  {
    name: 'birthday',
    humanizedName: 'Aniversário',
    default: false,
    getValue: (person) => person.birthday
  },
  {
    name: 'birth_year',
    humanizedName: 'Ano nasc.',
    default: false,
    getValue: (person) => person.birthYear
  },
  {
    name: 'category',
    humanizedName: 'Categoria',
    default: true,
    getValue: (person) => person.category?.name,
    queryString: {
      ct: {
        filterKey: 'category_id_in',
        set: (value) => (isEmpty(value) ? [] : [Number(value)]),
        columnName: 'category'
      }
    },
    filter: {
      name: 'category_id_in',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={CategorySelect}
          allowNull
          multiple
          isSearchable
        />
      )
    }
  },
  {
    name: 'origin',
    humanizedName: 'Origem',
    default: false,
    getValue: (person) => person.leadOrigin?.name,
    queryString: {
      origemLead: {
        filterKey: 'lead_origin_id_in',
        set: (value) => (isEmpty(value) ? [] : [Number(value)]),
        columnName: 'origin'
      }
    },
    filter: {
      name: 'lead_origin_id_in',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={LeadOriginSelect}
          allowNull
          multiple
          isSearchable
        />
      )
    }
  },
  {
    name: 'owner',
    humanizedName: 'Responsável',
    default: true,
    getValue: (person) => (
      person.ownerUser?.name
        ? (
          <span className='d-inline-flex align-items-center'>
            <Avatar
              name={person.ownerUser?.name}
              url={person.ownerUser?.avatarUrl}
              className='me-1'
              tooltip={false}
            />
            {person.ownerUser?.name}
          </span>
        )
        : null
    ),
    queryString: {
      ur: {
        filterKey: 'user_id_in',
        set: (value) => (isEmpty(value) ? [] : [Number(value)]),
        columnName: 'owner'
      }
    },
    filter: {
      name: 'user_id_in',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={UserSelect}
          label='Responsável'
          multiple
          isSearchable
          allowNull
        />
      )
    }
  },
  {
    name: 'email',
    humanizedName: 'Email',
    default: true,
    getValue: (person) => person.contact?.email
  },
  {
    name: 'phone',
    humanizedName: 'Telefone',
    default: true,
    getValue: (person) => person.contact?.availablePhone
  },
  {
    name: 'social_medias',
    humanizedName: 'Redes sociais',
    default: false,
    getValue: (person) => (
      person.socialMedias?.length
        ? <SocialMediaIcons data={person.socialMedias} />
        : null
    )
  },
  {
    name: 'cep',
    humanizedName: 'CEP',
    default: false,
    getValue: (person) => person.address?.postalCode
  },
  {
    name: 'country',
    humanizedName: 'País',
    default: false,
    getValue: (person) => person.address?.country
  },
  {
    name: 'state',
    humanizedName: 'Estado',
    default: false,
    getValue: (person) => person.address?.state,
    queryString: {
      e: {
        filterKey: 'state_in',
        set: (value = '') => [value.toUpperCase()],
        columnName: 'state'
      }
    },
    filter: {
      name: 'state_in',
      defaultValue: null,
      onClear: ({ setFieldValue }) => {
        setFieldValue('city_id_in', null);
      },
      renderComponent: FilterState
    }
  },
  {
    name: 'city',
    humanizedName: 'Cidade',
    default: false,
    getValue: (person) => person.address?.city?.name,
    queryString: {
      c: {
        filterKey: 'city_id_in',
        set: (value) => (isEmpty(value) ? [] : [Number(value)]),
        columnName: 'city'
      }
    },
    filter: {
      name: 'city_id_in',
      defaultValue: null,
      renderComponent: (filter) => (
        <DependentFormField
          key={filter.name}
          name={filter.name}
          as={CitySelect}
          source='state_in'
          target='state'
          allowNull
          multiple
          isSearchable
        />
      )
    }
  },
  {
    name: 'district',
    humanizedName: 'Bairro',
    default: false,
    getValue: (person) => person.address?.district,
    queryString: {
      b: {
        filterKey: 'district_i_cont',
        set: (value) => value,
        columnName: 'district'
      }
    },
    filter: {
      name: 'district_i_cont',
      defaultValue: '',
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={TextInput}
          label='Bairro'
          placeholder='Digite aqui'
          variant='light'
        />
      )
    }
  },
  {
    name: 'street_name',
    humanizedName: 'Rua',
    default: false,
    getValue: (person) => person.address?.streetName
  },
  {
    name: 'street_number',
    humanizedName: 'Número',
    default: false,
    getValue: (person) => person.address?.streetNumber
  },
  {
    name: 'additional_info',
    humanizedName: 'Complemento',
    default: false,
    getValue: (person) => person.address?.additionalInfo
  },
  {
    name: 'products',
    humanizedName: 'Produtos',
    default: false,
    getValue: (person) => {
      const productsEmptyListMessage = getString(
        ['messages', 'person', 'products', 'empty_list']
      );

      return (
        <ProductsPopover
          entity={{ id: person.id, type: 'person' }}
          emptyListMessage={productsEmptyListMessage}
          products={person.products}
        />
      );
    },
    queryString: {
      pro: {
        filterKey: 'products_id_in',
        set: (value) => (isEmpty(value) ? [] : [Number(value)]),
        columnName: 'products'
      }
    },
    filter: {
      name: 'products_id_in',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={ProductSelect}
          multiple
          allowNull
        />
      )
    }
  },
  {
    name: 'next_task',
    humanizedName: 'Próxima tarefa',
    default: false,
    getValue: (person) => (
      <TaskTag variant='nextTask'
        task={person?.nextTask}
        entity={{ type: 'person', id: person.id }}
      />
    ),
    filter: {
      name: 'next_task',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={NextTaskSelect}
        />
      )
    }
  },
  {
    name: 'latest_task',
    humanizedName: 'Última atividade',
    default: false,
    getValue: (person) => (
      <TaskTag variant='latestTask'
        task={person?.nextTask}
        entity={{ type: 'person', id: person.id }}
      />
    ),
    filter: {
      name: 'latest_activity',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={LatestActivitySelect}
        />
      )
    }
  },
  {
    name: 'import',
    humanizedName: 'Importações',
    default: false,
    getValue: (person) => person?.importedAt,
    className: 'text-nowrap',
    queryString: {
      import: {
        filterKey: 'import_id_in',
        set: (value) => (isEmpty(value) ? [] : [Number(value)]),
        columnName: 'import'
      }
    },
    filter: {
      name: 'import_id_in',
      defaultValue: null,
      renderComponent: (filter) => (
        <DependentFormField
          key={filter.name}
          name={filter.name}
          as={ImportSelect}
          source='import_id_in'
          entity='people'
          allowNull
          multiple
          isSearchable
        />
      )
    }
  },
  {
    name: 'ranking',
    humanizedName: 'Ranking',
    default: true,
    getValue: (person, onUpdate) => (
      <Rating
        value={person.ranking ?? 0}
        onChange={(ranking, done) => onUpdate(person.id, { ranking }, done)}
      />
    ),
    filter: {
      name: 'ranking_in',
      defaultValue: null,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={RankingSelect}
        />
      )
    }
  },
  {
    name: 'updated_at',
    humanizedName: 'Última atualização',
    default: false,
    className: 'text-nowrap',
    getValue: (person) => person.updatedAt
  },
  {
    name: 'created_at',
    humanizedName: 'Data de cadastro',
    default: false,
    className: 'text-nowrap',
    getValue: (person) => person.createdAt,
    queryString: {
      createdAtStart: {
        filterKey: 'created_at_utc_date_within',
        set: (value = '') => `${toISOString(value) || ''}/`,
        concat: concatDateForFilter,
        columnName: 'created_at'
      },
      createdAtEnd: {
        filterKey: 'created_at_utc_date_within',
        set: (value = '') => `/${toISOString(value) || ''}`,
        concat: concatDateForFilter,
        columnName: 'created_at'
      }
    },
    filter: {
      name: 'created_at_utc_date_within',
      defaultValue: null,
      isPopover: true,
      renderComponent: (filter) => (
        <FormField
          key={filter.name}
          name={filter.name}
          as={DateRangeInput}
          label='Data de cadastro'
          presets={DATE_RANGE_DEFAULT_PRESETS}
        />
      )
    }
  }
];

const columnsFilters = renderColumnsFiltersFrom(personColumns);

const columnsDefaultValues = buildColumnsDefaultValuesFrom(personColumns);

const tableDefaultValues = buildTableDefaultValuesFrom(personColumns);

const queryStringKeys = buildQueryStringKeysFrom(personColumns);

export default personColumns;
export { columnsDefaultValues, columnsFilters, tableDefaultValues, queryStringKeys };
