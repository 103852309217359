import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tooltip from '@/components/Tooltip';

// eslint-disable-next-line no-magic-numbers
const LINES = [1, 2, 3, 4];

const propTypes = {
  as: PropTypes.any,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  lines: PropTypes.oneOf(LINES),
  disableTooltip: PropTypes.bool
};

const defaultProps = {
  as: 'div',
  children: '',
  className: '',
  lines: 1,
  disableTooltip: false
};

/**
 *
 * Acrescenta a classe text-truncate e uma Tooltip quando o texto for truncado.
 * *Não* considera eventuais mudanças de largura do contâiner, então funciona
 * melhor para contâiners de largura fixa.
 *
 */
function Truncate(props) {
  const {
    as: Tag, children, className, lines, disableTooltip, ...otherProps
  } = props;

  const [show, setShow] = useState(false);

  // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const textRef = (node) => {
    if (node === null) {
      return;
    }

    if (node.scrollWidth > node.clientWidth || node.scrollHeight > node.clientHeight) {
      setShow(true);
    }
  };

  return (
    <Tooltip content={children} hide={disableTooltip || !show}>
      <Tag
        { ...otherProps }
        ref={textRef}
        className={classnames(className, {
          'text-truncate': lines === 1,
          'text-line-clamp': lines > 1
        })}
        style={{ ...props.style, '--line-clamp': lines }}
      >
        {children}
      </Tag>
    </Tooltip>
  );
}

Truncate.propTypes = propTypes;
Truncate.defaultProps = defaultProps;

export default Truncate;
