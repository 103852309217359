import Authorization from '@/components/Authorization';
import Button from '@/components/Button';
import { useActivityCard } from '@/components/EntityModal/Activities/ActivityCard/context';
import Icon from '@/components/Icon';
import LoadingButton from '@/components/LoadingButton';
import Popover from '@/components/Popover';
import Tooltip from '@/components/Tooltip';
import { canDeleteActivity } from '@/policies';
import classnames from 'classnames';
import React, { useState } from 'react';

function DeleteActivityButton({ className }) {
  const { activity, onDelete } = useActivityCard();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDelete = () => {
    setIsSubmitting(true);
    onDelete(activity.id, () => setIsSubmitting(false));
  };

  return (
    <Popover
      rootCloseEvent='mousedown'
      placement='bottom'
      showArrow
      flip
      contentClassName='pt-4 pb-4 px-3'
      content={({ onHide }) => (
        <>
          <h4 className='ms-3 me-3 mb-3 text-darker-gray fw-bold'>
            Deseja realmente excluir esta <br /> atividade?
          </h4>
          <Button
            className='ms-3 me-3'
            variant='light'
            onClick={onHide}
          >
            Agora não
          </Button>
          <LoadingButton
            className='me-3'
            isLoading={isSubmitting}
            onClick={handleDelete}
            variant='danger'
            type='submit'
            loadingText='Excluindo...'
          >
            Sim, excluir
          </LoadingButton>
        </>
      )}
    >
      <div>
        <Authorization policy={canDeleteActivity}>
          <Tooltip content='Excluir'>
            <Button
              className={classnames('ms-auto text-dark-gray', className)}

              variant='link'
            >
              <Icon name='delete' size='sm' />
            </Button>
          </Tooltip>
        </Authorization>
      </div>
    </Popover>
  );
}

export default DeleteActivityButton;
