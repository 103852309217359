import React from 'react';
import Proptypes from 'prop-types';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Divisor from '@/components/Divisor';
import ExternalLink from '@/components/ExternalLink';
import noResults from 'images/no-results.svg';

NoResults.propTypes = {
  pathname: Proptypes.string,
  surveyUrl: Proptypes.string,
  trackPartnerButtonClicked: Proptypes.func
};

NoResults.defaultProps = {
  pathname: '',
  surveyUrl: '',
  trackPartnerButtonClicked: () => {}
};

function NoResults({ pathname, surveyUrl, trackPartnerButtonClicked }) {
  const result = 'Resultado nao encontrado';

  const renderPartnerButton = (href, partner) => (
    <Button
      href={`${pathname}/${href}`}
      variant='light'
      className='w-50 text-dark-gray'
      onClick={() => trackPartnerButtonClicked(partner, 'Resultado nao encontrado')}
    >
      <Icon name='external-link' size='sm' className='me-1 mb-1' />
      {partner}
    </Button>
  );

  return (
    <div className='d-flex justify-content-center w-100'>
      <div className='d-flex flex-column align-items-center text-dark-gray'>
        <img src={noResults} width={72} alt='no-results' />

        <h4 className='my-4'>
          A integração que você está buscando não está disponível...
        </h4>

        <p>Encontre mais integrações nas plataformas abaixo:</p>
        <div className='d-flex justify-content-between gap-3 w-100'>
          {renderPartnerButton('pluga', 'Pluga')}
          {renderPartnerButton('zapier', 'Zapier')}
          {renderPartnerButton('make', 'Make')}
        </div>

        <Divisor />

        <p>Desenvolva sua própria integração utilizando nossa API pública:</p>
        <Button
          href={`${pathname}/dashboard`}
          variant='light'
          className='w-100 text-dark-gray'
          onClick={
            () => trackPartnerButtonClicked('Area desenvolvedor', result)
          }
        >
            Área do desenvolvedor
        </Button>

        <Divisor />

        <p>Solicite à nossa equipe o desenvolvimento da integração:</p>
        <Button
          as={ExternalLink}
          href={surveyUrl}
          target='_blank'
          variant='light'
          className='w-100 text-dark-gray'
          onClick={
            () => trackPartnerButtonClicked('Solicitar integracao', result)
          }
        >
          Solicitar uma nova integração
        </Button>
      </div>
    </div>
  );
}

export default NoResults;
