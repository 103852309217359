import React from 'react';
import { Navigate } from 'react-router-dom';
import { canSetupAccount } from '@/policies';
import Authorization from '@/components/Authorization';
import Setup from '@/feature/profile_form/ABTest/Setup';
import { funnelsPath } from '@/routes';

function SetupStep() {
  return (
    <Authorization
      policy={canSetupAccount}
      fallback={<Navigate to={funnelsPath()} />}
    >
      <Setup />
    </Authorization>
  );
}

export default SetupStep;
