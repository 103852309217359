import React from 'react';
import PropTypes from 'prop-types';

import Stepper from '@/components/Stepper';
import Header from '@/feature/profile_form/Header';
import CRMExperienceStep from '@/feature/profile_form/CRMExperienceStep';
import SegmentStep from '@/feature/profile_form/SegmentStep';
import NeedStep from '@/feature/profile_form/NeedStep';
import FindUs from '@/feature/profile_form/FindUsStep';

const propTypes = {
  advanceStep: PropTypes.func
};

function FormStep(props) {
  const { advanceStep } = props;

  return (
    <Stepper Header={Header}>
      <CRMExperienceStep />
      <SegmentStep />
      <NeedStep />
      <FindUs onSuccess={advanceStep} />
    </Stepper>
  );
}

FormStep.propTypes = propTypes;

export default FormStep;
