import { LEGACY_HOST } from '@/config';
import { isBlank } from '@/utils';

const ENTITY_PARAM = 'entidade';
const ID_PARAM = 'id';

const ENTITY_RESOURCES = {
  organization: 'empresas',
  person: 'pessoas',
  deal: 'negocios',
  activity: 'tarefas'
};

const FEATURE_RESOURCES = {
  newFunnel: 'NovoFunil',
  newForms: 'NovosForms',
  newLists: 'NovasListagens'
};

export function legacyNewFunnelOptoutUrl() {
  return buildLegacyOptoutUrl('newFunnel');
}

export function legacyNewFormsOptoutUrl(entity) {
  if (!entity) {
    return;
  }

  const queryString = buildLegacyOptoutUrlQueryString(entity);

  return buildLegacyOptoutUrl('newForms', queryString);
}

export function legacyNewListsOptoutUrl(entity) {
  if (!entity) {
    return;
  }

  const queryString = buildLegacyOptoutUrlQueryString(entity);

  return buildLegacyOptoutUrl('newLists', queryString);
}

function buildLegacyOptoutUrl(feature, queryString = '') {
  const featureResource = FEATURE_RESOURCES[feature];

  const path = `src/php/sistema/http/obj/Feature/${featureResource}/optout.php?${queryString}`;

  return `${LEGACY_HOST}/${path}`;
}

function buildLegacyOptoutUrlQueryString(entity) {
  const params = {
    [ENTITY_PARAM]: ENTITY_RESOURCES[entity.type],
    [ID_PARAM]: entity.id
  };

  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (!isBlank(value)) {
      searchParams.set(key, value);
    }
  });

  return searchParams.toString();
}
