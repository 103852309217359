import React from 'react';
import PropTypes from 'prop-types';
import Stack from 'react-bootstrap/Stack';

import Badge from '@/components/Badge';
import Dropdown from '@/components/Dropdown';
import Icon, { ICONS } from '@/components/Icon';

const propTypes = {
  data: PropTypes.shape({
    key: PropTypes.string,
    icon: PropTypes.oneOf(ICONS),
    title: PropTypes.string,
    href: PropTypes.string,
    completed: PropTypes.bool
  }).isRequired
};

function OngoingChecklistItem(props) {
  const {
    data: {
      key,
      icon,
      title,
      href,
      completed
    }
  } = props;

  return (
    <Dropdown.Item
      key={key}
      className='border border-1 rounded-1 p-2 position-relative text-dark-gray'
      href={href}
    >
      <Stack direction='horizontal' gap={3}>
        <Badge
          variant={completed ? 'flat-green' : 'primary'}
          className='p-1 rounded-circle'
        >
          <Icon size='sm' name={icon} />
        </Badge>

        <b className={completed ? 'text-decoration-line-through' : ''}>
          {title}
        </b>

        <div className='ms-auto text-primary px-1'>
          <Icon size='sm' name='arrow-right' />
        </div>
      </Stack>
    </Dropdown.Item>
  );
}

OngoingChecklistItem.propTypes = propTypes;

export default OngoingChecklistItem;
