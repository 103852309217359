/*
 * Anteriormente usava-se a lib ReactQuill para implementar o rich text.
 * Entretanto, com novas POCs, encontramos uma lib que aparenta corresponder
 * melhor ao que necessitamos dentro das features do Agendor (https://xdsoft.net/jodit/).
 * Por isso, ela acabou entrando em lugar do ReactQuill de modo geral para manter
 * a consistência e encontra-se em fase de testes.
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import JoditEditor from 'jodit-react';
import Form from 'react-bootstrap/Form';

const propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  hideLink: PropTypes.bool,
  readOnly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  toolbarButtonSize: PropTypes.string
};

const defaultProps = {
  className: '',
  name: '',
  value: '',
  label: null,
  hint: null,
  onChange: () => { },
  onBlur: () => { },
  error: null,
  hideLink: false,
  readOnly: false,
  autoFocus: false,
  toolbarButtonSize: 'small'
};

function RichTextInput(props) {
  const {
    className, name, label, hint, value, onChange, error, hideLink,
    readOnly, autoFocus, toolbarButtonSize
  } = props;

  const toolbar = `bold, italic, underline, strikethrough, |,
    font, fontsize, |, ul, ol, |, indent, outdent, align, |,
    table, image${hideLink ? '' : ', link'}`;

  const inlineToolbar = [
    'bold',
    'italic',
    'underline',
    'strikethrough',
    '|',
    'font',
    'fontsize',
    '\n',
    'ul',
    'ol',
    '|',
    'indent',
    'outdent',
    '|',
    'paragraph',
    '\n',
    'align',
    'lineHeight',
    '|',
    'superscript',
    'subscript',
    '|',
    'brush',
    '\n',
    'eraser',
    '|',
    'table',
    'image',
    `${hideLink ? '---' : 'link'}`,
    '---',
    'undo',
    'redo'
  ];

  const [editorContent, setEditorContent] = useState(value);
  const [editorConfig, setEditorConfig] = useState({
    direction: 'ltr',
    language: 'pt_br',
    toolbarSticky: false,
    toolbarAdaptive: false,
    toolbarInlineForSelection: true,
    selection: { focus: true },
    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    defaultActionOnPaste: 'insert_as_html',
    placeholder: 'Escreva o conteúdo aqui...',
    uploader: { insertImageAsBase64URI: true },
    popup: { selection: inlineToolbar },
    disablePlugins: `about, add-new-line, class-span, file, powered-by-jodit,
      paste, symbols`
  });

  useEffect(() => {
    setEditorContent(value);
    setEditorConfig((prevConfig) => ({
      ...prevConfig,
      readonly: readOnly,
      toolbar: !readOnly,
      toolbarButtonSize,
      autofocus: autoFocus,
      buttons: toolbar,
      enter: 'div',
      resizer: {
        showSize: true,
        useAspectRatio: true,
        hideSizeTimeout: 2000,
        forImageChangeAttributes: true
      }
    }));
  }, [readOnly, hideLink, autoFocus, toolbarButtonSize]);

  const handleChange = (newValue) => {
    if (newValue === '<p><br></p>') {
      onChange('');
      return;
    }
    /*
     * Quando inserida uma tabela, força as cores das bordas (necessário para
     * a visualização da tabela no PDF(preview) de propostas).
     */
    const sanitizedValue = newValue?.replaceAll(
      '<td style="width',
      '<td class="p-2" style="border: 1px solid #DADADA; width'
    );

    if (sanitizedValue !== editorContent) {
      onChange(sanitizedValue);
    }
  };

  return (
    <Form.Group className={className} controlId={name}>
      {(label || hint)
        ? (
          <div className='d-flex align-items-base justify-content-between'>
            <Form.Label>{label}</Form.Label>
            <Form.Text className='text-medium-gray fst-italic text-small mt-0'>
              {hint}
            </Form.Text>
          </div>
        )
        : null
      }

      <JoditEditor
        value={value}
        config={editorConfig}
        onBlur={(newContent) => setEditorContent(newContent)}
        onChange={handleChange}
      />
      <div className={classnames({ 'is-invalid': Boolean(error) })} />
      <Form.Control.Feedback type='invalid'>
        {error}
      </Form.Control.Feedback>
    </Form.Group>
  );
}

RichTextInput.propTypes = propTypes;
RichTextInput.defaultProps = defaultProps;
RichTextInput.displayName = 'RichTextInput';

export default RichTextInput;
