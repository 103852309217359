import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { dealPath, organizationPath, personPath } from '@/routes';
import Button from '@/components/Button';

const propTypes = {
  entityType: PropTypes.string,
  entityId: PropTypes.number,
  entityName: PropTypes.string,
  onClose: PropTypes.func
};

function Alert({ entityType, entityId, entityName }) {
  const navigate = useNavigate();

  const labels = { organization: 'Empresa', person: 'Pessoa', deal: 'Negócio' };
  const paths = { organization: organizationPath, person: personPath, deal: dealPath };
  const entityLabel = ['organization', 'person'].includes(entityType) ? 'salva' : 'salvo';

  return (
    <div className='d-flex align-items-center justify-content-between' style={{ gap: '8px' }}>
      <div>
        <div className='fw-bold'>
          {labels[entityType]} {entityLabel} com sucesso!
        </div>
        <div className='mb-0'>{entityName}</div>
      </div>
      <Button
        onClick={() => navigate(paths[entityType](entityId))}
        variant='link'
        className='fw-bold text-dark-gray'
      >
        Visualizar
      </Button>
    </div>
  );
}

Alert.propTypes = propTypes;

export default Alert;
