import React, { useMemo } from 'react';
import Link from '@/components/Link';
import Icon from '@/components/Icon';
import { dealPath, organizationPath, personPath } from '@/routes';
import Task from '@/task';
import { useActivityCard } from '@/components/EntityModal/Activities/ActivityCard/context';

const getEntityData = ({ entityData, entityType }) => {
  const entity = {
    deal: {
      path: dealPath(entityData.id),
      icon: 'deal',
      name: entityData.title
    },

    organization: {
      path: organizationPath(entityData.id),
      icon: 'organization',
      name: entityData.name
    },

    person: {
      path: personPath(entityData.id),
      icon: 'person',
      name: entityData.name
    }
  };

  return { ...entity[entityType], id: entityData.id };
};


const EntityLink = ({ path, icon, name }) => (
  <Link
    href={path}
    className='fw-bold link-dark-gray hover-text-primary'
  >
    <Icon name={icon} size='sm' className='me-2' />

    <span>{name}</span>
  </Link>
);

const RelatedEntityLink = ({ entityData }) => {
  const relatedEntityType = useMemo(() => {
    if (entityData.deal) {
      return 'deal';
    }
    if (entityData.organization) {
      return 'organization';
    }
    if (entityData.person) {
      return 'person';
    }
    return null;
  }, [entityData]);

  if (!relatedEntityType) {
    return null;
  }

  const relatedEntityData = getEntityData({
    entityType: relatedEntityType,
    entityData: entityData[relatedEntityType]
  });

  return (
    <>
      <EntityLink
        path={relatedEntityData.path}
        icon={relatedEntityData.icon}
        name={relatedEntityData.name}
      />
      <span className='mx-1'>|</span>
    </>
  );
};

function Header() {
  const { activity } = useActivityCard();

  const entityType = Task.build(activity).relatedEntityType;

  if (!entityType) {
    return null;
  }

  const entityData = activity[entityType] || {};
  const activityEntity = getEntityData({ entityData, entityType });

  return (
    <div className='px-4 py-1 border-bottom border-1 text-small'>
      <RelatedEntityLink entityData={entityData} />
      <EntityLink
        path={activityEntity.path}
        icon={activityEntity.icon}
        name={activityEntity.name}
      />
    </div>
  );
}

export default Header;
