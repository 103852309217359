import React from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';
import LoadDots from '@/components/LoadDots';

const propTypes = {
  onClick: PropTypes.func,
  show: PropTypes.bool,
  loading: PropTypes.bool
};

const defaultProps = {
  onClick: () => { },
  show: false,
  loading: false
};

function SmartSuggestionButton({ loading, show, onClick }) {
  if (loading) {
    return (
      <div className='d-flex justify-content-center ms-3 px-2'>
        <LoadDots tooltipText='Verificando se há sugestões inteligentes nessa atividade' />
      </div>
    );
  }
  return (
    <div>
      <Tooltip
        content={
          show
            ? 'Sugestões inteligentes'
            : 'Clique para ver as sugestões inteligentes'
        }
      >
        <Button
          className='me-n2 text-dark-gray'
          variant='link'
          size='sm'
          onClick={onClick}
          aria-label='ia'
        >
          <Icon name='ai' />
        </Button>
      </Tooltip>
    </div>
  );
}

SmartSuggestionButton.propTypes = propTypes;
SmartSuggestionButton.defaultProps = defaultProps;

export default SmartSuggestionButton;
