import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import RangeAdornment from '@/components/RangeAdornment';
import DateRangePopover from '@/components/DateRangePopover';
import TextInput from '@/components/Inputs/TextInput';
import { parseRangeISO } from '@/date-range';
import { toDateString, formatRange } from '@/date';

const propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  presets: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    days: PropTypes.number
  })),
  value: PropTypes.string
};

const defaultProps = {
  presets: []
};

function DateRangeInput({ name, label, onChange, value, presets }) {
  const initialState = useMemo(() => getState(value), [value]);
  const [selectedFrom, setSelectedFrom] = useState(initialState.to);
  const [selectedTo, setSelectedTo] = useState(initialState.from);

  const onSelect = (range) => {
    const newSelectedFrom = range[0] ? toDateString(range[0]) : undefined;
    const newSelectedTo = range[1] ? toDateString(range[1]) : undefined;

    setSelectedFrom(newSelectedFrom);
    setSelectedTo(newSelectedTo);
  };

  const onReset = () => {
    setSelectedTo(undefined);
    setSelectedFrom(undefined);
    onChange(null);
  };

  const isApplied = Boolean(initialState.from || initialState.to);

  // Update state when the value prop changes
  useEffect(() => {
    if (value) {
      const newState = getState(value);
      onSelect([newState.from, newState.to]);
    }
  }, [value]);

  const inputValue = useMemo(() => (
    formatRange(initialState.from, initialState.to)
  ), [initialState]);

  return (
    <DateRangePopover
      onChange={onChange}
      value={value}
      presets={presets}
      selectedFrom={selectedFrom}
      setSelectedFrom={setSelectedFrom}
      selectedTo={selectedTo}
      setSelectedTo={setSelectedTo}
    >
      <TextInput
        readOnly
        name={name}
        value={inputValue}
        label={label}
        placeholder='Qualquer período'
        innerClassName='cursor-default'
        rightAdornment={() => (
          <RangeAdornment isFilled={isApplied} onClear={onReset} />
        )}
      />
    </DateRangePopover>
  );
}

function getState(value) {
  const parsed = parseRangeISO(value) || [];

  return { from: parsed[0], to: parsed[1] };
}

DateRangeInput.propTypes = propTypes;

DateRangeInput.defaultProps = defaultProps;

export default DateRangeInput;
