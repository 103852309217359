import React from 'react';
import { useActivityCard } from '@/components/EntityModal/Activities/ActivityCard/context';
import { Col, Row } from 'react-bootstrap';
import EmailDetailPopover from '@/components/Email/EmailDetailPopover';
import EmailStatusBadge from '@/components/Email/EmailStatusBadge';
import { EMAIL_STATUSES } from '@/utils';
import Icon from '@/components/Icon';
import CreatedBy from '@/components/EntityModal/Activities/ActivityCard/CreatedBy';
import EmailBodyContent from '@/components/Email/EmailBodyContent';

function Email() {
  const { activity } = useActivityCard();
  const email = activity.email;
  const isEmailReceived = email.status === EMAIL_STATUSES.RECEIVED;

  return (
    <div className='rounded-bottom bg-white'>
      <Row className='row mb-3'>
        <Col sm={9}>
          <span>Assunto:</span>
          <span className='fw-bold text-nowrap ms-1'>
            {email.subject}
          </span>
          <EmailDetailPopover email={email} />
        </Col>
        <Col sm={3} className='text-end'>
          <EmailStatusBadge
            status={email.status}
            statusMessage={email.statusMessage}
          />
        </Col>
      </Row>

      <Row className='row mb-3'>
        <Col className='d-flex align-items-center'>
          Enviado por:
          <div className='ms-1 d-inline-flex align-items-center'>
            {
              isEmailReceived
                ? (
                  <div>
                    <Icon name='email-received' className='me-1' />
                    {email.from}
                  </div>
                )
                : (
                  <CreatedBy
                    createdBy={activity.createdBy}
                    showLabel
                  />
                )
            }
          </div>
        </Col>
      </Row>

      <hr className='mb-2'/>

      <Row>
        <Col>
          <EmailBodyContent email={email} />
        </Col>
      </Row>
    </div>
  );
}

export default Email;
