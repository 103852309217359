import { post } from '@/lib/fetch';
import { useMutation } from 'react-query';

async function createSetup() {
  const { data } = await post('/setup');

  return data;
}

export function useCreateSetup({ config } = {}) {
  return useMutation(createSetup, config);
}
