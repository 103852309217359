import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import classnames from 'classnames';

import emptyActivities from 'images/empty-activities.png';

import LoadSpinner from '@/components/LoadSpinner';
import ActivityCard from '@/components/EntityModal/Activities/ActivityCard';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import Skeleton from '@/components/Skeleton';
import Stack from 'react-bootstrap/Stack';

Activities.propTypes = {
  entity: PropTypes.object,
  relatedEntity: PropTypes.object,
  activities: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    createdBy: PropTypes.shape({
      name: PropTypes.string
    }),
    dueAt: PropTypes.string,
    createdAt: PropTypes.string,
    text: PropTypes.string,
    finished: PropTypes.bool,
    finishedAt: PropTypes.string,
    finishedBy: PropTypes.shape({
      name: PropTypes.string
    }),
    assignedUsers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      active: PropTypes.bool,
      name: PropTypes.string,
      avatarUrl: PropTypes.string
    }))
  })),
  isFetchingActivities: PropTypes.bool,
  isFetchingNextPageActivities: PropTypes.bool,
  fetchNextPageActivities: PropTypes.func,
  hasNextPageActivities: PropTypes.bool,
  isLoading: PropTypes.bool,
  onDeleteActivity: PropTypes.func,
  onUpdateActivity: PropTypes.func,
  onEditEntity: PropTypes.func
};

Activities.defaultProps = {
  entity: null,
  relatedEntity: null,
  activities: [],
  isFetchingActivities: false,
  isFetchingNextPageActivities: false,
  fetchNextPageActivities: () => { },
  hasNextPageActivities: false,
  isLoading: false,
  onDeleteActivity: () => { },
  onUpdateActivity: () => { },
  onEditEntity: () => { }
};

const EmptyActivities = () => (
  <div className='p-6 text-center'>
    <img src={emptyActivities} alt='Nenhuma atividade registrada' />
    <h4 className='mb-2'>Nenhuma atividade registrada</h4>
    <span>Comece a organizar suas atividades e otimizar seu tempo com o Agendor.</span>
  </div>
);

function Activities({
  activities,
  isFetchingActivities,
  isFetchingNextPageActivities,
  fetchNextPageActivities,
  hasNextPageActivities,
  isLoading,
  onDeleteActivity,
  onUpdateActivity
}) {
  const tracker = useTracking();
  const { user } = useAuth();
  const isLoadingOrFetching = isLoading || isFetchingActivities || isFetchingNextPageActivities;

  const onFinished = () => {
    tracker.trackTaskFinished({ user, screen: 'Nova listagem tarefas' });
  };

  if (isLoading) {
    return (
      <Stack gap='3'>
        <Skeleton width='100%' height={154} />
        <Skeleton width='100%' height={154} />
      </Stack>
    );
  }

  if (!activities.length && !isLoadingOrFetching) {
    return <EmptyActivities />;
  }

  return (
    <>
      <InfiniteScroll
        scrollableTarget='task-list'
        dataLength={activities.length}
        next={fetchNextPageActivities}
        hasMore={hasNextPageActivities}
        scrollThreshold={0.95}
        className='overflow-hidden d-flex flex-column flex-grow-1 flexible-height'
        loader={
          <LoadSpinner
            size='md'
            className={classnames(
              'text-center',
              'mx-auto',
              'text-dark-gray',
              'opacity-100'
            )}
          />
        }
      >
        {activities.map((activity) => (
          <ActivityCard
            key={activity.id}
            className='mb-3'
            activity={activity}
            onDelete={onDeleteActivity}
            onUpdate={onUpdateActivity}
            onFinished={onFinished}
          />
        ))}
      </InfiniteScroll>
    </>
  );
}

export default Activities;
