import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntegrations } from '@/api';
import isEqual from 'lodash/isEqual';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TextInput } from '@/components/Inputs';
import InputAdornment from '@/components/InputAdornment';
import Icon from '@/components/Icon';
import Link from '@/components/Link';
import ExternalLink from '@/components/ExternalLink';
import LoadingPage from '@/components/LoadingPage';
import IntegrationCard from '@/feature/integration/IntegrationCard';
import NoResults from '@/feature/integration/NoResults';
import IntegrationModal from '@/components/IntegrationModal';
import { useStoredState, useDebouncedValue } from '@/hooks';
import { legacyIntegrationsUrl, settingsIntegrationsPath } from '@/routes';
import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';
import { RAILS_ENV } from '@/config';

const DEBOUNCE_DELAY = 2000;

function IntegrationsIndex() {
  const tracker = useTracking();
  const { user } = useAuth();
  const { data: integrations, isLoading } = useIntegrations();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const account = user.account;

  const userName = user.name.trim().split(' ');
  const firstName = userName.at(0);
  const lastName = userName.slice(1).join(' ');

  const result = 'Resultado encontrado';

  const surveyParams = () => {
    let params = `first_name=${encodeURI(firstName)}&last_name=${encodeURI(lastName)}`;
    params = `${params}&email=${encodeURI(user.email)}&uid=${user.id}`;
    params = `${params}&plano=${encodeURI(account.plan.name)}%20${account.plan.id}`;
    params = `${params}&nomePlano=${encodeURI(account.plan.name)}`;
    params = `${params}&tipoConta=${account.subscriptionStatus}`;
    params = `${params}&ramoAtividade=${encodeURI(account.industry)}`;
    params = `${params}&grupo=${encodeURI(user.groupType)}&persona=${encodeURI(user.persona)}`;

    return params;
  };

  const surveyUrl = `
    https://survey.survicate.com/f98f3ba92fb9f9e5/?p=integracoes&${surveyParams()}
  `;

  let activeIntegrations = useMemo(() => (integrations?.data ?? [])
    .filter((integration) => integration.active)
    .map((integration) => ({
      id: integration?.id,
      logo: integration?.logo?.[0]?.url,
      name: integration?.name,
      tag: integration?.tag?.[0]?.value?.toUpperCase(),
      shortDescription: integration?.shortDescription,
      legacy: integration?.legacy,
      slug: integration?.slug,
      categories: integration?.categories.map((category) => category.value),
      contentFiles: integration?.contentFiles.map((contentFile) => contentFile.url),
      published: integration?.published
    })), [integrations]);

  if (RAILS_ENV === 'production') {
    activeIntegrations = activeIntegrations.filter((integration) => integration.published);
  }

  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const {
    value: filteredIntegrations, store: setFilteredIntegrations
  } = useStoredState('filtered-integrations', activeIntegrations);
  const {
    value: searchTerm, store: setSearchTerm
  } = useStoredState('integrations-search', '');

  useEffect(() => {
    if (!isLoading && activeIntegrations.length) {
      const integration = activeIntegrations.find(({ slug }) => pathname === slug);
      if (integration) {
        if (integration?.legacy) {
          const redirectUrl = `${legacyIntegrationsUrl()}/#${integration.slug}`;

          window.location.href = redirectUrl;
        } else {
          setSelectedIntegration(integration);
          setShowModal(true);
        }
      }
    }
  }, [pathname, activeIntegrations, isLoading]);

  useEffect(() => {
    if (!isEqual(filteredIntegrations, activeIntegrations) && !searchTerm) {
      setFilteredIntegrations(activeIntegrations);
    }
  }, [activeIntegrations, searchTerm]);

  const handleOpenModal = (integration) => {
    if (integration.legacy) {
      const redirectUrl = `${legacyIntegrationsUrl()}/#${integration.slug}`;

      window.location.href = redirectUrl;
      return;
    }
    navigate(`${settingsIntegrationsPath()}/${integration.slug}`, { replace: true });
    setShowModal(true);
    setSelectedIntegration(integration);
  };

  const handleCloseModal = () => {
    navigate(settingsIntegrationsPath(), { replace: true });
    setShowModal(false);
    setSelectedIntegration(null);
  };

  const trackSearch = (value) => {
    const searchResult = filteredIntegrations.length ? 'Econtrado' : 'Não encontrado';
    tracker.trackIntegrationSearched({ user, searchTerm: value, searchResult });
  };

  const debouncedSearchTerm = useDebouncedValue(searchTerm, DEBOUNCE_DELAY);

  useEffect(() => {
    if (searchTerm !== '') {
      trackSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  const handleOnChangeSearch = (value) => {
    setSearchTerm(value ?? '');

    const filtered = activeIntegrations
      .filter((integration) => integration?.name?.toLowerCase()
        .includes(value?.toLowerCase() ?? ''));

    setFilteredIntegrations(filtered);
  };

  const trackPartnerButtonClicked = (partner, area) => {
    tracker.trackIntegrationPartnerButtonClicked({ user, partner, area });
  };

  const renderPartnerLink = (href, partner) => (
    <Link
      href={`${pathname}/${href}`}
      className='fw-bold'
      onClick={() => trackPartnerButtonClicked(partner, result)}
    >
      {partner}
    </Link>
  );

  if (isLoading) {
    return (
      <LoadingPage
        subtitle='Aguarde só um pouco, estamos carregando as integrações.'
      />
    );
  }

  return (
    <>
      <TextInput
        name='integrations-search'
        placeholder='Buscar por nome do sistema'
        value={searchTerm}
        onChange={handleOnChangeSearch}
        className='mt-6 table-form-search-input'
        isClearable
        rightAdornment={({ focus }) => (
          <InputAdornment alignment='right' onClick={focus}>
            <Icon className='text-primary' name='search' />
          </InputAdornment>
        )}
      />
      <div className='my-6 text-dark-gray'>
        {!filteredIntegrations?.length
          ? (
            <NoResults
              user={user}
              pathname={pathname}
              surveyUrl={surveyUrl}
              trackPartnerButtonClicked={trackPartnerButtonClicked}
            />
          )
          : (
            <>
              <Row className='g-4 row-cols-auto'>
                {filteredIntegrations.map((integration) => (
                  <Col key={integration.id}>
                    <IntegrationCard
                      integration={integration}
                      onClick={handleOpenModal}
                    />
                  </Col>
                ))}
              </Row>
              <div className='d-flex flex-column mt-2'>
                <span className='my-4'>
                  Você pode encontrar mais integrações nos parceiros
                  {renderPartnerLink('pluga', ' Pluga')},
                  {renderPartnerLink('zapier', ' Zapier')} ou
                  {renderPartnerLink('make', ' Make')}
                </span>
                <span>
                  Solicite à nossa equipe o desenvolvimento da integração:
                  <ExternalLink
                    href={surveyUrl}
                    target='_blank'
                    className='fw-bold'
                    onClick={
                      () => trackPartnerButtonClicked('Solicitar integracao', result)
                    }
                  >
                    &nbsp;Solicitar nova integração
                  </ExternalLink>
                </span>
              </div>
            </>
          )}
      </div>

      <IntegrationModal
        slug={selectedIntegration?.slug}
        show={showModal}
        onHide={handleCloseModal}
      />
    </>
  );
}

export default IntegrationsIndex;
