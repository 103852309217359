import React from 'react';
import PropTypes from 'prop-types';
import Card from '@/components/Card';
import Badge from '@/components/Badge';
import Truncate from '@/components/Truncate';
import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';

IntegrationCard.propTypes = {
  integration: PropTypes.object,
  onClick: PropTypes.func
};

IntegrationCard.defaultProps = {
  integration: {},
  onClick: () => {}
};

function IntegrationCard({ integration, onClick }) {
  const tracker = useTracking();
  const { user } = useAuth();

  const handleOpenIntegrationModal = () => {
    tracker.trackIntegrationCardClicked({
      user,
      integration: integration.name
    });

    onClick(integration);
  };

  return (
    <Card
      className='p-3 border-1 border-gray hover-border-dark-primary cursor-pointer text-dark-gray'
      style={{ width: '20rem', height: '12rem' }}
      onClick={handleOpenIntegrationModal}
    >
      <div className='d-flex align-items-center mb-3'>
        <img src={integration.logo} width={30} alt={'logo'} />
        <h4 className='ms-3'>{integration.name}</h4>
      </div>

      <div>
        <Badge variant='light' className='mb-2 p-2'>
          <span className='text-smaller'>
            {integration.tag}
          </span>
        </Badge>
        <div className='text-base'>
          <Truncate lines={4}>
            {integration.shortDescription}
          </Truncate>
        </div>
      </div>
    </Card>
  );
}

export default IntegrationCard;
