import React from 'react';
import Container from 'react-bootstrap/Container';

import Main from '@/components/Main';
import Stepper from '@/components/Stepper';
import { FormStep, SetupStep } from '@/feature/profile_form';

function ProfileForm() {
  return (
    <Main
      overflow
      fluid
      flex
      className='justify-content-lg-center align-items-center bg-profile container-full-height'
    >
      <Container>
        <Stepper>
          <FormStep />
          <SetupStep />
        </Stepper>
      </Container>
    </Main >
  );
}

export default ProfileForm;


