import { useQuery, useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { get, post, patch, _delete } from '@/lib/fetch';
import { dealsKeys } from '@/api';
import Task from '@/task';

export const activitiesKeys = {
  details: () => ['detail'],
  detail: (activityId) => [...activitiesKeys.details(), activityId],
  infiniteAll: ['infinite-activities'],
  infiniteLists: () => [...activitiesKeys.infiniteAll, 'list'],
  infiniteList: (params) => [...activitiesKeys.infiniteAll, 'list', params],
  infiniteByEntityAll: ['infinite-activities-by-entity'],
  infiniteListsByEntity: (entity) => ([...activitiesKeys.infiniteByEntityAll, entity]),
  infiniteListByEntity: (entity, params) => (
    [...activitiesKeys.infiniteListsByEntity(entity), params]
  )
};

export async function getActivities(params = {}) {
  const { data } = await get('/activities', params);

  return data;
}

export async function getActivity(activityId) {
  const { data } = await get(`/activities/${activityId}`);

  return data;
}

export async function createActivity(params = {}) {
  const { data } = await post('/activities', params);

  return data;
}

export async function updateActivity({ activityId, params = {} }) {
  const { data } = await patch(`/activities/${activityId}`, params);

  return data;
}

export async function deleteActivity({ activityId }) {
  const { data } = await _delete(`/activities/${activityId}`);

  return data;
}

const getEntity = (data) => {
  const type = Task.build(data).relatedEntityType;

  if (!type) {
    return null;
  }

  return {
    type,
    id: data[type].id
  };
};

export function useCreateActivity({ config = {}, hasProposal = false } = {}) {
  const queryClient = useQueryClient();

  const meta = {
    ongoingChecklistKey: hasProposal ? 'create-proposal' : 'create-task'
  };

  const updateData = (oldData, newData) => {
    if (!oldData) {
      return;
    }

    oldData.pages[0].data.unshift(newData);

    return oldData;
  };

  const onSuccess = ({ data: newData }) => {
    const promisesToInvalidate = [];

    if (hasProposal && newData.proposal?.deal_id) {
      promisesToInvalidate.push(
        queryClient.invalidateQueries(dealsKeys.detail(newData.proposal?.deal_id))
      );
    }

    queryClient.setQueriesData(
      activitiesKeys.infiniteLists(),
      (oldData) => updateData(oldData, newData)
    );

    const entity = getEntity(newData);
    if (entity) {
      const key = activitiesKeys.infiniteListsByEntity({
        entityType: entity.type,
        entityId: entity.id
      });
      queryClient.setQueriesData(key, (oldData) => updateData(oldData, newData));
    }

    return Promise.all(promisesToInvalidate);
  };

  return useMutation(
    createActivity,
    {
      onSuccess,
      meta,
      ...config
    }
  );
}

export function useUpdateActivity({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = ({ data: newData }) => {
    const promisesToInvalidate = [queryClient.invalidateQueries(activitiesKeys.infiniteLists())];

    const entity = getEntity(newData);
    if (entity) {
      promisesToInvalidate.push(
        queryClient.invalidateQueries(activitiesKeys.infiniteByEntityAll)
      );
    }

    return Promise.all(promisesToInvalidate);
  };

  return useMutation(
    updateActivity,
    {
      onSuccess,
      ...config
    }
  );
}

export function useDeleteActivity({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(activitiesKeys.infiniteLists);
  };

  return useMutation(
    deleteActivity,
    {
      onSuccess,
      ...config
    }
  );
}

export function useInfiniteActivities({ params = {}, config = {} } = {}) {
  return useInfiniteQuery(
    activitiesKeys.infiniteList(params),
    ({ pageParam: page = 1 }) => getActivities({
      ...params,
      page
    }),
    {
      ...config,
      getNextPageParam: (lastPage) => lastPage.meta.next
    }
  );
}

export function useInfiniteActivitiesByEntity(
  { entityType, entityId, params = {}, config = {} } = {}
) {
  return useInfiniteQuery(
    activitiesKeys.infiniteListByEntity({ entityType, entityId: Number(entityId) }, params),
    ({ pageParam: page = 1 }) => getActivities({
      ...params,
      page,
      entity: entityType,
      entity_id: entityId
    }),
    {
      ...config,
      getNextPageParam: (lastPage) => lastPage.meta.next
    }
  );
}

export function useActivity({ activityId, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: activitiesKeys.detail(activityId),
    queryFn: () => getActivity(activityId)
  });
}
