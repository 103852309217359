import React, { createContext, useContext } from 'react';

const ActivityCardContext = createContext(undefined);

export function useActivityCard() {
  const context = useContext(ActivityCardContext);

  if (context === undefined) {
    throw new Error('useActivityCard must be used within ActivityCardProvider');
  }

  return context;
}

function ActivityCardProvider({ value, children }) {
  return (
    <ActivityCardContext.Provider value={value}>
      {children}
    </ActivityCardContext.Provider>
  );
}

export default ActivityCardProvider;
