import React, { useEffect, useMemo, useState } from 'react';
import { compact, flatten, map } from 'lodash';
import { useUpdateHistoricSuggestion, useUpdateHistoricSuggestionsFromActivity } from '@/api';
import ActivityCardProvider from '@/components/EntityModal/Activities/ActivityCard/context';
import Task from '@/task';
import { ACTIVITY_TYPES, getPhonesOnlyNumbers } from '@/utils';
import { useAuth } from '@/lib/auth';
import Edit from '@/components/EntityModal/Activities/ActivityCard/Edit';
import Content from '@/components/EntityModal/Activities/ActivityCard/Content';

function ActivityCard({
  activity, onDelete, onUpdate, onFinished, className, smartSuggestionProps, emailProps
}) {
  const [showFooterButtons, setShowFooterButtons] = useState(false);
  const [editing, setEditing] = useState(false);
  const [showSmartSuggestion, setShowSmartSuggestion] = useState(false);
  const { user } = useAuth();
  const { account } = user;

  const isEmail = Boolean(activity.email);
  const { relatedEntity, relatedEntityType } = Task.build(activity);

  const updateSuggestionsMutation = useUpdateHistoricSuggestion({
    params: { entity: relatedEntityType, entity_id: relatedEntity?.id }
  });
  const updateSuggestionsFromActivityMutation = useUpdateHistoricSuggestionsFromActivity();

  const smartSuggestion = useMemo(() => {
    const isNoteOrFinished = (activity.finished || activity.type === ACTIVITY_TYPES.NOTE);
    const smartSuggestionEnabled =
      account.performanceOrHigher && isNoteOrFinished && Boolean(smartSuggestionProps);

    if (!smartSuggestionEnabled) {
      return {
        enabled: false
      };
    }

    const { suggestions, onEditEntity, onSelectIntent } = smartSuggestionProps;

    const entities = compact([relatedEntity].concat(relatedEntity?.people || []));
    const intents = prepareIntents(activity, suggestions, entities);
    const isProcessing = intents.some(({ processing }) => processing === true);
    const isOpen = intents.some(({ open }) => open === true);

    const hasSmartSuggestions = suggestions.length > 0 && intents.length > 0;

    return {
      enabled: true,
      suggestions,
      intents,
      isProcessing,
      isOpen,
      entities,
      hasSmartSuggestions,
      onEditEntity,
      onSelectIntent,
      onUpdateSuggestion: (currentIntent, params) => {
        updateSuggestionsMutation.mutate({ id: currentIntent.id, params });
      },
      onUpdateActivitySuggestions: (open) => {
        setShowSmartSuggestion(open);

        const params = { open, activity_id: activity.id };
        updateSuggestionsFromActivityMutation.mutate({ params });
      }
    };
  }, [
    account,
    smartSuggestionProps,
    relatedEntity,
    activity,
    updateSuggestionsMutation,
    updateSuggestionsFromActivityMutation
  ]);

  useEffect(() => {
    if (smartSuggestion.enabled && smartSuggestion.intents) {
      setShowSmartSuggestion(!smartSuggestion.isProcessing && smartSuggestion.isOpen);
    }
  }, [smartSuggestion.suggestions]);

  const email = useMemo(() => {
    if (!emailProps) {
      return { historyEnabled: false };
    }

    return {
      historyEnabled: true,
      showEmailHistory: emailProps?.showEmailHistory
    };
  }, [emailProps]);

  return (
    <ActivityCardProvider
      value={{
        showFooterButtons,
        setShowFooterButtons,
        setEditing,
        activity,
        onDelete,
        onUpdate,
        onFinished,
        isEmail,
        email,
        showSmartSuggestion,
        smartSuggestion
      }}
    >
      <div className={className}>
        {editing ? <Edit /> : <Content />}
      </div>
    </ActivityCardProvider>
  );
}

function prepareIntents(activity, suggestions, entities) {
  if (activity && suggestions?.length) {
    let extractedPhones = [];
    let extractedEmails = [];

    if (entities?.length) {
      const currentPhones = compact(
        flatten(
          map(
            entities,
            ({ contact }) => map(
              getPhonesOnlyNumbers(contact),
              (phone) => phone?.replace(/^\+55/gu, '')?.replace(/\D/gu, '')
            )
          )
        )
      );

      const currentEmails = compact(
        map(entities, (entityItem) => entityItem?.contact?.email)
      );

      extractedPhones = suggestions
        .filter(({ capturedText, suggestionType }) => (
          suggestionType === 'contact.phone' && !currentPhones.includes(capturedText)
        ));

      extractedEmails = suggestions
        .filter(({ capturedText, suggestionType }) => (
          suggestionType === 'contact.email' && !currentEmails.includes(capturedText)
        ));
    }

    const extractedActivities = suggestions
      .filter(({ suggestionType }) => suggestionType?.includes('activity.'));

    const extracted = [
      ...extractedPhones,
      ...extractedEmails,
      ...extractedActivities
    ];

    const intents = extracted.map((suggestion) => ({
      id: suggestion.id,
      type: suggestion.suggestionType,
      value: suggestion.capturedText,
      suggestionData: suggestion.suggestionData,
      processing: Boolean(suggestion?.processing),
      open: Boolean(suggestion?.open),
      activity
    }));

    return intents;
  }

  return [];
}

export default ActivityCard;
