import React from 'react';
import ToggleButtonGroup from '@/components/ToggleButtonGroup';
import ToggleButton from '@/components/ToggleButton';
import { useFilters, useUpdateFilters } from '@/contexts/task-list';

const STATUS_OPTIONS = [
  { id: 'pending', label: 'Pendentes' },
  { id: 'finished', label: 'Finalizadas' }
];

const StatusFilter = () => {
  const filters = useFilters();
  const updateFilters = useUpdateFilters();

  const handleChange = (value) => {
    updateFilters({
      status: value,
      assigned_user_id: filters.finished_by_user_id,
      finished_by_user_id: filters.assigned_user_id
    });
  };

  return (
    <ToggleButtonGroup
      type='radio'
      name='status'
      value={filters.status || STATUS_OPTIONS[0].id}
      onChange={handleChange}
    >
      {STATUS_OPTIONS.map(({ id, label }) => (
        <ToggleButton
          key={id}
          id={id}
          value={id}
          className={`${
            filters.status === id
              ? 'bg-light-primary text-dark-primary border-0'
              : 'bg-white border-0'
          }`}
        >
          {label}
        </ToggleButton>

      ))}
    </ToggleButtonGroup>
  );
};

export default StatusFilter;
