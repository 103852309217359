import React from 'react';
import {
  TextCustomField,
  DecimalCustomField,
  IntegerCustomField,
  SelectCustomField,
  DateCustomField
} from '@/components/CustomFields/Types';

/* eslint key-spacing: ["error", { "align": "value" }] */
const components = {
  text:         TextCustomField,
  integer:      IntegerCustomField,
  decimal:      DecimalCustomField,
  select:       SelectCustomField,
  multiselect:  SelectCustomField,
  date:         DateCustomField
};
/* eslint key-spacing: ["error", { "mode": "minimum" }] */

function CustomField(props) {
  const { field } = props;

  const Component = components[field.type];

  if (!Component) {
    return null;
  }

  return <Component { ...props } />;
}

export default CustomField;
