import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icons from 'images/icons.svg';

/**
 * List of icons in alphabetical order.
 */
export const ICONS = [
  'active-deal',
  'add',
  'agendor-logo',
  'agendor-logo-small',
  'ai',
  'alert',
  'alert-simple',
  'answer-everyone',
  'arrow-down',
  'arrow-left',
  'arrow-respond',
  'arrow-right',
  'arrow-to-forward',
  'arrow-turn-down-right',
  'arrow-up',
  'ascending',
  'bar-code',
  'birthday',
  'block',
  'calculator',
  'call',
  'call-close',
  'call-start',
  'chat',
  'calendar',
  'card-pay',
  'check',
  'checkmark-security-lock',
  'clock',
  'close',
  'close-beta',
  'config',
  'copy',
  'coupon',
  'date-picker',
  'deal',
  'descending',
  'delete',
  'dislike',
  'dislike-fill',
  'double-arrow-left',
  'double-arrow-right',
  'double-checked',
  'download',
  'document',
  'dots-nine',
  'dots-six',
  'drag',
  'duplication',
  'edit',
  'edit-color',
  'edit-field',
  'email',
  'email-received',
  'email-sent',
  'expand',
  'external-link',
  'facebook',
  'face-happy',
  'face-sad',
  'face-very-happy',
  'filter',
  'filter-settings',
  'font-family',
  'font-size',
  'frozen',
  'frozen-deal',
  'full-arrow-bottom',
  'full-arrow-left',
  'full-arrow-right',
  'full-arrow-top',
  'funnel',
  'funnel-add',
  'funnel-config',
  'funnel-stage-add',
  'globe',
  'help',
  'help-circle',
  'horizontal-dots',
  'hidden',
  'history',
  'home',
  'image',
  'info',
  'instagram',
  'integration',
  'lightning',
  'like',
  'like-fill',
  'link',
  'linkedin',
  'listing',
  'loading',
  'lock',
  'lost-deal',
  'microphone-closed',
  'microphone-open',
  'minimize',
  'map',
  'meeting',
  'menu',
  'minus',
  'music',
  'news',
  'note',
  'notification',
  'no-wifi',
  'order',
  'organization',
  'paperclip',
  'pause',
  'person',
  'pix',
  'play',
  'play-outline',
  'plus',
  'power',
  'product',
  'promo',
  'proposal',
  'push-pin',
  'redo',
  'reload',
  'report',
  'rocket',
  'search',
  'send',
  'security-code',
  'signature',
  'skype',
  'smartphone',
  'star',
  'star-outlined',
  'tag',
  'task',
  'team',
  'toggle-on',
  'toggle-off',
  'transcription',
  'types-multiselect',
  'types-number',
  'types-number-decimal',
  'types-select',
  'types-text',
  'types-text-area',
  'twitter',
  'undo',
  'unfrozen',
  'upload',
  'vertical-dots',
  'visible',
  'visit',
  'whatsapp',
  'won-deal'
];

const propTypes = {
  name: PropTypes.oneOf(ICONS).isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'full', 'fixed']),
  withStroke: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func
};

const defaultProps = {
  size: 'md',
  className: '',
  withStroke: false,
  onClick: () => { }
};

function Icon({ className, name, size, withStroke, onClick, ...other }) {
  return (
    <svg
      onClick={onClick}
      className={classnames(className, 'icon', `icon-${name}`, `icon-${size}`)}
      fill='currentColor'
      stroke={withStroke ? 'currentColor' : null}
      {...other}
    >
      <use xlinkHref={`${Icons}#icon-${name}`} />
    </svg>
  );
}

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;
