import React from 'react';
import {
  TextInput,
  IntegerInput,
  DecimalInput,
  Select,
  DatePickerInput
} from '@/components/Inputs';

/* eslint key-spacing: ["error", { "align": "value" }] */
const components = {
  text:         TextInput,
  integer:      IntegerInput,
  decimal:      DecimalInput,
  select:       Select,
  date:         (props) => <DatePickerInput initialShow={true} {...props} />,
  multiselect:  (props) => <Select multiple {...props} />
};
/* eslint key-spacing: ["error", { "mode": "minimum" }] */

function CustomFieldInput(props) {
  const { field } = props;

  const Component = components[field.type];

  if (!Component) {
    return null;
  }

  return <Component { ...props } />;
}

export default CustomFieldInput;
