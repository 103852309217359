import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tooltip from '@/components/Tooltip';
import Icon from '@/components/Icon';

function FooterButton({ disabled, type, text, className, onClick }) {
  return (
    <div
      className={classnames(
        className,
        'cursor-pointer',
        'customer-search-footer-button',
        {
          'pe-none': disabled,
          'cursor-pointer': !disabled
        }
      )}
      onClick={onClick}
    >
      <Icon name={type} className='me-1 text-dark-gray' />

      <span
        className={classnames({
          'text-primary': !disabled,
          'text-gray': disabled
        })}
      >
        {text}
      </span>
    </div>
  );
}

const propTypes = {
  type: PropTypes.oneOf(['organization', 'person', 'deal']).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.shape({
      name: PropTypes.string
    })
  })),
  term: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func
};

const defaultProps = {
  items: [],
  term: '',
  disabled: false,
  className: '',
  onClick: () => { }
};

const TYPES = {
  organization: 'Empresa',
  person: 'Pessoa',
  deal: 'Negócio'
};

function FooterActionButton({ type, items, term, disabled, className, onClick }) {
  let blocked = false;

  if (type === 'organization') {
    blocked = items.some((item) => {
      if (item.type !== type) {
        return false;
      }

      const { data: { name } } = item;

      return name.toLowerCase() === term.toLowerCase();
    });
  }

  const handleClick = (event) => {
    event.preventDefault();
    onClick({ type, term });
  };

  const text = TYPES[type];
  const tooltipContent = () => (
    type === 'deal'
      ? `Este ${text.toLocaleLowerCase()} já está cadastrado`
      : `Esta ${text.toLocaleLowerCase()} já está cadastrada`
  );

  const button =
    <FooterButton
      type={type}
      text={text}
      disabled={disabled || blocked}
      className={className}
      onClick={handleClick}
    />;

  if (blocked) {
    return (
      <Tooltip content={tooltipContent}>
        <div>
          {button}
        </div>
      </Tooltip>
    );
  }

  return button;
}

FooterActionButton.propTypes = propTypes;
FooterActionButton.defaultProps = defaultProps;

export default FooterActionButton;
