import React, { useState } from 'react';
import classnames from 'classnames';
import { Nav, Stack } from 'react-bootstrap';
import {
  activitiesImportPath,
  legacyCalendarUrl,
  legacyIntegrationsUrl,
  newTaskListUrl
} from '@/routes';
import NavLink from '@/components/NavLink';
import Icon from '@/components/Icon';
import ExternalLink from '@/components/ExternalLink';
import Pills from '@/components/Pills';
import Card from '@/components/Card';
import Button from '@/components/Button';
import AddOrganizationPopover from '@/components/QuickForms/AddOrganizationPopover';
import AddPersonPopover from '@/components/QuickForms/AddPersonPopover';
import AddDealPopover from '@/components/QuickForms/AddDealPopover';
import Tooltip from '@/components/Tooltip';

const tabs = [
  {
    key: 'listagem',
    label: 'Listagem',
    icon: 'listing',
    href: newTaskListUrl()
  },
  {
    key: 'calendario',
    label: 'Calendário',
    icon: 'calendar',
    href: legacyCalendarUrl(),
    external: true
  }
];

const popovers = [
  { component: AddOrganizationPopover, entityType: 'organization', label: 'Adicionar empresa' },
  { component: AddPersonPopover, entityType: 'person', label: 'Adicionar pessoa' },
  { component: AddDealPopover, entityType: 'deal', label: 'Adicionar negócio' }
];

const TabItem = ({ tab, isActive, onClick }) => {
  const LinkComponent = tab.external ? ExternalLink : NavLink;
  return (
    <Pills.Item key={tab.key} className='flex-grow-1'>
      <Nav.Item>
        <LinkComponent
          href={tab.href}
          className={classnames('d-flex justify-content-center ps-4 pe-4 py-2 w-100', {
            'bg-light-primary text-dark-primary': isActive,
            'bg-white text-darker-gray': !isActive
          })}
          onClick={onClick}
        >
          <Icon name={tab.icon} size='sm' className='me-2 mt-1' />
          <div className='fw-bold text-nowrap'>{tab.label}</div>
        </LinkComponent>
      </Nav.Item>
    </Pills.Item>
  );
};

const ActionButton = ({ icon, label, disabled, tooltip, ...props }) => {
  const button = (
    <Button
      variant='lighter-gray'
      className='text-nowrap flex-grow-1'
      disabled={disabled}
      aria-label={label}
      {...props}
    >
      <Icon name={icon} className='me-2' size='fixed' />
      {label}
    </Button>
  );

  return tooltip
    ? <Tooltip placement='bottom' content={tooltip}>
      <div className='d-flex flex-grow-1'>{button}</div>
    </Tooltip>
    : button;
};

function SideBar() {
  const [activeTab, setActiveTab] = useState('listagem');
  const [activePopover, setActivePopover] = useState();

  return (
    <Card className='p-4 border-light'>
      <div className='d-flex justify-content-center'>
        <Pills className='rounded-3 border border-light-gray overflow-hidden d-flex w-100'>
          {tabs.map((tab) => (
            <TabItem
              key={tab.key}
              tab={tab}
              isActive={activeTab === tab.key}
              onClick={() => setActiveTab(tab.key)}
            />
          ))}
        </Pills>
      </div>

      <div className='border-top border-2 border-lighter-gray my-3' />

      <div className='d-flex gap-3 flex-wrap'>
        <ActionButton
          icon='upload'
          label='Importar'
          as={ExternalLink}
          href={activitiesImportPath()}
        />
        <ActionButton
          icon='download'
          label='Exportar'
          disabled
          tooltip='Em breve'
        />
      </div>

      <div className='border-top border-2 border-lighter-gray my-3' />

      <ActionButton
        icon='calendar'
        label='Integrar com o Google Agenda'
        as={ExternalLink}
        href={`${legacyIntegrationsUrl()}#google-calendar`}
      />

      <div className='border-top border-2 border-lighter-gray my-3' />
      <Stack gap={2}>
        {popovers.map(({ component: Popover, entityType, label }) => (
          <Popover key={entityType} handleHide={setActivePopover}>
            <ActionButton
              icon={entityType}
              label={label}
              variant={entityType === activePopover ? 'lighter-blue' : 'lighter-gray'}
              className={classnames(
                'w-100',
                entityType === activePopover ? 'text-primary' : 'text-darker-gray'
              )}
              onClick={() => setActivePopover(entityType)}
            />
          </Popover>
        ))}
      </Stack>
    </Card>
  );
}

export default SideBar;
