import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { useAlert } from 'react-alert';

import Modal from '@/components/Modal';
import Header from '@/components/EntityModal/Person/Header/Header';
import ModalContent from '@/components/EntityModal/Person/ModalContent';
import ModalError from '@/components/EntityModal/ModalError';

import {
  usePerson,
  useUpdatePerson,
  useDeletePerson,
  useUpdateOrganization,
  useVoipVerify
} from '@/api';
import { PersonModalProvider } from '@/contexts/personModal';
import APIErrorMessage from '@/components/APIErrorMessage';
import { useDocumentTitle, useScreenSize } from '@/hooks';
import { getScreenSizeLabel } from '@/utils';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';

const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string])
};

function PersonModal({ id }) {
  const alert = useAlert();
  const navigate = useNavigate();
  const updateOrganizationMutation = useUpdateOrganization();
  const updatePersonMutation = useUpdatePerson();
  const deletePersonMutation = useDeletePerson();
  const screenSize = useScreenSize();
  const modalSize = getScreenSizeLabel(screenSize);
  const { user } = useAuth();
  const tracker = useTracking();
  const entityType = 'person';

  const { data: person, isSuccess, error } = usePerson({
    personId: id,
    params: { avatar_size: 'medium' },
    config: { staleTime: 1000 }
  });
  useDocumentTitle(person?.data.name || '');
  const { data: voip } = useVoipVerify({ config: { staleTime: 1000 } });

  useEffect(() => {
    if (isSuccess) {
      tracker.trackModalViewed({
        entityType,
        user
      });
    }
  }, [isSuccess]);

  const onUpdate = (params, done) => {
    updatePersonMutation.mutate({ personId: id, params }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='person' action='update' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.({ error: err });
      },
      onSuccess: (data) => {
        alert.show(
          'Pessoa atualizada com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        done?.(data);
      }
    });
  };

  const onUpdateOrganization = (params, done, organizationData) => {
    updateOrganizationMutation.mutate({ organizationId: organizationData.id, params }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='organization' action='update' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.({ error: err });
      },
      onSuccess: (data) => {
        alert.show(
          'Empresa atualizada com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        done?.(data);
      }
    });
  };

  const handleClose = () => {
    navigate(location?.pathname);
  };

  const onDelete = (_params, done) => {
    deletePersonMutation.mutate({ personId: id }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='person' action='delete' />,
          { variant: 'danger', timeout: 5000 }
        );
        done?.(err);
      },
      onSuccess: () => {
        alert.show(
          'Pessoa Excluida',
          { variant: 'success', timeout: 5000 }
        );
        done?.();
        handleClose();
      }
    });
  };

  return (
    <Modal
      id='entity-modal'
      size={modalSize}
      baseClassName='entity-modal overflow-y-scroll'
      bodyClassName='bg-light p-0 rounded d-flex justify-content-center'
      show={Boolean(id)}
      onHide={handleClose}
    >
      {
        error
          ? (
            <ModalError onHide={handleClose} error={error} entityType={entityType} />
          )
          : (
            <PersonModalProvider
              value={{
                person: person?.data,
                voip,
                onUpdate,
                onDelete,
                onUpdateOrganization
              }}
            >
              <Row className='w-100'>
                <Col sm='12'>
                  <Header />
                </Col>
                <ModalContent personId={id} />
              </Row>
            </PersonModalProvider>
          )
      }
    </Modal>
  );
}

PersonModal.prototype = propTypes;

export default PersonModal;
