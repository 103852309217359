import React from 'react';
import PropTypes from 'prop-types';
import Base from 'react-bootstrap/Form';
import FormProvider from '@/components/FormProvider';

const propTypes = {
  children: PropTypes.func.isRequired,
  name: PropTypes.string,
  className: PropTypes.string,
  initialValues: PropTypes.object,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  initialSubmit: PropTypes.bool,
  submitOnDefaultChange: PropTypes.bool,
  validationSchema: PropTypes.object,
  initialErrors: PropTypes.object
};

const defaultProps = {
  className: '',
  defaultValues: {},
  initialSubmit: false,
  submitOnDefaultChange: false
};

const Form = React.forwardRef(
  ({
    children, name, className, defaultValues, initialValues, onSubmit, enableReinitialize, onReset,
    initialSubmit, submitOnDefaultChange, validationSchema, validationContext, initialErrors,
    validateOnMount, ...otherProps
  }, ref) => {
    const formProviderProps = {
      defaultValues,
      initialValues,
      onSubmit,
      enableReinitialize,
      onReset,
      initialSubmit,
      submitOnDefaultChange,
      validationSchema,
      validationContext,
      initialErrors,
      validateOnMount
    };

    return (
      <FormProvider
        ref={ref}
        {...formProviderProps}
      >
        {(providerProps) => (
          <Base
            onSubmit={providerProps.handleSubmit}
            className={className}
            name={name}
            { ...otherProps }
          >
            {children(providerProps)}
          </Base>
        )}
      </FormProvider>
    );
  }
);

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;
Form.displayName = 'Form';

Form.Label = Base.Label;
Form.Group = Base.Group;

export default Form;
