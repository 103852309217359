import React, { createContext, useContext, useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '@/lib/auth';
import { useInfiniteActivities } from '@/api';
import _ from 'lodash';
import { todayRange, weekRange } from '@/date';
import { useStoredState } from '@/hooks';

const TaskListContext = createContext(undefined);

export function useTaskListContext() {
  const context = useContext(TaskListContext);

  if (context === undefined) {
    throw new Error('useTaskListContext must be used within TaskListProvider');
  }

  return context;
}

export const useFilters = () => useTaskListContext().filters;

export const useUpdateFilters = () => useTaskListContext().updateFilters;

export const useActivities = () => useTaskListContext().activities;

export const useActivitiesCount = () => useTaskListContext().totalActivities;

export const useActivitiesActions = () => {
  const {
    isLoading,
    isFetchingActivities,
    isFetchingNextPageActivities,
    fetchNextPageActivities,
    hasNextPageActivities
  } = useTaskListContext();

  return {
    isLoading,
    isFetchingActivities,
    isFetchingNextPageActivities,
    fetchNextPageActivities,
    hasNextPageActivities
  };
};

const dateParams = (filters, dateParts) => {
  if (filters.date === 'today') {
    return todayRange();
  }

  if (filters.date === 'week') {
    return weekRange();
  }

  if (!dateParts) {
    return [null, null];
  }

  return [filters[dateParts[0]], filters[dateParts[1]]];
};

const dateFilters = (filters) => {
  const { date, status } = filters;

  if (date === 'all') {
    return {};
  }

  const dateParts = status === 'pending'
    ? ['initial_due_at', 'final_due_at']
    : ['initial_finished_at', 'final_finished_at'];

  return _.zipObject(dateParts, dateParams(filters, dateParts));
};

export function TaskListProvider({ children }) {
  const { user } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { value: storedFilters, store: storeFilters } = useStoredState(
    'task_list_filters',
    {}
  );

  const filterValue = (attributeName, defaultValue) => (
    searchParams.get(attributeName) || storedFilters?.[attributeName] || defaultValue
  );

  const statusValue = filterValue('status', 'pending');

  const initialFilters = {
    types_in: searchParams.get('types_in')
      ? JSON.parse(searchParams.get('types_in'))
      : storedFilters?.types_in || [],
    status: statusValue,
    assigned_user_id: statusValue === 'pending'
      ? filterValue('assigned_user_id', user.id)
      : null,
    finished_by_user_id: statusValue === 'finished'
      ? filterValue('finished_by_user_id', user.id)
      : null,
    date: filterValue('date', 'all'),
    initial_due_at: filterValue('initial_due_at', null),
    final_due_at: filterValue('final_due_at', null),
    initial_finished_at: filterValue('initial_finished_at', null),
    final_finished_at: filterValue('final_finished_at', null)
  };

  const [filters, setFilters] = useState(initialFilters);

  const updateFilters = (newFilters) => {
    setFilters((prevFilters) => {
      const swapDateFilters = newFilters.status
        ? {
          initial_due_at: prevFilters.initial_finished_at,
          final_due_at: prevFilters.final_finished_at,
          initial_finished_at: prevFilters.initial_due_at,
          final_finished_at: prevFilters.final_due_at
        }
        : {};

      const updatedFilters = { ...prevFilters, ...newFilters, ...swapDateFilters };

      storeFilters(updatedFilters);

      const filteredParams = _.omitBy(
        {
          types_in: updatedFilters.types_in.length ? JSON.stringify(updatedFilters.types_in) : null,
          status: updatedFilters.status,
          assigned_user_id: updatedFilters.assigned_user_id,
          finished_by_user_id: updatedFilters.finished_by_user_id,
          date: updatedFilters.date,
          initial_due_at: updatedFilters.initial_due_at,
          final_due_at: updatedFilters.final_due_at,
          initial_finished_at: updatedFilters.initial_finished_at,
          final_finished_at: updatedFilters.final_finished_at
        },
        _.isNil
      );

      setSearchParams(filteredParams);

      return updatedFilters;
    });
  };

  const activityParams = useMemo(() => {
    const filtersWithoutDate = _.omit(filters, 'date');

    return {
      per_page: 30,
      ...filtersWithoutDate,
      ...dateFilters(filters)
    };
  }, [filters]);

  const {
    data: { pages: activityPages } = {},
    isLoading,
    isFetching: isFetchingActivities,
    isFetchingNextPage: isFetchingNextPageActivities,
    fetchNextPage: fetchNextPageActivities,
    hasNextPage: hasNextPageActivities
  } = useInfiniteActivities({
    entity: 'organization',
    entity_id: 14193334,
    params: activityParams
  });

  const activities = activityPages?.flatMap((page) => page.data) || [];

  const totalActivities = activityPages?.[0]?.meta?.count;

  const value = {
    filters,
    updateFilters,
    activities,
    totalActivities,
    isLoading,
    isFetchingActivities,
    isFetchingNextPageActivities,
    fetchNextPageActivities,
    hasNextPageActivities
  };

  return (
    <TaskListContext.Provider value={value}>
      {children}
    </TaskListContext.Provider>
  );
}
