import React from 'react';
import { Navigate } from 'react-router-dom';
import ABTest from '@/components/ABTest';
import SetupStepB from './ABTest/SetupStep';
import { useAuth } from '@/lib/auth';
import { funnelsPath } from '@/routes';

export { default as FormStep } from './FormStep';

export function SetupStep() {
  const { user } = useAuth();

  return (
    <ABTest name='setup' identifier={user.account.id}>
      <ABTest.Variant name='A'>
        <Navigate to={funnelsPath()} />
      </ABTest.Variant>

      <ABTest.Variant name='B'>
        <SetupStepB />
      </ABTest.Variant>
    </ABTest>
  );
}
