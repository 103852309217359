import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { get } from '@/lib/fetch';

const ongoingChecklistsKeys = {
  all: ['ongoing-checklist']
};

export async function getOngoingChecklist(params = {}) {
  const { data } = await get('/ongoing_checklist', params);

  return data;
}

export function useOngoingChecklist({ params = {}, config = {} }) {
  const queryKey = ongoingChecklistsKeys.all;

  const queryClient = useQueryClient();
  useEffect(() => {
    const unsubscribe = queryClient.getMutationCache().subscribe((mutation) => {
      const itemKey = mutation.options.meta?.ongoingChecklistKey;
      if (!itemKey) {
        return;
      }

      if (mutation.state.status !== 'success') {
        return;
      }

      const currentData = queryClient.getQueryData(queryKey);
      const newData = checkOngoingChecklistItem(currentData, itemKey);
      if (currentData === newData) {
        return;
      }

      queryClient.setQueryData(queryKey, newData);

      const allCompleted = newData.data.items.every((item) => item.completed);
      if (allCompleted) {
        queryClient.invalidateQueries(queryKey);
      }
    });

    return () => unsubscribe();
  }, []);

  return useQuery({
    ...config,
    queryKey,
    queryFn: () => getOngoingChecklist(params)
  });
}

function checkOngoingChecklistItem(data, key) {
  const { data: { items: oldItems, ...otherData } } = data;

  const index = oldItems.findIndex((item) => item.key === key && !item.completed);
  if (index === -1) {
    return data;
  }

  const newItems = [...oldItems];

  newItems.splice(index, 1, { ...oldItems[index], completed: true });

  return { data: { items: newItems, ...otherData } };
}
