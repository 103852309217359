import React, { useState } from 'react';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import {
  StatusFilter,
  OwnerUserFilter,
  TypeFilter,
  DateRangeFilter
} from '@/components/Filters/TaskListFilters';
import { Col, Row } from 'react-bootstrap';
import ActivityForm from '@/components/TaskList/ActivityForm';
import Collapse from '@/components/Collapse';

const TaskListFilters = () => {
  const [showForm, setShowForm] = useState(false);

  return (
    <div className='pb-3'>
      <div className='d-flex flex-column flex-lg-row justify-content-between align-items-center
        bg-lighter-gray rounded px-6 py-4 mb-2 gap-3'
      >
        <TypeFilter />
        <StatusFilter />
        <DateRangeFilter />
      </div>

      <Row className='mb-4'>
        <Col sm={3}>
          <Button
            onClick={() => setShowForm((prev) => !prev)}
            variant='primary'
            className='fw-bold text-nowrap'
          >
            <Icon name='plus' />
            Adicionar tarefa
          </Button>
        </Col>

        <Col sm={9} className='d-flex justify-content-end'>
          <OwnerUserFilter />
        </Col>
      </Row>

      <Collapse in={showForm}>
        <div>
          <ActivityForm />
        </div>
      </Collapse>
    </div>
  );
};

export default TaskListFilters;
