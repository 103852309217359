import React from 'react';

import { useBillingProducts } from '@/api';
import { useDebounce } from '@/hooks';
import { toCurrencyString } from '@/number';

import FormField from '@/components/FormField';
import { FetchableSelect, IntegerInput } from '@/components/Inputs';
import Tooltip from '@/components/Tooltip';
import { useUpdateSubscriptionContext } from '@/contexts';

const defaultDebounceTime = 600;
const maxUsersComboPlan = 3;

function transformer(fetchedData) {
  const PLANS = fetchedData.data.filter((plan) => plan.paidPlan === true);

  return PLANS.map((plan) => ({
    label: plan.planName,
    value: plan.code,
    rightAdornment: (
      <Tooltip content={`${toCurrencyString(plan.value)}/mês por usuário`}>
        <div className='text-small fw-normal'>
          {`${toCurrencyString(plan.value)}/mês...`}
        </div>
      </Tooltip>
    )
  }));
}

function UpdatePlanFields() {
  const {
    currentPlanData,
    setSelectedPlan,
    setSelectedMaxUsers,
    crmSubscription
  } = useUpdateSubscriptionContext();

  const baseUsersCount = 3;
  const minUsersCount = currentPlanData.maxUsersCount === 2 ? 2 : baseUsersCount;

  const debounceHandler = useDebounce((fn) => fn(), defaultDebounceTime);
  const onChangeSelectedPlan = (value) => setSelectedPlan(value);
  const onChangeSelectedMaxUsers = (value) => {
    const usersCount = Number(value);
    debounceHandler(() => {
      if (crmSubscription || (usersCount >= minUsersCount)) {
        setSelectedMaxUsers(usersCount);

        if (!crmSubscription) {
          setSelectedPlan(
            usersCount <= maxUsersComboPlan
              ? 'agendor_chat_combo_plan'
              : 'agendor_chat_plan'
          );
        }
      }
    });
  };

  return (
    <>
      <div className='fw-bold mb-3'>NOVO PLANO</div>
      {crmSubscription && (
        <FormField
          as={FetchableSelect}
          transformer={transformer}
          name='plan_select'
          onChange={onChangeSelectedPlan}
          query={useBillingProducts}
          queryArgs={{ params: { from_plan: true } }}
          optionsClassName='fw-bold'
          className='mb-3'
        />
      )}
      <FormField
        as={IntegerInput}
        name='users_count'
        className='mb-1'
        onChange={onChangeSelectedMaxUsers}
        innerClassName='text-center'
        type='number'
        placeholder='1'
        min={crmSubscription ? '1' : minUsersCount}
      />
    </>
  );
}

export default UpdatePlanFields;
