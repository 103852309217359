import React from 'react';
import { useActivityCard } from '@/components/EntityModal/Activities/ActivityCard/context';
import TruncateLinkfied from '@/components/TruncateLinkfied';
import SmartSummary from '@/components/SmartSummary';

function Base() {
  const { activity } = useActivityCard();

  return (
    <div className='rounded-bottom bg-white'>
      <TruncateLinkfied
        fullWidth
        anchorClassName='d-block fw-bold'
        text={activity.text}
        maxLength={200}
      />

      <SmartSummary activity={activity} />
    </div>
  );
}

export default Base;
