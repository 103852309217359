import { fixDateYear, parseDate } from '@/date';
import addHours from 'date-fns/addHours';

export default class Task {
  static TYPES = {
    visit: 'Visita',
    meeting: 'Reunião',
    call: 'Ligação',
    email: 'E-mail',
    proposal: 'Proposta',
    task: 'Tarefa',
    note: 'Nota',
    whatsapp: 'WhatsApp'
  };

  static getActivityDates(activity) {
    const FAKE_UTC_HOURS = 3;
    const parsedCreatedAt = parseDate(activity.createdAt);

    /*
     * Idealmente usamos a data em UTC, porém, há casos onde atividades possuem dueAt,
     * mas não dueAtUtc.
     * Será corrigido este comportamento no banco de dados em WEB-8057
     */
    const result = {
      createdAt: parsedCreatedAt,
      dueAt: null,
      finishedAt: null
    };

    if (activity.dueAtUtc || activity.dueAt) {
      const dueAt = fixDateYear(
        activity.dueAtUtc
          ? activity.dueAtUtc
          : activity.dueAt
      );
      const hasDueAtError = dueAt && activity.dueAt && !activity.dueAtUtc;
      result.dueAt = hasDueAtError
        ? addHours(parseDate(dueAt), FAKE_UTC_HOURS)
        : parseDate(dueAt);
    }

    if (activity.finishedAtUtc || activity.finishedAt) {
      const finishedAt = fixDateYear(
        activity.finishedAtUtc
          ? activity.finishedAtUtc
          : activity.finishedAt
      );
      const hasFinishedAtError = finishedAt && activity.finishedAt && !activity.finishedAtUtc;
      result.finishedAt = hasFinishedAtError
        ? addHours(parseDate(finishedAt), FAKE_UTC_HOURS)
        : parseDate(finishedAt);
    }

    return result;
  }


  static build(data) {
    if (!data) {
      return null;
    }

    return new Task(data);
  }

  constructor(data) {
    this.data = data;
    this.type = data.type;
    this.typeName = Task.TYPES[data.type];
    this.createdAt = parseDate(data.createdAt);
    this.createdBy = data.createdBy;
    this.dueAt = data.dueAtUtc ? parseDate(data.dueAtUtc) : null;
    this.allDay = data.allDay;
    this.text = data.text;
    this.assignedUsers = data.assignedUsers;
    this.status = data.status;
  }

  get overdue() {
    return this.status === 'overdue';
  }

  get done() {
    return this.status === 'done';
  }

  get description() {
    if (!this.status || this.done) {
      return `${this.typeName}`;
    }

    let descriptionAdjective = 'pendente';

    if (!this.overdue) {
      descriptionAdjective = ['email', 'whatsapp'].includes(this.type)
        ? 'agendado'
        : 'agendada';
    }

    return `${this.typeName} ${descriptionAdjective}`;
  }

  get relatedEntity() {
    if (this.relatedEntityType) {
      return this.data[this.relatedEntityType];
    }

    return null;
  }

  get relatedEntityType() {
    if (this.data.deal) {
      return 'deal';
    } else if (this.data.person) {
      return 'person';
    } else if (this.data.organization) {
      return 'organization';
    }
    return null;
  }
}
