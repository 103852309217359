const trackingEvents = {
  user: {
    loaded: {
      name: 'Dados de usuario configurados',
      category: 'Usuario'
    },
    profile_updated: {
      name: 'Perfil atualizado',
      category: 'Usuario'
    }
  },
  customField: {
    organization: {
      created: {
        name: 'Campo personalizado adicionado em empresa',
        category: 'Campo personalizado'
      }
    },
    person: {
      created: {
        name: 'Campo personalizado adicionado em pessoa',
        category: 'Campo personalizado'
      }
    },
    deal: {
      created: {
        name: 'Campo personalizado adicionado em negocio',
        category: 'Campo personalizado'
      }
    }
  },
  list: {
    horizontalScrollViewed: {
      name: 'Listagem scroll horizontal exibido',
      category: 'Listagem'
    }
  },
  deal: {
    created: {
      name: 'deal_created'
    },
    won: {
      name: 'deal_won'
    },
    lost: {
      name: 'deal_lost'
    }
  },
  activity: {
    created: {
      name: 'activity_created'
    }
  }
};

export class DataLayerTracker {
  #trackerFn;

  constructor(trackerFn) {
    this.#trackerFn = trackerFn;
  }

  trackUserLoaded({ user, abTest }) {
    const event = trackingEvents.user.loaded;
    const userMetadata = this.#buildUserMetadata(user);
    const eventMetadata = {
      ...userMetadata,
      'teste_ab': abTest
    };

    return this.#trackEvent(event, eventMetadata);
  }

  trackProfileUpdated({ user }) {
    const event = trackingEvents.user.profile_updated;
    const eventMetadata = this.#buildUserMetadata(user);

    return this.#trackEvent(event, eventMetadata);
  }

  trackCustomFieldCreated({ entity, field }) {
    const event = trackingEvents.customField[entity].created;
    const eventMetadata = {
      'nome_campo': field.name,
      'identificador_campo': `custom_fields.${field.identifier}`
    };

    return this.#trackEvent(event, eventMetadata);
  }

  trackListHorizontalScrollViewed({ hasDoneScrollWithDrag }) {
    const event = trackingEvents.list.horizontalScrollViewed;
    const eventMetadata = { has_done_scroll_with_drag: hasDoneScrollWithDrag };

    return this.#trackEvent(event, eventMetadata);
  }

  trackDealCreated() {
    const event = trackingEvents.deal.created;

    return this.#trackEvent(event);
  }

  trackDealStatusChanged({ status }) {
    let event;

    if (status === 'Ganho') {
      event = trackingEvents.deal.won;
    } else if (status === 'Perdido') {
      event = trackingEvents.deal.lost;
    } else {
      return;
    }

    return this.#trackEvent(event);
  }

  trackCommentAdded({ entity }) {
    const event = trackingEvents.activity.created;
    const eventMetadata = { type: entity.activityType };

    return this.#trackEvent(event, eventMetadata);
  }

  #buildUserMetadata(user) {
    const { account, features } = user;
    const { plan } = account;

    function getPlanName(planName, fullName = true) {
      const planoCompleto = planName.trim().split(/\s+/u);
      let nomePlano = null;

      if (fullName) {
        if (planName === 'Gratuito') {
          nomePlano = planoCompleto[0];
        } else {
          nomePlano = `${planoCompleto[0]} ${account.maxUsers}`;
        }
      } else {
        nomePlano = planoCompleto[0];
      }

      return nomePlano;
    }

    return {
      'persona': user.persona,
      'ramo_atividade': account.segment,
      'necessidade': account.need,
      'tipo_grupo': user.groupType,
      'plano': getPlanName(plan.name),
      'nome_plano': getPlanName(plan.name, false),
      'max_usuarios_plano': String(account.maxUsers),
      'id_usuario': String(user.id),
      'nome_usuario': user.name,
      'email_usuario': user.email,
      'dt_cadastro_usuario': user.createdAt,
      'id_cliente': String(account.id),
      'nome_cliente': account.name,
      'dt_cadastro_cliente': account.createdAt,
      'novos_forms_ativados': Boolean(features?.newFormsEnabled),
      'novo_funil_ativado': Boolean(features?.newFunnelEnabled),
      'como_conheceu': account.findUs,
      'experiencia_crm': user.crmExperience,
      'tamanho_equipe_comercial': account.salespersonNumber,
      'tipo_conta': account.subscriptionStatus,
      'utm_content': account.utmContent,
      'utm_medium': account.utmMedium,
      'cliente_whatsapp': account.whatsapp,
      'cliente_telefone': account.workPhone,
      'usuario_whatsapp': user.whatsapp,
      'usuario_telefone': user.workPhone
    };
  }

  #trackEvent(event, metadata) {
    return this.#trackerFn({
      'event': 'eventTrigger',
      'eventCategory': event.category,
      'eventAction': event.name,
      'eventLabel': event.name,
      'eventProperties': metadata,
      'eventValue': 0
    });
  }
}
